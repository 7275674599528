import React, {  } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import IconAlert from '../../../images/MDV/GastosTotais/icon-alert.svg';

const Aviso = () => (
  <Box padding={'16px'} bg={'#C96F1B'} borderRadius={10}>
    <Image src={IconAlert} alt="Icon Alert" w={6} h={'auto'} mb={'10px'} />
    <Text fontSize="md" color={'#FFF'} lineHeight={'18px'}>
      {`O valor a receber só ficará disponível para saque 30 dias após o pagamento do`}
      <br />
      {`assinante.`}
    </Text>
  </Box>
);

export default Aviso;