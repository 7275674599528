import {
  Modal,
  Box,
  Text,
  VStack,
  Image,
  Spinner,
  useDisclosure,
  useToast,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Heading,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import SelectField from 'components/SelectField';
import { Form, Formik } from 'formik';
import PopupDependenteSemCpf from '../../Atendimento/PopupDependenteSemCpf';
import AvisoAtendimento from './Modal/AvisoAtendimento';
import TextField from 'components/TextField';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';
import Contact from '../../../../../images/ClinicoIcon.svg';
import Editar from '../../../../../images/editar.svg';
import telemedDeslogado from '../../../../../images/Deslogado/telemedDeslogado.svg';
import * as Yup from 'yup';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function AtualizarTelefone() {
  const { user, setUser } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState('');
  const [value, setValue] = useState(null);
  const [dependentes, setDependentes] = useState(false);

  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];
  const [client, setClient] = useState();
  const navigate = useNavigate();
  const [accept, setAccepted] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const disclosure = useDisclosure();
  const { state } = useLocation();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const disclosureAviso = useDisclosure();
  const whatsappDisclosure = useDisclosure();
  const [status, setStatus] = useState(null);
  const [atualizarTelefone, setAtualizar] = useState(true);

  const ModalWhatsAppStatus = ({ disclosure }) => {
    return (
      <>
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
          <ModalOverlay />
          <ModalContent m={10} p={2} pt={4} pb={4} borderRadius={18}>
            <ModalBody display={'flex'} flexDir={'column'} alignItems={'center'}>
              {status == true ? <FiCheckCircle color="green" size={60} /> : <FiAlertCircle color="red" size={60} />}
              <Text fontWeight={'bold'} textAlign={'center'} fontSize={'2xl'} mt={4}>
                {status == true ? 'Sucesso!' : 'Aviso!'}
              </Text>

              <Text mt={4} textAlign={'center'}>
                {status == true
                  ? 'Número de contato atualizado com sucesso!'
                  : 'Problema ao atualizar o número de contato. Seu atendimento não será interrompido!'}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                bg={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                mr={3}
                onClick={() => {
                  disclosure.onClose();
                  disclosureAviso.onOpen();
                }}
                w={'100%'}
                textColor={'#FFF'}
              >
                Continuar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const registerAppointment = async () => {
    const resposta = await fetch(
      `${ENDPOINT}/rapidoc/appointment-insert/${state && state.especialista ? 'especialista' : 'clinico'}/${
        !usuarioAssinante ? onlyNumbers(user.username) : state.dependentes[value].cpf
      }`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    resposta.json().then((res) => {
      if (res.success) {
        console.log('sucesso');
      }
    });
  };

  async function geturl() {
    setLoading(true);
    registerAppointment(!usuarioAssinante ? onlyNumbers(user.username) : onlyNumbers(state.dependentes[state.value].cpf));
    navigate('/medico-agora/atendimento', {
      state: {
        patient: !usuarioAssinante ? onlyNumbers(user.username) : state.dependentes[state.value].cpf,
        triagem: { ...state },
        faturado: state?.faturado,
        credito: state?.credito_id ? state?.credito_id : state.credito,
      },
    });
  }

  const handleModalFechado = () => {
    /*if (user.titular === true) {
      if (state.dependentes && state.value) {
        geturl();
        setError(null);
        setShowText(true);
        setDisabled(true);
      }
    } else {
      geturl();
      setError(null);
      setShowText(true);
      setDisabled(true);
    } */
    geturl();
    setError(null);
    setShowText(true);
    setDisabled(true);
  };

  useEffect(() => {
    if (status) {
      whatsappDisclosure.onOpen();
    }
  }, [status]);

  const handlePhoneNumber = async (vals) => {
    const upd = await fetch(`${ENDPOINT}/auth/update_whatsapp`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ cpf: state.dependentes[state.value].cpf, whatsapp: onlyNumbers(vals.fone) }),
    });

    const res = await upd.json();

    if (res.success == true) {
      setStatus(true);
      whatsappDisclosure.onOpen();
      if (state.dependentes[state.value].cpf == user.cpf) {
        setUser((prevState) => ({ ...prevState, phone1: onlyNumbers(vals.fone) }));
      }
    } else {
      setStatus(false);
    }
  };

  let contato = '';
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;

  if (state.dependentes[state.value].cpf === user.username && user.titular) {
    contato = state.dependentes[state.value].celular;
  } else {
    contato = state.dependentes[state.value].celular ? state.dependentes[state.value].celular : state.dependentes[state.value].phone1;
  }

  if(!usuarioAssinante) {
    contato = user.phone1
  }

  return (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background={usuarioAssinante ? '#EB8B2A' : '#529C94'}
      >
        <ModalWhatsAppStatus disclosure={whatsappDisclosure} />
        <AvisoAtendimento disclousure={disclosureAviso} onModalClose={handleModalFechado} patient={state?.dependentes[state?.value].cpf} />
        <PopupDependenteSemCpf disclosure={disclosure} datas={dependentes[value]} />
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt="1"
          id=""
          as="card"
          width="auto"
          height="auto"
          borderRadius="18px"
          fontSize="xs"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        >
          <VStack mt="8%" mb="8%" ml="2%" mr="2%" textColor="#585858">
            <Image top={{ lg: '5.5em', sm: '5em' }} src={!usuarioAssinante ? telemedDeslogado : Contact} alt="teste" height={'4.5em'} />

            <Text fontWeight="bold" fontSize="1.5em">
              Confirme o telefone do responsável
            </Text>

            <Text fontSize="xs" fontWeight="bold">
              É importante a confirmação dos dados para que você receba suas receitas e solicitações médicas por WhatsApp.
            </Text>

            <Formik
              initialValues={{
                fone: contato ? onlyNumbers(contato) : '',
                confirma_telefone: '',
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(vals) => {
                handlePhoneNumber(vals);
              }}
              validationSchema={Yup.object({
                fone: Yup.string()
                  .required('Telefone é obrigatório')
                  .trim()
                  .transform((value) => value.replace(/[^\d]/g, ''))
                  .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido'),

                confirma_telefone: Yup.string()
                  .required('Confirmação de telefone é obrigatória')
                  .transform((value) => value.replace(/[^\d]/g, ''))
                  .oneOf([Yup.ref('fone'), null], 'Os telefones devem ser iguais')
                  .matches(/^\d{10,11}$/, 'Telefone Inválido'),
              })}
            >
              <VStack as={Form} w={{ base: '100%', md: '500px' }} m="auto" padding="5%" justify="top" overflow="hidden" align="start">
                <Text fontWeight={'medium'} mb={0} fontSize={'16px'}>
                  Telefone WhatsApp
                </Text>

                <Box w={'100%'}>
                  <HStack justify="space-between" w="100%" mt={'1em'}>
                    <TextField
                      name="fone"
                      type="tel"
                      bg={'#ececec'}
                      placeholder="Celular com DDD"
                      mask="(99) 99999-9999"
                      maskChar={null}
                      isDisabled={atualizarTelefone}
                    />
                    <Image
                      boxSize="15px"
                      objectFit="cover"
                      src={Editar}
                      alt=""
                      mr={'1.1em'}
                      onClick={() => {
                        setAtualizar(false);
                      }}
                    />
                  </HStack>
                </Box>
                <TextField
                  name="confirma_telefone"
                  type="tel"
                  bg={'#ececec'}
                  placeholder="Confirme o telefone"
                  mask="(99) 99999-9999"
                  maskChar={null}
                  w={'92%'}
                  mr={'1.5em'}
                />
                <VStack w={'100%'}>
                  <Button textColor={'#FFF'} bg={usuarioAssinante ? '#EB8B2A' : '#529C94'} w={'100%'} p={1} type="submit" mt={'2em'}>
                    Continuar
                  </Button>
                </VStack>
              </VStack>
            </Formik>
          </VStack>
        </Box>
      </VStack>
    </>
  );
}

export default AtualizarTelefone;
