import { Box, Button, ButtonGroup, Flex, HStack, Heading, Image, VStack, chakra, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AvisoPendencias from '../../../images/AvisoPendencias.svg';
import DrawerFaturamentoCancelamento from '../Assinaturas/Modal/DrawerFaturaCancelamento';
import { AccountContext } from 'components/AccountContext';

function TaxaCancelamento() {
  const navigate = useNavigate();
  const state = useLocation();
  const dados = state?.state;
  const { setPage, user, cancelaContrato } = useContext(AccountContext);
  console.log(dados)
  function taxaCancelamento() {
    navigate('/perfil/dependentes/taxa-cancelamento-valor', { state: dados });
  }
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const disclosure = useDisclosure();
  async function cancelar(){
    const pago = {
      pago: false,
      valor: dados.valor_multa,
    };

    const resposta = await cancelaContrato(user, pago, dados.motivo_cancelamento);

    if (!resposta.success) {
      return;
    }
    navigate('/perfil/assinaturas/contrato-cancelado-sucesso');
  
  }
  return (
    <>
      <ChildHeader />
      <DrawerFaturamentoCancelamento disclosure={disclosure} />
      <Box>
        <Flex minW="210px" maxW="340px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
          <VStack w={'100%'}>
            <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} p={'1em'} w={'100%'}>
              <Image boxSize="25px" objectFit="cover" src={AvisoPendencias} mr={'15em'}></Image>
              <Heading fontSize={'19'} color={'#585858'} fontWeight={'bold'}>
                Taxa de <b style={{ color: '#D60000' }}>cancelamento</b> do contrato{' '}
              </Heading>
              <chakra.span color={'#585858'} fontSize="16" textAlign="left">
              Se o contrato for rescindido e houver parcelas em aberto, será cobrada uma taxa de 30% das parcelas restantes.
              </chakra.span>
            </VStack>

            <Box position="fixed" bottom="1rem" left="40%" transform="translateX(-50%)" >
              <ButtonGroup
                sx={{ width: '100%' }}
                ml={isMinHeight ? '1.5em' : '2.2em'}
                mb="1em"
                mt={'1em'}
                onClick={() => taxaCancelamento()}
              >
                <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
                  Pagar taxa de cancelamento
                </Button>
              </ButtonGroup>
              <ButtonGroup sx={{ width: '100%' }} ml={isMinHeight ? '1.5em' : '2.2em'} mb="1em" onClick={() => cancelar()}>
                <Button name="btn" color="#585858" bg={'#F3F3F3'} type="submit" w={{ base: '100%' }} h={'2em'}>
                  Continuar cancelamento 😓
                </Button>
              </ButtonGroup>
            </Box>
          </VStack>
        </Flex>
      </Box>
    </>
  );
}

export default TaxaCancelamento;
