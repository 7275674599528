import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useState } from 'react';
import {
  VStack,
  useDisclosure,
  chakra,
  Center,
  HStack,
  Flex,
  Heading,
  Spacer,
  Image,
  ButtonGroup,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Cancelar from '../../../images/Cancelar.svg';
import Whatsapp from '../../../images/whatsapp.svg';
import Emoji from '../../../images/Emoji.svg';
import EmojiCoracoes from '../../../images/EmojiCoracoes.svg';
import CoracaoLaranja from '../../../images/CoracaoLaranja.svg';
import FamiliaIcon from '../../../images/familiaIcon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCircleInfo } from 'react-icons/fa6';
import DrawerFaturamentoCancelamento from '../Assinaturas/Modal/DrawerFaturaCancelamento';
function CancelarContrato() {
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
  const { setPage, setUser, user } = useContext(AccountContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  setPage('Cancelamento de contrato');
  const toast = useToast();
  const navigate = useNavigate();
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const state = useLocation();
  const { dependentes, pets } = state.state;
  const disclosure = useDisclosure();
  function callToast(title, descricao, status) {
    toast({
      title: title,
      description: descricao,
      status: status,
      duration: 6000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function cancelarContrato() {
    fetch(`${ENDPOINT}/clients/validaCancelandoContrato`, {
      method: 'POST',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ contrato_id: user.id_contrato, cpf: user.cpf }),
    })
      .catch((err) => {
        return;
      })
      .then(async (res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError('Erro na Rede');
          return res.json();
        }
        if (res.status === 200) {
          setError('');
        }
        return res.json();
      })
      .then(async (data) => {
        if (!data) return;
        setLoading(false);

        if (data.success !== true) {
          callToast('Importante', 'Ocorreu um problema ao cancelar seu contrato. Tente de novo ou entre em contato com o suporte', 'warning');
          return;
        }

        if ((data.multa === true && data.motivo === '12_parcelas_pagas') || (data.multa === 'true' && data.motivo === '12_parcelas_pagas')) {
          navigate('/perfil/assinaturas/taxa-cancelamento', { state: data });
        }

        if (
          (data.success === true && data.motivo === '12_parcelas_nao_pagas') ||
          (data.success === 'true' && data.motivo === '12_parcelas_nao_pagas')
        ) {
          navigate('/perfil/assinaturas/taxa-cancelamento', { state: data });
        }

        if ((data.multa === false && data.valor_multa === 0) || (data.multa === 'false' && data.valor_multa === 0)) {
          disclosure.onOpen();
        }
      });
  }

  const useDependentes = dependentes?.map((dependente, index) => {
    const arrName = dependente.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nome = Array.isArray(arrName) ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name) === false).join(' ') : '';
    return (
      <>
        <Text color={'#585858'} fontSize={'14px'} mb={2}>
          {nome}
        </Text>
        <Spacer border={'0.1px solid #f0f0f0'} w={'90%'} />
      </>
    );
  });

  const usePets = pets?.map((pet, index) => {
    const arrName = pet.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nome = Array.isArray(arrName) ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name) === false).join(' ') : '';

    return (
      <>
        <Text color={'#585858'} fontSize={'14px'} mb={2}>
          {nome}
        </Text>
        <Spacer border={'0.1px solid #f0f0f0'} w={'90%'} />
      </>
    );
  });

  return (
    <>
      <ChildHeader />
      <DrawerFaturamentoCancelamento disclosure={disclosure} />
      <VStack w={'100%'} mt={'1.5em'}>
        <Image w="30px" h={'30px'} objectFit="cover" src={Emoji} ml={'-16em'} />
        <VStack w={'100%'}>
          <Text color={'#EB8B2A'} fontSize={'18px'} fontWeight={'bold'} mb={'-0.5em'} ml={'-11em'}>
            E agora...
          </Text>
          <Text color={'#EB8B2A'} fontSize={'18px'} fontWeight={'bold'}>
            Que faço eu da vida sem você?
          </Text>
          <Box minW="210px" maxW="390px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
            <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'} mb={2}>
              Ficamos sabendo que você deseja cancelar sua assinatura DeoVita.😉
            </Text>
            <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'}>
              Estamos aqui para te ajudar a tomar uma decisão final.😓
            </Text>

            <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'} mt={2}>
              Antes de tudo... Vamos te lembrar das principais vantagens que você tem com sua assinatura DeoVita!
            </Text>
            <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'}>
              <Image w="30px" h={'30px'} src={EmojiCoracoes} />
            </Text>
          </Box>
          <Flex minW="210px" maxW="290px" bg={'#F3F3F3'} p={'10px'} borderRadius={'10px'}>
            <VStack w={'100%'}>
              <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                <Text color={'#EB8B2A'} fontSize={'16px'}>
                  Até 80% de desconto em consultas e exames médicos;
                </Text>
              </HStack>
              <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                <Text color={'#EB8B2A'} fontSize={'16px'}>
                  Telemedicina ilimitada e sem custo para você e seus dependentes;
                </Text>
              </HStack>
              <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                <Text color={'#EB8B2A'} fontSize={'16px'}>
                  A partir de 37% de desconto na Drogasil, Extrafarma e Pague Menos;
                </Text>
              </HStack>
              <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                <Text color={'#EB8B2A'} fontSize={'16px'}>
                  Clínico Geral Online 24h.
                </Text>
              </HStack>
              <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                <Text color={'#EB8B2A'} fontSize={'16px'}>
                  Telepet: veterinário online para tirar dúvidas sobre a saúde do seu amigo de 4 patas;
                </Text>
              </HStack>
              <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                <Text color={'#EB8B2A'} fontSize={'16px'}>
                  Grande rede de clínicas credenciadas;
                </Text>
              </HStack>
              <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                <Text color={'#EB8B2A'} fontSize={'16px'}>
                  E muito mais.
                </Text>
              </HStack>
            </VStack>
          </Flex>

          <VStack w={'100%'} position="relative">
            <Image w="30px" h={'40px'} src={FamiliaIcon} ml={'-16em'} />
            <Text
              color={'#EB8B2A'}
              fontSize={'16px'}
              fontWeight={'bold'}
              mt={'1em'}
              position="absolute"
              left={isMinHeight ? '2.5em' : '4.2em'}
              top="100%"
              transform="translateY(-10%)"
            >
              Confira os dependentes que
              <br />
              perderão seus benefícios
            </Text>
          </VStack>

          <Box minW={isMinHeight ? '380px' : '400px'} maxW="390px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
            {user.titular && user?.qtde_max_dependentes_total >= 1 && (
              <>
                <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'} mt={'2em'} mb={2} fontWeight={'bold'}>
                  Depedentes
                </Text>
                <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'}>
                  {useDependentes}
                </Text>{' '}
              </>
            )}
            <Text
              color={'#585858'}
              fontSize={'16px'}
              ml={isMinHeight ? '1.5em' : '2.2em'}
              mb={2}
              fontWeight={'bold'}
              mt={user.titular && user?.qtde_max_dependentes_total >= 1 ? '1em' : '2em'}
            >
              Pets
            </Text>
            <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'}>
              {usePets}
            </Text>

            <ButtonGroup
              sx={{ width: '85%' }}
              ml={isMinHeight ? '1.5em' : '2.2em'}
              mb="1em"
              mt={'1em'}
              onClick={() => navigate('/perfil/assinaturas/continua-contrato')}
            >
              <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
                Continuar com meu plano 😄
              </Button>
            </ButtonGroup>
            <ButtonGroup sx={{ width: '85%' }} ml={isMinHeight ? '1.5em' : '2.2em'} mb="1em" onClick={() => disclosure.onOpen()}>
              <Button name="btn" color="#585858" bg={'#F3F3F3'} type="submit" w={{ base: '100%' }} h={'2em'}>
                Não quero mais o plano 😓
              </Button>
            </ButtonGroup>
          </Box>
        </VStack>
      </VStack>
    </>
  );
}

export default CancelarContrato;
