import React, { useState, useEffect } from 'react';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import { Text, Button, VStack, Heading, Stack, HStack, chakra, Center, Alert, AlertIcon } from '@chakra-ui/react';
import { MdPendingActions } from 'react-icons/md';
import BottomNavBar from 'components/BottomNavBar';
import { useLocation } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdCreditCard } from 'react-icons/md';
import Loading from 'components/Loading';
import verifyPeriodOfTheDay from '../../Utils/Functions/verifyPeriodOfTheDay';

const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
        : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function DetalhesAnaliseSistema() {
    const { state } = useLocation();
    const { atendimento } = state;
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const data = state.atendimento.APP_DATE;
    const formData = new Date(data ? data.replace(' ', 'T') : '');
    const dataCad = state.atendimento.DATA_ATUALIZACAO;
    const appTime = state.atendimento.START_TIME;
    const [atendente, setAtendente] = useState([]);

    function myToLowerCase(text) {
        if (typeof text === 'string') {
            return text.toLocaleLowerCase();
        } else {
            return text;
        }
    }

    const fetchOperadorAtendimento = async (atendimento_id) => {
        const url = `${ENDPOINT}/appointments/fetchOperadorAtendimento/${atendimento_id}`;
        const response = await fetch(url, {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        });
        const postsData = await response.json();
        if (postsData) {
            setAtendente(postsData);
        }
    };

    useEffect(() => {
        fetchOperadorAtendimento(atendimento.APP_LID)
    }, [atendimento]);


    return (
        <>
            <ChildHeader />
            <VStack
                align="center"
                justify={{
                    base: 'center',
                    md: 'space-around',
                    xl: 'space-between',
                }}
                direction={{ base: 'column-reverse' }}
                wrap="no-wrap"
                spacing={5}
                px={4}
                py={6}
            >
                <Stack direction="row" align="center">
                    <Heading fontSize="lg">Processando Pagamento</Heading>
                    <MdCreditCard size="50" />
                </Stack>
                <Center>
                    {' '}
                    <Text alignItems="center">O pagamento de: </Text>
                </Center>

                <Center>
                    <Stack
                        borderWidth="1px"
                        borderRadius="25px"
                        minW="100%"
                        maxW="500px"
                        direction={{ base: 'column', md: 'row' }}
                        bg={'white'}
                        boxShadow={'lg'}
                        padding={4}
                    >
                        <VStack alignItems="right" spacing={4}>
                            <Heading fontSize="xs">
                                {atendimento.financeiro_faturas_pendentes_app_id
                                    ? 'Agendamento de exames'
                                    : state.atendimento?.ACTIVITY_NAME
                                        ? state.atendimento?.ACTIVITY_NAME
                                        : 'CONSULTA COM ' + state.atendimento?.ESPECIALIDADE?.toUpperCase()}
                            </Heading>
                            <Text ml="-5" fontSize="0.7em" casing="capitalize">
                                {atendimento.financeiro_faturas_pendentes_app_id
                                    ? atendimento.PROCEDURE_NAMES?.map((procedure) => {
                                        return <chakra.li>{procedure}</chakra.li>;
                                    })
                                    : state.atendimento.RESOURCE_NAME === 'Sem Solicitante' || state.atendimento.RESOURCE_NAME === null
                                        ? ''
                                        : 'Dr. ' + myToLowerCase(state.atendimento.RESOURCE_NAME)}
                            </Text>
                            <chakra.p fontSize="0.7em">
                                <chakra.span fontWeight="bold"> Modalidade: </chakra.span>
                                {state.atendimento.TELEMEDICINA === true ? 'Telemedicina' : 'Presencial'}
                            </chakra.p>
                            <chakra.p fontSize="0.7em">
                                <chakra.span fontWeight="bold"> Data do atendimento: </chakra.span>
                                {state.atendimento.RESOURCE_NAME === 'Sem Solicitante'
                                    ? //formData.toLocaleDateString('pt-br', options) +
                                    ' no turno da ' + verifyPeriodOfTheDay(appTime) + ' (Atendimento por ordem de chegada)'
                                    : formData.toLocaleDateString('pt-br', options)}
                            </chakra.p>
                            <chakra.p fontSize="0.7em">
                                <chakra.span fontWeight="bold"> Horário: </chakra.span>
                                {state.atendimento.RESOURCE_NAME === 'Sem Solicitante'
                                    ? //formData.toLocaleDateString('pt-br', options) +
                                    ' no turno da ' + verifyPeriodOfTheDay(appTime) + ' (Atendimento por ordem de chegada)'
                                    : appTime
                                        ? appTime.split(':')[0] + ':' + appTime.split(':')[1]
                                        : ''}
                            </chakra.p>
                        </VStack>
                    </Stack>
                </Center>

                <chakra.span textAlign="center" fontSize="1em" fontWeight="bold" >
                    {' '}
                    <Text alignItems="center">Está em processamento. Entre em contato com o(a) operador {atendente.nome} ou com o suporte para que seu agendamento seja faturado. </Text>
                </chakra.span>

                <chakra.span textAlign="center" fontSize="xs" fontWeight="bold" color="yellow.500"></chakra.span>
                <Stack>
                    <Alert status="info" fontSize="xs" borderRadius={20}>
                        <AlertIcon />
                        <Text noOfLines={1} fontSize="10">
                            Após a confirmação do pagamento você será notificado!
                        </Text>
                    </Alert>
                </Stack>
                <chakra.span textAlign="center" fontSize="xs" fontWeight="bold" color="red">
                    Caso o pagamento não seja realizado no prazo estipulado a sua aquisição será cancelada.
                </chakra.span>
            </VStack>
            <VStack mt="3" mb="15%">
                <Stack direction="column" spacing={2}>
                    <Text textAlign="center" fontSize="0.9em">
                        Problemas com seu agendamento?
                    </Text>
                    <Button
                        colorScheme="teal"
                        borderRadius={8}
                        height="40px"
                        onClick={() => {
                            window?.ReactNativeWebView?.postMessage(
                                JSON.stringify({
                                  service: true,
                                  openInBrowser: true,
                                  url: 'https://wa.me/5508002802133',
                                })
                            );
                        }}
                        width="350px"
                        variant="ghost"
                    >
                        Falar com Suporte
                    </Button>
                </Stack>
            </VStack>
            <BottomNavBar />
        </>
    )
}

export default DetalhesAnaliseSistema