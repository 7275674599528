import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import Loading from 'components/Loading';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { formataCentavosParaRealBrasileiro } from 'components/Utils/Functions/MoneyFunctions';
import DrawerSaque from './components/DrawerSaque';
import Valores from './components/Valores';
import DisponivelParaSaque from './components/DisponivelParaSaque';
import Aviso from './components/Aviso';
import AvisoNaoCadastrouDadosBancarios from './components/AvisoNaoCadastrouDadosBancarios';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const useRecipientBalanceData = () => {
  const [error, setError] = useState('');
  const [data, setData] = useState({
    aReceber: 0,
    ganhosTotais: 0,
    disponivelParaSaque: 0,
    recipient: {
      id: "",
      name: "",
      email: "",
      document: "",
      description: "",
      type: "",
      status: "",
      created_at: "",
      updated_at: "",
      deleted_at: "",
      default_bank_account: "",
      metadata: "",
      automatic_anticipation_settings: null,
      transfer_settings: null,
      code: null,
      payment_mode: ""
    }
  });

  const { user } = useContext(AccountContext);

  useEffect(() => {
    if (!user.recipient_id) {
      return;
    }
    if(data.recipient.id) {
      return;
    }

    const recipientId = user.recipient_id;
    fetch(`${ENDPOINT}/entrepreneur/balance/${recipientId}`, {
      method: 'GET',
      credentials: 'include',
    })
    .then((response) => response.json())
    .then((res) => {
      if (!res.recipientBalance?.data) {
        return;
      }

      setData({...res.recipientBalance.data});
    })
    .catch((err) => {
      console.error('Erro ao buscar recipient balance data:', err);
      setError('Erro ao buscar recipient balance data');
    });
  }, [data, setData, user.recipient_id]);

  return {...data, error};
};

const useValores = () => {
  const [values, setValues] = useState({ aReceber: 0, disponivelParaSaque: 0 });

  const recipientBalance = useRecipientBalanceData();

  useEffect(() => {

    try {
      if (!recipientBalance || recipientBalance.error) {
        return;
      }

      const { waiting_funds_amount, available_amount } = recipientBalance;

      const aReceber = waiting_funds_amount ? formataCentavosParaRealBrasileiro(waiting_funds_amount) : 0;
      const disponivelParaSaque = available_amount ? formataCentavosParaRealBrasileiro(available_amount) : 0;

      setValues({
        aReceber,
        disponivelParaSaque,
      });

    } catch (error) {
      console.error('Erro ao buscar valores:', error);
    }

  }, [setValues, recipientBalance]);

  return values;
};

const useGanhosTotais = () => {
  const [ganhosTotais, setGanhosTotais] = useState(0);

  // const { user } = useContext(AccountContext);

  // --- Descomente para fazer a chamada para buscar os ganhos totais
  // useEffect(() => {
  //   if (!user.recipient_id) {
  //     return;
  //   }
  //   const recipientId = user.recipient_id;
  //   fetch(`${ENDPOINT}/entrepreneur/getSumBalanceOperations/${recipientId}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //   })
  //   .then((response) => response.json())
  //   .then((res) => {
  //     console.log('res', res);
  //     if (!res?.sumBalanceOperations?.data) {
  //       return;
  //     }

  //     const ganhosTotais = res.sumBalanceOperations.data ? formataCentavosParaRealBrasileiro(res.sumBalanceOperations.data) : 0;

  //     setGanhosTotais(ganhosTotais);
  //   }).catch((err) => {
  //     console.error('Erro ao buscar ganhos totais:', err);
  //   });
  // }, [setGanhosTotais]);

  return ganhosTotais;
};

const useBankData = () => {
  const [bankData, setBankData] = useState(null);
  const { user } = useContext(AccountContext);


  useEffect(() => {
    fetch(ENDPOINT + '/entrepreneur/bank-data/' + user.id_empreendedor, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => response.json())
    .then((res) => {
      if (!res.data) {
        return;
      }

      setBankData(res.data);
    })
    .catch((err) => {
      console.error('Erro ao buscar dados bancários:', err);
    });
  }, [setBankData, user.id_empreendedor, user.token]);

  return bankData;
};

const useViewModel = () => {
  const { setPage } = useContext(AccountContext);
  setPage('Ganhos Totais');

  const { user } = useContext(AccountContext);
  const { aReceber, disponivelParaSaque } = useValores();
  const ganhosTotais = useGanhosTotais();
  const bankData = useBankData();
  const disclosure = useDisclosure();
  const recipientBalance = useRecipientBalanceData();

  const onSacarPress = () => {
    disclosure.onOpen();
  };

  const naoCadastrouDadosBancarios = !user?.recipient_id;

  const loading = useMemo(() => {
    if (naoCadastrouDadosBancarios) {
      return false;
    }
    return (!aReceber || !disponivelParaSaque) && !bankData;
  }, [aReceber, bankData, disponivelParaSaque, naoCadastrouDadosBancarios]);

  return {
    naoCadastrouDadosBancarios,
    aReceber,
    disponivelParaSaque,
    ganhosTotais,
    bankData,
    recipientBalance,
    disclosure,
    loading,
    onSacarPress
  };
};

export default function GanhosTotais() {
  const {
    naoCadastrouDadosBancarios,
    aReceber,
    disponivelParaSaque,
    ganhosTotais,
    loading,
    bankData,
    recipientBalance,
    disclosure,
    onSacarPress
  } = useViewModel();

  
  if (loading) {
    return (
      <>
        <ChildHeader />
        <Loading />
      </>
    );
  
  }
  
  if (naoCadastrouDadosBancarios) {
    return (
      <>
        <ChildHeader />
        <AvisoNaoCadastrouDadosBancarios />
      </>
    )
  }

  return (
    <>
      <ChildHeader />
      <DrawerSaque disclosure={disclosure} recipientBalance={recipientBalance} bankData={bankData} />
      <Box display={'flex'} flex={1} flexDir={'column'} padding={'20px'} gap={'20px'}>
        <Valores aReceber={aReceber} ganhosTotais={ganhosTotais} />
        <DisponivelParaSaque valor={disponivelParaSaque} onSacarPress={onSacarPress} />
        <Aviso />
      </Box>
    </>
  );
}
