import {
  Select,
  Flex,
  useDisclosure,
  Text,
  VStack,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Center,
  Avatar,
  HStack,
  List,
  Divider,
  Button,
  Badge,
  useToast,
} from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import Calendario from './Calendario';
import { GoBriefcase } from 'react-icons/go';
import { StarIcon } from '@chakra-ui/icons';
import { formatHour } from 'components/Utils/Functions/DateFunctions';
import _ from 'lodash';
import Loading from 'components/Loading';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function AgendamentoDependente(props) {
  const navigate = useNavigate();
  const { user, setPage, setUser } = useContext(AccountContext);
  const { state } = useLocation();
  const [cart, setCart] = useState(user.cart ? user.cart.itens : []);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);
  const [dependentes, setDependentes] = useState(null);
  const [medicos, setMedicos] = useState(null);
  const [dependenteSelecionado, setDependenteSelecionado] = useState(null);
  const [selecionado, setSelecionado] = useState(false);
  const [medicoStep, setMedicoStep] = useState(1);
  const [turno, setTurno] = useState('');
  const [medicosDatas, setMedicosDatas] = useState([]);
  const [dataMedicoHoras, setDataMedicoHoras] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [horaDataSelecionada, setHoraDataSelecionada] = useState(null);
  const [horaDataSelecionadaDisplay, setHoraDataSelecionadaDisplay] = useState(null);
  const [horario, setHorario] = useState(null);
  const [isByOrder, setIsByOrder] = useState(true);
  const [dadosMedico, setDadosMedico] = useState(null);
  const [isSaturday, setIsSaturday] = useState(false);




  const toast = useToast();
  const ShowToast = (title, description, status, duration, isClosable, position, colorScheme) => {
    toast({
      title,
      description,
      status,
      duration,
      isClosable,
      position,
      colorScheme,
      containerStyle: {
        paddingBottom: '5em',
      },
    });
  };

  setPage('Agendamento');

  const { titular, id_contrato } = user;
  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
      ? '#EB8B2A'
      : titular === true && id_contrato > 0
      ? '#EB8B2A'
      : '#529C94';

  const currentDate = (currentDateTime) => {
    if (currentDateTime) {
      currentDateTime.setUTCHours(currentDateTime.getUTCHours() - 3);
      return currentDateTime.toISOString().replace('T', ' ').split('.')[0].split(' ')[0];
    }
  };

  // Obter Dependentes
  const fetchDependentes = async () => {
    const url2 = ENDPOINT + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    if (resp.data[0] !== null) {
      setDependentes(resp.data);
    } else {
      setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita }]);
    }
  };

  // Obter Médicos
  const fetchMedicos = async () => {
    if (state.procedure.medico === true) {
      const url3 = ENDPOINT + '/schedule/getMedicosProcedimento';
      const payload = {
        empresaId: state.procedure.empresa_id,
        procedimentoTussId: state.procedure.procedimento_tuss_id,
      };
      const response = await fetch(url3, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      setMedicos(resp);
    }
  };

  // Obter datas dos médicos
  const fetchMedicosDatas = async () => {
    if (state.procedure.medico === true) {
      const url4 = ENDPOINT + '/schedule/getMedicosDatas';
      const payload = {
        empresaId: state.procedure.empresa_id,
        procedimentoTussId: state.procedure.procedimento_tuss_id,
        medicoAgenda: selecionado,
      };
      const response = await fetch(url4, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      setMedicosDatas(resp);
    }
  };

  // Obter horas dos dias
  const fetchHorasDatas = async () => {
    if (state.procedure.medico === true) {
      const url5 = ENDPOINT + '/schedule/getHorasDatasMedico';
      const payload = {
        empresaId: state.procedure.empresa_id,
        procedimentoTussId: state.procedure.procedimento_tuss_id,
        medicoAgenda: selecionado,
        data: currentDate(date),
      };
      const response = await fetch(url5, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      setDataMedicoHoras(resp);
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchDependentes();
    fetchMedicos();
    if (!state.procedure.medico) {
      setActiveTab(1);
    }
  }, []);

  // useEffect para obter as datas quando o usuario selecionar o medico na lista
  useEffect(() => {
    if (medicosDatas.length > 0) {
      setMedicosDatas([]);
    }
    fetchMedicosDatas();
  }, [selecionado]);

  // useEffect para obter os horarios dos dias quando apertar na data
  useEffect(() => {
    setLoading(true);
    fetchHorasDatas();
  }, [date]);

  // Datas para deixar apenas ativadas (quando não houver datas, deixar o array vazio)
  var datasDisponiveis = [];

  // Card Item médico
  const ListaMedicoItem = ({ medico }) => {
    const avaliacao = Math.round(medico.avaliacao_media);
    const handleClick = () => {
      setSelecionado(medico.medico_agenda);
      setDadosMedico({ operador_id: medico.operador_id, split: medico.split, id_recipient: medico.id_recipient });
    };

    return (
      <>
        <Box
          display={'flex'}
          backgroundColor={'#F3F3F3'}
          borderRadius={10}
          w={'100%'}
          padding={2}
          onClick={handleClick}
          borderWidth={1}
          borderColor={selecionado === medico.medico_agenda ? '#529C94' : '#F3F3F3'}
        >
          <Box flex={2}>
            <Avatar
              borderRadius="15px"
              size={'lg'}
              name={medico.nome}
              src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.operador_id + '/foto_profissional.webp'}
            />
          </Box>

          <VStack flex={8} paddingLeft={1}>
            <Text color={'#585858'} w={'100%'} fontWeight={'bold'} fontSize={'0.9em'} textAlign={'left'}>
              {medico.sexo === 'M' ? 'Dr. ' : 'Dra. '}
              {medico.nome}
            </Text>
            <HStack w={'100%'}>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                {avaliacao >= 1 ? <StarIcon color="#EB8B2A" fontSize="1em" mt={-0.3} /> : <StarIcon color="gray.500" fontSize="1em" mt={-0.3} />}
                <Text fontSize="1.em" color="#EB8B2A" align={'center'} fontWeight={600} ml={1}>
                  {avaliacao >= 5 ? '5' : avaliacao}
                </Text>
              </Box>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} color={'#529C94'}>
                <GoBriefcase color="#529C94" />{' '}
                <Text ml={1} fontWeight={'bold'} onClick={() => {navigate()}}>
                  Ver curriculo
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Box>
        <Divider h={2} />
      </>
    );
  };

  // Calendário com condicao de medico nao
  const TabCalendario = () => {
    return (
      <>
        <VStack>
          <Calendario date={date} setDate={setDate} setIsSaturday={setIsSaturday} datasDisponiveis={datasDisponiveis} backgroundColor={backgroundColor} />
          {isByOrder === true ? (
            <VStack w={'100%'} pr={4} pl={4}>
              <Badge
                id="turno_manha"
                padding="0.5em 1.4em"
                fontSize="md"
                w={'100%'}
                backgroundColor={turno === 'manha' ? backgroundColor : '#A3A3A3'}
                borderRadius={10}
                color={'#FFF'}
                onClick={() => {
                  setTurno('manha');
                  setHorario('07:30');
                  setHoraDataSelecionadaDisplay('07:30');
                }}
              >
                Manhã
              </Badge>


              {!isSaturday ?
              <>
                <Badge
                id="turno_manha"
                padding="0.5em 1.4em"
                fontSize="md"
                w={'100%'}
                borderRadius={10}
                color={'#FFF'}
                backgroundColor={turno === 'tarde' ? backgroundColor : '#A3A3A3'}
                onClick={() => {
                  setTurno('tarde');
                  setHorario('12:30');
                  setHoraDataSelecionadaDisplay('12:30');
                }}
                >
                  Tarde
                </Badge>
                </>
              :
              <></>}
            </VStack>
          ) : (
            <></>
          )}
          <Button w={'100%'} borderRadius={10} backgroundColor={backgroundColor} color={'#FFF'} onClick={checWithoutMedicoAndContinue}>
            Continuar
          </Button>
        </VStack>
      </>
    );
  };

  function updateState() {
    var tempState = user.cart.itens;
    const indiceItem = user.cart.itens.findIndex((item) => item.procedimento_tuss_id === state.procedure.procedimento_tuss_id);

    tempState[indiceItem].medico_selecionado = selecionado;

    if (state.procedure.medico && dadosMedico) {
      tempState[indiceItem].medico_id_recipient = dadosMedico.id_recipient;
      tempState[indiceItem].medico_split = dadosMedico.split;
    }

    tempState[indiceItem].dia_selecionado = currentDate(date);
    tempState[indiceItem].hora_selecionada = horaDataSelecionada;
    tempState[indiceItem].hora_selecionada_display = horaDataSelecionadaDisplay;

    tempState[indiceItem].isByOrder = isByOrder;
    tempState[indiceItem].empresa_id = state.procedure.empresa_id;

    tempState[indiceItem].descricao = state.procedure.descricao;
    tempState[indiceItem].medico = state.procedure.medico;
    tempState[indiceItem].bairro = state.procedure.bairro;
    tempState[indiceItem].estado = state.procedure.estado;
    tempState[indiceItem].cep = state.procedure.cep;
    tempState[indiceItem].distancia = state.procedure.distancia;
    tempState[indiceItem].horario_sab_fim = state.procedure.horario_sab_fim;
    tempState[indiceItem].horario_sab_inicio = state.procedure.horario_sab_inicio;
    tempState[indiceItem].horario_seg_sex_fim = state.procedure.horario_seg_sex_fim;
    tempState[indiceItem].horario_seg_sex_inicio = state.procedure.horario_seg_sex_inicio;
    tempState[indiceItem].latitude = state.procedure.latitude;
    tempState[indiceItem].longitude = state.procedure.longitude;
    tempState[indiceItem].numero = state.procedure.numero;
    tempState[indiceItem].telefone_01 = state.procedure.telefone_01;
    tempState[indiceItem].telefone_02 = state.procedure.telefone_02;
    tempState[indiceItem].empresa = state.procedure.empresa;
    tempState[indiceItem].preparo_descricao = state.procedure.preparo_descricao;
    tempState[indiceItem].preparo = state.procedure.preparo;
    tempState[indiceItem].logradouro = state.procedure.logradouro;
    tempState[indiceItem].numero = state.procedure.numero;

    tempState[indiceItem].particular_id = state.procedure.particular_id;

    tempState[indiceItem].valor_deovita = state.procedure.valor_deovita;
    tempState[indiceItem].valor_particular = state.procedure.valor_particular;


    if (isByOrder === true) {
      tempState[indiceItem].horario = horario;
    }

    setUser((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        itens: tempState,
      },
    }));
  }

  const checWithoutMedicoAndContinue = () => {
    if (turno === '' || date === null) {
      ShowToast('Selecione uma data', 'Verifique o dia e o horário para o procedimento.', 'warning', 4000, true, 'bottom', 'green');
      return;
    } else if (isByOrder === true) {
      updateState();
      navigate(-1);
    }
  };

  const checkMedicosAndContinue = () => {
    if (state.procedure.medico === true && !selecionado) {
      ShowToast('Aviso', 'Selecione um médico para continuar.', 'warning', 4000, true, 'bottom', 'green');
      return;
    } else if (medicoStep === 2) {
      if (date === null || horaDataSelecionada === null) {
        alert('Selecione data e hora');
      } else {
        updateState();
        navigate('/exames/empresa', { state: { procedureModalDisclosure: true } });
      }
    } else {
      setMedicoStep(2);
    }
  };

  const ListaHorasDatas = () => {
    const handleClick = (agendaExamesId, hour) => {
      setHoraDataSelecionada(agendaExamesId);
      setHoraDataSelecionadaDisplay(hour);
      setIsByOrder(false);
    };

    return (
      <>
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Box w={'100%'}>
              <Text w={'100%'} align={'left'} mb={2} fontWeight={'bold'}>
                Selecione um horário disponível
              </Text>
              <List overflow="auto" h={'10.625em'}>
                {dataMedicoHoras.map((e, i) => {
                  return (
                    <>
                      <Box
                        key={i}
                        h={'30px'}
                        bgColor={'#F3F3F3'}
                        display={'flex'}
                        alignItems={'center'}
                        paddingLeft={2}
                        onClick={() => {
                          handleClick(e.agenda_exames_id, formatHour(e.inicio));
                        }}
                        borderRadius={10}
                        borderWidth={1}
                        borderColor={horaDataSelecionada === e.agenda_exames_id ? '#529C94' : '#F3F3F3'}
                      >
                        <Text>{formatHour(e.inicio)}</Text>
                      </Box>
                      <Divider h={'5px'} />
                    </>
                  );
                })}
              </List>
            </Box>
          </>
        )}
      </>
    );
  };

  const TabMedicos = () => {
    if (medicoStep === 1) {
      return (
        <>
          {loading ? (<Loading />) : (
          <List overflow="auto" h={'100%'}>
            {state.procedure.medico ? medicos && medicos.map((e, i) => <ListaMedicoItem medico={e} key={i} />) : <Text>Não há médicos disponíveis para este procedimento.</Text>}

          </List>
        )}
        </>
      );
    } else if (medicoStep === 2) {
      if (medicosDatas.length > 0) {
        const arrayDeDatas = medicosDatas.map((item) => new Date(item.data));
        datasDisponiveis = [...datasDisponiveis, ...arrayDeDatas];
      }

      return (
        <>
          <Text w={'100%'} align={'left'} fontWeight={'bold'}>
            Selecione uma Data disponível
          </Text>
          <Calendario date={date} setDate={setDate} datasDisponiveis={datasDisponiveis} backgroundColor={backgroundColor} />
          {date ? (
            <VStack w={'100%'}>
              <ListaHorasDatas />
            </VStack>
          ) : (
            <></>
          )}
        </>
      );
    }
  };

  // Tabs
  const TabsMedicoCalendario = () => {
    return (
      <Tabs align="center" w="100%" colorScheme="teal" index={activeTab} onChange={(index) => setActiveTab(index)}>
        <TabList w="100%">
          <Tab isDisabled={!state.procedure.medico} fontSize="14px">
            AGENDA MÉDICO
          </Tab>
          <Tab fontSize="14px">AGENDA CALENDÁRIO</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Center>
              <VStack paddingLeft={9} paddingRight={9} h={'65vh'}>
                <TabMedicos />
                <Box w={'100%'}>
                  {state.procedure.medico ? (
                    <Button w={'100%'} borderRadius={10} backgroundColor={backgroundColor} color={'#FFF'} onClick={checkMedicosAndContinue}>
                      Continuar
                    </Button>
                  ) : (
                    <>
                    </>
                  )}
                </Box>
              </VStack>
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <TabCalendario />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };

  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack h={'80vh'}>
        <Box paddingLeft={9} paddingRight={9}>
          <Flex direction="row" justifyContent="space-between" w="4.em" h="50px" backgroundColor="#F3F3F3" padding={2} mt={4} borderRadius="5px">
            <Box w="40%" display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Text fontSize={14} color="#585858">
                Exame Selecionado:
              </Text>
            </Box>

            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Text fontWeight={'bold'} fontSize={12}>
                {state.procedure.nome}
              </Text>
            </Box>
          </Flex>
        </Box>
        <TabsMedicoCalendario />
      </VStack>
    </>
  );
}

export default AgendamentoDependente;
