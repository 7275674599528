import { Box, Button, ButtonGroup, Flex, HStack, Image, Text, VStack, useMediaQuery, useToast } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EmojiCoracoes from '../../../images/EmojiCoracoes.svg';
import CoracaoLaranja from '../../../images/CoracaoLaranja.svg';
import { AccountContext } from 'components/AccountContext';
import LoadingGenerico from 'components/AreaLivre/DashboardLivre/LoadingGenerico';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function TentativaReterCancelamento() {
  const { setPage, user, cancelaContrato } = useContext(AccountContext);
  const state = useLocation();
  const { option, motivos } = state?.state;

  const navigate = useNavigate();
  var titulo = '';
  var paragrafoUm = '';
  var paragrafoDois = '';
  switch (option) {
    case 0:
      titulo = 'Podemos ter um credenciado perto do seu novo endereço!';
      paragrafoUm = 'E você ainda pode continuar usufruindo da nossa telemedicina com clínico geral 24h e 13 especialidades.';
      paragrafoDois = 'Confira a nossa rede credenciada em nosso site:';
      break;
    case 1:
      titulo = 'Cuidar da sua saúde não tem preço!';
      paragrafoUm = 'Se você realmente precisa economizar, então o DeoVita é o seu melhor aliado.';
      paragrafoDois = '';
      break;
    case 2:
      titulo = 'Saúde nunca é demais!';
      paragrafoUm = 'Já conferiu se o outro serviço possui telemedicina ilimitada e vários descontos em consultas, exames e medicamentos?';
      paragrafoDois = 'Porque no DeoVita você encontra tudo isso e muito mais!';
      break;
    case 3:
      titulo = 'Ficamos felizes por saber que você continuará no DeoVita!';
      paragrafoUm = 'Quando sua assinatura for cancelada, seu CPF estará liberado para se tornar dependente.';
      paragrafoDois = '';
      break;
    case 4:
      titulo = 'Estamos constantemente expandindo nossa rede credenciada!';
      paragrafoUm =
        'Mas talvez não estejamos perto de você... Porém, você ainda pode aproveitar nossa telemedicina ilimitada com clínico geral 24h e 13 especialidades sem custo adicional!';
      paragrafoDois = '';
      break;
    case 5:
      titulo = 'Talvez você não conheça a infinidade de benefícios que você tem com o DeoVita! Confira alguns:';
      paragrafoUm = 'Deixa esse negócio de cancelamento pra lá e aproveite ainda mais sua assinatura!';
      paragrafoDois = '';
      break;
    case 6:
      titulo = 'Estamos sempre trabalhando para melhorar nosso atendimento!';
      paragrafoUm =
        'Entendemos sua insatisfação e estamos aqui para te ajudar. Se estiver com algum problema que não esteja sendo visto pela nossa equipe, entre em contato conosco através do e-mail: contato@deovita.com.br';
      paragrafoDois = '';
      break;
    case 7:
      titulo = 'O DeoVita conta com telemedicina ilimitada e é a mais acessível do Brasil!';
      paragrafoUm =
        'Nossa tabela pode chegar a até 80% de desconto na rede credenciada e nosso clube de vantagens faz você economizar muito no seu dia a dia!';
      paragrafoDois = 'Deixa esse negócio de cancelamento pra lá e aproveite ainda mais sua assinatura!';
      break;
    default:
  }
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const [iphoneXr] = useMediaQuery('(max-height: 896px)');
  const [isInRange] = useMediaQuery('(max-height: 840px) and (max-height: 1000px)');
  const toast = useToast();

  function callToast(title, descricao, status) {
    toast({
      title: title,
      description: descricao,
      status: status,
      duration: 6000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function cancelarContrato() {
    fetch(`${ENDPOINT}/clients/validaCancelandoContrato`, {
      method: 'POST',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ contrato_id: user.id_contrato, cpf: user.cpf }),
    })
      .catch((err) => {
        return;
      })
      .then(async (res) => {
        if (!res || !res.ok || res.status >= 400) {
          return res.json();
        }
        if (res.status === 200) {
        }
        return res.json();
      })
      .then(async (data) => {
     
        const dados_motivo = { option: option, texto: motivos[option] };

        if (!data) return;

        if (data.success !== true) {
          callToast('Importante', 'Ocorreu um problema ao cancelar seu contrato. Tente de novo ou entre em contato com o suporte', 'warning');
          return;
        }

        if ((data.multa === true && data.motivo === '12_parcelas_pagas') || (data.multa === 'true' && data.motivo === '12_parcelas_pagas')) {
          navigate('/perfil/assinaturas/taxa-cancelamento', { state: { ...data, motivo_cancelamento: dados_motivo } });
          return;
        }

        if (
          (data.success === true && data.motivo === '12_parcelas_nao_pagas') ||
          (data.success === 'true' && data.motivo === '12_parcelas_nao_pagas')
        ) {
          navigate('/perfil/assinaturas/taxa-cancelamento', { state: { ...data, motivo_cancelamento: dados_motivo } });
          return;
        }

        if ((data.multa === false && data.valor_multa === 0) || (data.multa === 'false' && data.valor_multa === 0)) {
          if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
            navigate('/perfil/assinaturas/cancelamento-final ', {
              state: {
                pago: {
                  pago: false,
                  valor: user && user.qntdeParcelas && user.qntdeParcelas.valorParcelas ? user.qntdeParcelas.valorParcelas : null,
                },
                motivo: dados_motivo,
              },
            });
            return;
          }
        }

        const pago = {
          pago: true,
          valor: 0,
        };
        const motivo = {
          option: option,
          texto: motivos[option],
        };

        const resposta = await cancelaContrato(user, pago, motivo);

        if (!resposta.success) {
          return;
        }
        navigate('/perfil/assinaturas/contrato-cancelado-sucesso');
      });
  }

  return (
    <>
      <ChildHeader />
      <LoadingGenerico />
      <VStack w={'100%'} h={isMinHeight ? '75vh' : '80vh'} justifyContent="space-between">
        <VStack w={'100%'}>
          <VStack w={'100%'} alignItems="center">
            <Image w="60px" h={'60px'} src={EmojiCoracoes} ml={isMinHeight ? '-13em' : '-16em'} mb={'-4em'} />
          </VStack>
          <Text fontSize={'18px'} fontWeight={'bold'} color={'#EB8B2A'} p={'3em'} ml={'1em'} mt={'-3em'}>
            {titulo}
          </Text>

          <Flex minW="210px" maxW="290px" borderRadius={'10px'}>
            <VStack w={'100%'} mt={'-3em'}>
              {option === 5 ? (
                <>
                  <Flex minW="210px" maxW="290px" bg={'#F3F3F3'} p={'10px'} borderRadius={'10px'}>
                    <VStack w={'100%'} p={'1em'}>
                      <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                        <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                        <Text color={'#EB8B2A'} fontSize={'16px'}>
                          Telemedicina Ilimitada;
                        </Text>
                      </HStack>
                      <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                        <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                        <Text color={'#EB8B2A'} fontSize={'16px'}>
                          Clínico Geral online 24h;
                        </Text>
                      </HStack>
                      <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                        <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                        <Text color={'#EB8B2A'} fontSize={'16px'}>
                          13 Especialidades médicas online sem custo adicional;
                        </Text>
                      </HStack>
                      <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                        <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                        <Text color={'#EB8B2A'} fontSize={'16px'}>
                          A partir de 37% de desconto em genéricos;
                        </Text>
                      </HStack>
                      <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                        <Image w="14.04px" h={'30px'} src={CoracaoLaranja} />
                        <Text color={'#EB8B2A'} fontSize={'16px'}>
                          Descontos de até 40% em lojas e cinemas parceiros.
                        </Text>
                      </HStack>
                    </VStack>
                  </Flex>{' '}
                </>
              ) : (
                <></>
              )}
              <Box w={'100%'} ml={isMinHeight ? '1.2em' : iphoneXr ? '-1.2em' : '-1.8em'}>
                {paragrafoUm !== null ? (
                  <Text fontSize={'16px'} color={'#585858'} mb={paragrafoDois ? '1em' : 0}>
                    {paragrafoUm}
                  </Text>
                ) : (
                  <></>
                )}

                {paragrafoDois !== null ? (
                  <Text fontSize={'16px'} color={'#585858'}>
                    {paragrafoDois}
                  </Text>
                ) : (
                  <></>
                )}
              </Box>
            </VStack>
          </Flex>
          {option === 0 ? (
            <>
              <ButtonGroup
                sx={{ width: isMinHeight ? '75%' : '80%' }}
                ml={isMinHeight ? '1.5em' : '2.2em'}
                mt={'1em'}
                onClick={() => window.open('https://deovita.com.br/onde-estamos/', '_blank')}
              >
                <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
                  Continuar
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <></>
          )}
        </VStack>

        <Box w="100%">
          <ButtonGroup
            sx={{ width: '85%' }}
            ml={isMinHeight ? '1.8em' : '2.2em'}
            mb="1em"
            onClick={() => navigate('/perfil/assinaturas/continua-contrato')}
          >
            <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
              Continuar com meu plano 😄
            </Button>
          </ButtonGroup>
          <ButtonGroup
            sx={{ width: '85%' }}
            ml={isMinHeight ? '1.8em' : '2.2em'}
            mb="1em"
            onClick={async () => {
              await cancelarContrato();
            }}
          >
            <Button name="btn" color="#909090" bg={'#F3F3F3'} type="submit" w={{ base: '100%' }} h={'2em'}>
              Não quero mais o plano 😓
            </Button>
          </ButtonGroup>
        </Box>
      </VStack>
    </>
  );
}

export default TentativaReterCancelamento;
