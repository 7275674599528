import { Box, HStack, Image, Spacer, chakra, VStack, RadioGroup, Radio, Center, Heading, Text, Flex, useMediaQuery, ButtonGroup, Button } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function MotivosCancelamentoContrato() {
  const motivos = [
    'Mudança de endereço',
    'Contensão de despesas',
    'Contratei outro serviço',
    'Vou me tornar um dependente',
    'Falta de rede credenciada',
    'Não utilizo os serviços',
    'Insatisfação com o atendimento',
    'Insatifeito com o preço do serviço',
  ];
  const [option, setOption] = useState(null);
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const navigate = useNavigate();

  const useMotivos = motivos.map((motivo, i) => {
    return (
      <VStack key={i} justify="space-between" w="100%">
        <RadioGroup name="select" w="100%" value={option}>
          <VStack align="start" w="100%">
            <Radio
              key={i}
              id={i}
              value={i}
              padding="1em"
              mt="-1em"
              style={{ backgroundColor: option === i ? 'orange' : 'inherit' }}
              onChange={(e) => setOption(parseInt(e.target.value))}
            >
              {motivo}
            </Radio>
          </VStack>
        </RadioGroup>
      </VStack>
    );
  });
  return (
    <>
      <ChildHeader />
      <VStack w="100%" alignItems="center">
        <Text color="#EB8B2A" fontSize="18px" ml={'2em'} textAlign={'left'} mb={'-1.5em'} p={isMinHeight ? '1em' : '2em'} fontWeight={'bold'}>
          Nos diga porque você quer cancelar seu plano DeoVita
        </Text>
        <Flex minW="10em" maxW="20em" p="10px" borderRadius="10px">
          <VStack w="100%" spacing="10px">
            {useMotivos}
          </VStack>
        </Flex>
        {option !== null ? (
          <>
            <ButtonGroup
              sx={{ width: '85%' }}
              ml={isMinHeight ? '1.5em' : '2.2em'}
              mt={'1em'}
            >
              <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'} mb="1em" onClick={() => navigate('/perfil/assinaturas/tentativa-reter-cancelamento', {state: {option: option, motivos: motivos}})}>
                Continuar 
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <></>
        )}
      </VStack>
    </>
  );
}

export default MotivosCancelamentoContrato;
