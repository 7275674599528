async function sendMessageSolicitante(ENDPOINT, orcamento_id) {
    console.log("sendMessageSolicitante")
    try {
      const url = ENDPOINT + '/whatsapp/sendMessageSolicitante/' + orcamento_id;
      await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
    } catch (error) {
      console.log("sendMessageSolicitante error: " + error);
    }
  }

  export default sendMessageSolicitante;