import React, { useEffect, useState } from 'react';

import AgendamentoCalendarioimg from '../../../../../images/agendamentoCalendario.svg';
import AgendamentoCalendarioimgPng from '../../../../../images/agendamentoCalendario.png';

import Google from '../../../../../images/Google.svg';
import Apple from '../../../../../images/Apple.svg';
import { Button, Image, Text, VStack, chakra, useMediaQuery, useToast } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

function AgendamentoCalendario() {
  const navigate = useNavigate();
  const state = useLocation();
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  const [iphone, setIphone] = useState(true);
  const toast = useToast();
  function isIOS() {
    const userAgent = window.navigator.userAgent;
    return /iPhone|iPad|iPod/i.test(userAgent);
  }

  useEffect(() => {
    const dispositivo = isIOS();
    if (!dispositivo) {
      setIphone(false);
    } else {
      setIphone(true);
    }
  }, []);

  return (
    <>
      <VStack w={'100%'} alignItems={'center'} spacing={4}>
        <Image src={AgendamentoCalendarioimg} mt={'6em'} />
        <Text fontWeight="bold" fontSize="22px" color={'#585858'} textAlign={'center'}>
          Sucesso!
        </Text>
        <VStack alignItems={'center'} spacing={2}>
          <Text fontWeight="bold" fontSize="14px" color={'#585858'} textAlign={'center'}>
            Seu atendimento foi agendado!
          </Text>
          <Text fontWeight="bold" fontSize="14px" color={'#585858'} textAlign={'center'}>
            Lembre-se de entrar no aplicativo 15 <br /> minutos antes do início da sua consulta.
          </Text>
        </VStack>

        <VStack bg={'#EB8B2A'} borderRadius={'0.5em'} w={'80%'} p={'2'}>
          <Text fontWeight="bold" fontSize="12px" color={'white'} textAlign={'center'}>
            Para não esquecer
          </Text>
          <Text fontWeight="bold" fontSize="12px" color={'white'} textAlign={'center'}>
            Adicione este lembrete á sua agenda!
          </Text>

          <Button
            bg={'#FFFFFF'}
            color={'#000000'}
            w={miniLargeHeight ? '60%' : '50%'}
            fontSize="12px"
            onClick={() => {
              let google = 'Google Calendar';
              let eventData = {
                title: state.state.eventData.title,
                startDate: state.state.eventData.startDate,
                endDate: state.state.eventData.endDate,
                location: state.state.eventData.location,
                notes: state.state.eventData.notes,
                preferredCalendar: google,
              };

              window.ReactNativeWebView.postMessage(JSON.stringify({ calendarEvent: eventData }));
            }}
          >
            <Image src={Google} /> <Text ml={'1em'}> Google Agenda </Text>
          </Button>

          <Button
            bg={'#FFFFFF'}
            color={'#000000'}
            w={miniLargeHeight ? '60%' : '50%'}
            fontSize="12px"
            onClick={() => {
              let apple = 'Apple Calendar';
              let eventData = {
                title: state.state.eventData.title,
                startDate: state.state.eventData.startDate,
                endDate: state.state.eventData.endDate,
                location: state.state.eventData.location,
                notes: state.state.eventData.notes,
                preferredCalendar: apple,
                agenda_exames_id: state.state.eventData.agenda_exames_id
              };
              window.ReactNativeWebView.postMessage(JSON.stringify({ calendarEvent: eventData }));
            }}
          >
            <Image src={Apple} /> <Text ml={'1em'}> Apple Calendar </Text>
          </Button>
        </VStack>
        <Button bg={'#EB8D00'} color={'white'} w={miniLargeHeight ? '60%' : '50%'} onClick={() => navigate('/consultas')}>
          Fechar
        </Button>
      </VStack>
    </>
  );
}

export default AgendamentoCalendario;
