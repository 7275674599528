import React, { useContext } from 'react';
import {
  chakra,
  Box,
  Img,
  LinkBox,
  Text,
  VStack,
  Checkbox,
  Image,
  RadioGroup,
  Stack,
  Radio,
  Spinner,
  Heading,
  useDisclosure,
  Textarea,
  Center,
  Highlight,
  Container,
  Badge,
  Flex,
  HStack,
  useMediaQuery,
  Button,
} from '@chakra-ui/react';
import BottomNavBar from 'components/BottomNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import Voltar from '../../../../../images/Voltar.svg';
import Contact from '../../../../../images/ClinicoIcon.svg';
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { AccountContext } from 'components/AccountContext';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import TelemedDeslogado from '../../../../../../src/images/Deslogado/telemedDeslogado.svg';
function AvisoPreAtendimento2() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');
  const drawerHeight = isLargeHeight ? '50vh' : '40vh';
  const { user } = useContext(AccountContext);

  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <>
      <VStack
        align="center"
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        pt="6em"
        pb="6em"
        background={usuarioAssinante ? '#EB8B2A' : '#529C94'}
        color="#585858"
        overflow="hidden"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box as="card" width="328px" borderRadius="18px" fontSize="xs" size="10" backgroundColor="white" maxInlineSize="80" textAlign="center">
          <VStack m={{ sm: '10%' }} textColor="#585858">
            <Image top={{ lg: '5.5em', sm: '5em' }} src={!usuarioAssinante ? TelemedDeslogado : Contact} alt="teste" height={'4.5em'} />
            <Text fontWeight="bold" fontSize="1.5em">
              Orientações do Pronto Atendimento
            </Text>
            <HStack backgroundColor="#E5E5E5" borderRadius="15px">
              <Text>
                <Flex ml="1em">
                  <Box
                    backgroundColor={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                    borderRadius="5px"
                    margin="0.8em"
                    color="rgba(62, 100, 255, 1)"
                    padding="0 0.4em"
                  ></Box>
                  <Text p={'5'} fontSize="xs" align="left" color="#585858;">
                    <chakra.p>
                      Em casos <b>emergenciais</b> e <b>urgências</b>, é recomendado tratamento médico presencial imediato;
                    </chakra.p>
                  </Text>
                </Flex>
                <Flex ml="1em">
                  <Box
                    backgroundColor={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                    borderRadius="5px"
                    margin="0.8em"
                    color="rgba(62, 100, 255, 1)"
                    padding="0 0.4em"
                  ></Box>
                  <Text p={'5'} fontSize="xs" align="left" color="#585858;">
                    <chakra.p>Esteja em ambiente calmo e silencioso, para maior conforto durante a consulta;</chakra.p>
                  </Text>
                </Flex>
                <Flex ml="1em">
                  <Box
                    backgroundColor={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                    borderRadius="5px"
                    margin="0.8em"
                    color="rgba(62, 100, 255, 1)"
                    padding="0 0.4em"
                  ></Box>
                  <Text p={'5'} fontSize="xs" align="left" color="#585858;">
                    <chakra.p>
                      Para a sua <b>segurança</b>, seu atendimento será <b>gravado</b>.
                    </chakra.p>
                  </Text>
                </Flex>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#D9D9D9',
                    display: 'inline-block',
                  }}
                ></div>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: usuarioAssinante ? '#EB8B2A' : '#529C94',
                    display: 'inline-block',
                  }}
                ></div>
                <VStack w="100%" p={'1em'} ml={'40%'}>
                  <BsFillArrowLeftCircleFill size={'2em'} color={usuarioAssinante ? '#EB8B2A' : '#529C94'} onClick={() => navigate(-1)} />
                </VStack>
              </Text>
            </HStack>

            <VStack w="100%" p="1em" ml="40%">
              <VStack alignItems="left" w="100%">
                <Button
                  bg={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                  color="#FFFFFF"
                  w="100%"
                  onClick={() => {
                    if (user.termoTelemed) {
                      navigate(`${state?.tela}`, {
                        state: {
                          pediatra: state?.pediatra,
                          pago: state.pago,
                          credito: state?.credito,
                          metadataCoparticipacao: state?.metadataCoparticipacao,
                        },
                      });
                    } else {
                      navigate('/termos-de-uso/', {
                        state: {
                          pediatra: state?.pediatra,
                          tela: state?.tela,
                          pago: state.pago,
                          credito: state?.credito,
                          metadataCoparticipacao: state?.metadataCoparticipacao,
                        },
                      });
                    }
                  }}
                  borderRadius="0.8em"
                  h="2em"
                >
                  Entendi
                </Button>
              </VStack>
            </VStack>
          </VStack>
        </Box>
      </VStack>
    </>
  );
}

export default AvisoPreAtendimento2;
