import { React, useState, useContext, forwardRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Text, Tabs, TabList, TabPanels, TabPanel, Box, useTab, useMediaQuery } from '@chakra-ui/react';
import CartaoGenerico from './CartaoGenerico';
import PixGenerico from './PixGenerico';
import { AccountContext } from '../AccountContext';
import ChildHeader from '../Dashboard/Main/ChildHeader';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import BottomNavBar from '../BottomNavBar';
import { ReactComponent as CreditCardImg } from '../../images/card.svg';
import { ReactComponent as PixImg } from '../../images/pix.svg';
import { ReactComponent as BoletoImg } from '../../images/boleto.svg';
import { ReactComponent as Lampada } from '../../images/lamp.svg';
import BoletoGenerico from './BoletoGenerico';
import Loading from '../LoadingWithHeader';
import { getPagamentos } from 'api/plans';
import FormularioEnel from './FormularioEnel';

const TabPagamento = forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];

  return (
    <Box
      {...tabProps}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={6}
      py={4}
      borderRadius={10}
      bgColor={isSelected ? '#529C94' : '#E0E0E0'}
      color={isSelected ? 'white' : '#585858'}
      fontSize={'sm'}
      textAlign={'center'}
    >
      {tabProps.children}
    </Box>
  );
});

const Menu = () => {
  /*
      passar as via state por useLocation:
      navig_to: página para onde deve ser redirecionada após ser concluido com sucesso o pagamento (string)
      valor: valor a ser cobrado (float)
      parcesActive: define se o campo de parcelamento deve ficar ativo para o usuário selecionar em quantas vezes deseja dividir o pagamento (bool)
      expitation_pix_in_min: define o tempo de vencimento do pix em minutos (int)
      item: a descricao dos itens que estão sendo cobrados para exibição na tela de pix (string)
  */

  const [lg] = useMediaQuery('(min-width: 425px)');
  let { state } = useLocation();

  const { user, setPage } = useContext(AccountContext);
  const { navig_to, valor } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;
  const [tab, setTab] = useState(0);
  const [loadingPagamentos, setLoadingPagamentos] = useState(true);
  const [formasPagamento, setFormasPagamento] = useState([]);
  const navigate = useNavigate();

  const temPagamento = useCallback(
    (formaRendimentoId) => {
      return formasPagamento.some(({ formaRendimento }) => formaRendimento === formaRendimentoId);
    },
    [formasPagamento]
  );

  const getValorPagamento = useCallback(
    (formaRendimentoId) => {
      return formasPagamento.find(({ formaRendimento }) => formaRendimento === formaRendimentoId).valor;
    },
    [formasPagamento]
  );

  const getPercent = useCallback(
    (formaRendimentoId, tipoId) => {
      const forma = formasPagamento.find(({ formaRendimento }) => formaRendimento === formaRendimentoId);

      if (forma.split) {
        return forma.split.find(({ tipo_id }) => tipo_id === tipoId).percentual;
      } else {
        return null;
      }
    },
    [formasPagamento]
  );

  const getParcelamento = useCallback(
    (formaRendimentoId) => {
      const forma = formasPagamento.find(({ formaRendimento }) => formaRendimento === formaRendimentoId);

      return forma.parcelamento;
    },
    [formasPagamento]
  );

  const getValorminimoParcela = useCallback(
    (formaRendimentoId) => {
      const forma = formasPagamento.find(({ formaRendimento }) => formaRendimento === formaRendimentoId);

      return forma.valorMinimoParcela;
    },
    [formasPagamento]
  );

  const getParcelamentoMaximo = useCallback(
    (formaRendimentoId) => {
      const forma = formasPagamento.find(({ formaRendimento }) => formaRendimento === formaRendimentoId);

      return forma.parcelamentoMaximo;
    },
    [formasPagamento]
  );

  setPage('Pagamentos');

  useEffect(() => {
    if (state.id_plano && state.frequencia) {
      (async () => {
        try {
          const data = await getPagamentos(state.id_plano, state.frequencia);
          setFormasPagamento(
            data.map(
              ({ forma_rendimento_id, valor, perm_parcelamento, valor_minimo_parcela, parcelamento_maximo, supervisor, vendedor, gerente }) => {
                return {
                  formaRendimento: forma_rendimento_id,
                  valor: Number(valor),
                  parcelamento: perm_parcelamento,
                  valorMinimoParcela: valor_minimo_parcela,
                  parcelamentoMaximo: parcelamento_maximo,
                  split: [
                    {
                      tipo_id: 1,
                      percentual: supervisor,
                    },
                    {
                      tipo_id: 2,
                      percentual: gerente,
                    },
                    {
                      tipo_id: 3,
                      percentual: vendedor,
                    },
                  ],
                };
              }
            )
          );
        } catch (error) {
          navigate('/alerts/erro/cadastro_plano');
        } finally {
          setLoadingPagamentos(false);
        }
      })();
    } else {
      setFormasPagamento(
        [9, 12].map((forma) => {
          return {
            formaRendimento: forma,
            valor: Number(valor),
            split: null,
          };
        })
      );
      setLoadingPagamentos(false);
    }
  }, [state]);

  return loadingPagamentos ? (
    <Loading />
  ) : (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <Box p={10}>
        <Text fontWeight="bold" color="#585858" mb={4}>
          Escolha uma forma de pagamento
        </Text>

        <Tabs
          isLazy
          isFitted
          index={tab}
          onChange={(index) => {
            setTab(index);
          }}
        >
          <TabList display={'grid'} gridTemplateColumns={lg ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'} gap={4} borderBottom={'none'} mb={4}>
            {temPagamento(12) && (
              <TabPagamento>
                <CreditCardImg width={15} height={15} />
                <Text>Cartão</Text>
              </TabPagamento>
            )}

            {temPagamento(9) && (
              <TabPagamento>
                <PixImg width={15} height={15} />
                <Text>Pix</Text>
              </TabPagamento>
            )}

            {temPagamento(11) && (
              <TabPagamento>
                <BoletoImg width={15} height={15} />
                <Text>Boleto</Text>
              </TabPagamento>
            )}

            {/*temPagamento(13) && (
              <TabPagamento>
                <Lampada width={15} height={15} />
                <Text>
                  Conta
                  <br />
                  de Luz
                </Text>
              </TabPagamento>
            )*/}
          </TabList>
          <TabPanels>
            {temPagamento(12) && (
              <TabPanel>
                <CartaoGenerico
                  permiteParcelamento={getParcelamento(12)}
                  valorMinimoParcela={getValorminimoParcela(12)}
                  parcelamentoMaximo={getParcelamentoMaximo(12)}
                  selecionado={getValorPagamento(12)}
                  mdvPercent={getPercent(12, 3)}
                  mdvGerente={getPercent(12, 2)}
                  mdvDiretor={getPercent(12, 1)}
                />
              </TabPanel>
            )}

            {temPagamento(9) && (
              <TabPanel>
                <PixGenerico
                  selecionado={getValorPagamento(9)}
                  mdvPercent={getPercent(9, 3)}
                  mdvGerente={getPercent(9, 2)}
                  mdvDiretor={getPercent(9, 1)}
                />
              </TabPanel>
            )}

            {temPagamento(11) && (
              <TabPanel>
                <BoletoGenerico
                  selecionado={getValorPagamento(11)}
                  mdvPercent={getPercent(11, 3)}
                  mdvGerente={getPercent(11, 2)}
                  mdvDiretor={getPercent(11, 1)}
                ></BoletoGenerico>
              </TabPanel>
            )}

          { /*temPagamento(13) && (
              <TabPanel>
                <FormularioEnel valor={getValorPagamento(13)}></FormularioEnel>
              </TabPanel>
            )*/}
          </TabPanels>
        </Tabs>
      </Box>
      {user.loggedIn && fromCadastro === false ? <BottomNavBar /> : ''}
    </>
  );
};

export default Menu;
