import SadIcon from '../../../../../images/sadicon2.png';
import Contact from '../../../../../images/ClinicoIcon.svg';

import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import TelemedDeslogado from '../../../../../../src/images/Deslogado/telemedDeslogado.svg';
import {
  Box,
  Button,
  chakra,
  ButtonGroup,
  Flex,
  HStack,
  VStack,
  LinkBox,
  Heading,
  Text,
  Td,
  Badge,
  Table,
  Alert,
  AlertIcon,
  Tr,
  TableContainer,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  useToast,
  Img,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../../Loading';
import { AccountContext } from '../../../../AccountContext';

import PopupDependenteSemCpf from '../../Atendimento/PopupDependenteSemCpf';
import SelectField from 'components/SelectField';
import { Formik } from 'formik';
import '../../../css/lista-datas.css';
import DrawerCompraPlano from '../../DrawerCompraPlano';
import DrawerServico from '../../DrawerServico';
import _ from 'lodash';
import BottomNavBar from 'components/BottomNavBar';
import LoadingGenerico from './LoadingGenerico';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Agendamento() {
  const navigate = useNavigate();
  const { user, setPage, setUser, toastInadimplente } = useContext(AccountContext);
  const [dependentes, setDependentes] = useState([]);
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];
  const loading = useDisclosure();
  //dados do medico
  const { state } = useLocation();
  console.log(state);
  const {
    medico,
    startNum,
    st,
    defaultDateTime,
    procedimento,
    price,
    procedimentoId,
    procedimentoParticular,
    valorParticular,
    procedimentoCoparticipacao,
    valorCoparticipacao,
    paciente,
    idClientClinica,
  } = state;
  const newState = { ...state };
  const [updatedState, setUpdatedState] = useState(newState);

  // return;
  const arrName = medico.dados.nome.split(' ');
  const [pag, setPag] = useState(startNum);
  const [pac, setPac] = useState(st);
  const [escolha, setEscolha] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [bloqueadoIdade, setBloqueadoIdade] = useState(false);
  const [agendas, setAgendas] = useState([]);

  const disclosure = useDisclosure();
  const scheduleDrawer = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);

  const [reload, setReload] = useState(false);
  const toast = useToast();
  const [pickWarning, setPickWarning] = useState(false);
  let faixa_inicial = 0;
  let faixa_final = 0;

  let titulo = '';
  if (medico.dados.sexo === 'M') {
    titulo = 'Dr. ';
  } else if (medico.dados.sexo === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Agendamento');

  let assinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) assinante = true;

  const handlePick = async (agendaId, medico, agenda, filteredAgenda, filteredAgendasOrdemChegada, index) => {
    try {
      loading.onOpen();
      let pacienteId = idClientClinica;
      if (!idClientClinica) {
        const postsData = await fetch(`${ENDPOINT}/clients/post_client_clinicas`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cpf: user.username }),
        });

        const response = await postsData.json();
        console.log('response ', response);
        if (response.paciente_id === 0) {
          toast({
            title: 'Importante',
            description: 'Não foi possível realizar a operação. Entre em contato com o suporte',
            status: 'warning',
            duration: 4000,
            isClosable: true,
            position: 'top',
            colorScheme: 'red',
            containerStyle: {
              paddingTop: '50px',
            },
          });
          return;
        }
        pacienteId = response.paciente_id;
      }

      const url = ENDPOINT + '/schedule/checkAvailability/' + agendaId + '/' + pacienteId;
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json().then(({ available }) => {
        if (available === true) {
          document.getElementById('agenda' + index).scrollIntoView({ behavior: 'smooth' });
          document.querySelectorAll(`[class="chakra-badge css-1958td"]`).forEach((item) => {
            item.style.border = 'none';
          });
          document.getElementById('agenda' + index).scrollIntoView({ inline: 'center', behavior: 'smooth' });
          document.getElementById('agenda' + index).style.border = `1px solid ${assinante ? '#EB8B2A' : '#529C94'}`;
          setEscolha({
            medicoNome: medico.dados.nome,
            tipo: medico.dados.tipo,
            especialidade: medico.dados.especialidade,
            valor:
              user?.allowsTelemedicina?.paga_telemedicina === true
                ? valorCoparticipacao
                : (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
                  (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0') &&
                  user.allowsTelemedicina.paga_telemedicina === false
                ? updatedState.price
                : updatedState.valorParticular,
            agenda: filteredAgenda,
            paciente: dependentes[pac],
            clinica: medico.dados.empresa,
            logradouro: medico.dados.logradouro,
            numero: medico.dados.numero,
            bairro: medico.dados.bairro,
            municipio: medico.dados.municipio,
            medico: medico,
            procedimento: procedimento,
            procedimentoId:
              user?.allowsTelemedicina?.paga_telemedicina === true
                ? procedimentoCoparticipacao
                : (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
                  (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0') &&
                  user?.allowsTelemedicina?.paga_telemedicina === false
                ? procedimentoId
                : procedimentoParticular,
          });
          if (escolha) {
            setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
          }
          //lockAgenda(filteredAgenda.AVAILABILITY_LID);
          //socket.emit('unlockSchedule', '');
          //socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);

          dependentes[pac].cpf && setDisabled(true);
          loading.onClose();
        } else {
          loading.onClose();
          setPickWarning(true);
        }
      });
    } catch (error) {
      loading.onClose();
      console.log(error);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchPosts = async () => {
      const especialista = true;
      const url = `${ENDPOINT}/schedule/getAvailabilitySpecialist/${medico.dados.medico}/${procedimentoId}/${idClientClinica}/${assinante}/${
        user?.allowsTelemedicina?.paga_telemedicina ? user.allowsTelemedicina.paga_telemedicina : false
      }`;
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json();
      setAgendas(postsData);
      setPickWarning(false);
      setIsLoading(false);
    };
    const fetchDependentes = async () => {
      const url2 = ENDPOINT + '/client/dependents2';
      const titularId = {
        id_contrato: user.id_contrato,
        idade_inicial: 0,
        idade_final: 0,
      };
      const response = await fetch(url2, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(titularId),
      });
      const resp = await response.json();
      if (resp.data[0] !== null) {
        setDependentes(resp.data.filter((i) => i.cpf === paciente));
      } else {
        setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
      }
    };
    fetchDependentes();
    fetchPosts();
    toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
  }, [reload]);

  let uniqueDates = [];
  const useAgenda = agendas.map((agenda, index) => {
    if (!uniqueDates.includes(agenda.AVA_DATE)) {
      uniqueDates.push(agenda.AVA_DATE);
    }
  });

  function calcularIdade(dataNascimento) {
    const nascimento = new Date(dataNascimento);
    const hoje = new Date();

    let idade = hoje.getFullYear() - nascimento.getFullYear();
    const mes = hoje.getMonth() - nascimento.getMonth();
    if (mes < 0 || (mes === 0 && hoje.getDate() < nascimento.getDate())) {
      idade--;
    }
    return idade;
  }

  const faixa_etaria = agendas?.map((faixas, index) => {
    if (faixas.faixa_etaria !== 0 || faixas.faixa_etaria_final !== 0) {
      faixa_inicial = faixas.faixa_etaria;
      faixa_final = faixas.faixa_etaria_final;
    }
  });

  const idade_paciente = calcularIdade(state.nascimento);

  useEffect(() => {
    const agendaEscolhida = agendas.find((i) => uniqueDates[pag] === i.AVA_DATE);
    if (agendaEscolhida) {
      newState.price = agendaEscolhida.PRICE;
      newState.valorParticular = agendaEscolhida.valor_particular;
      setUpdatedState(newState);
    }
  }, [agendas]);

  function escolhaData(uniqueDate) {
    const agendaEscolhida = agendas.find((i) => uniqueDate === i.AVA_DATE);
    if (agendaEscolhida) {
      newState.price = agendaEscolhida.PRICE;
      newState.valorParticular = agendaEscolhida.valor_particular;
      setUpdatedState(newState);
    }
  }

  const useUniqueDates = uniqueDates.map((uniqueDate, index) => {
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
    return (
      <>
        <Flex>
          <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="5px" color="rgba(62, 100, 255, 1)" padding="0 13px">
            <Text fontWeight="bold" fontSize="xl">
              {dataIcon[0]}
            </Text>
            <Text fontWeight="bold" fontSize="sm" mt="-1">
              {dataIcon[1].toUpperCase()}
            </Text>
          </Box>
          <Text margin="5% 0" fontSize="xs" align="left">
            <chakra.p fontWeight="bold">{medico.dados.tipo ? medico.dados.tipo.toUpperCase() : ''}</chakra.p>
            {event.toLocaleString('pt-br', { weekday: 'long' })}
          </Text>
        </Flex>
      </>
    );
  });

  const filteredAgendasOrdemChegada = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE) && i.ordem_chegada === true);
  let horario_chegada = filteredAgendasOrdemChegada.filter(
    (item, index, self) => index === self.findIndex((i) => i.AVA_START_TIME === item.AVA_START_TIME)
  );

  const filteredAgendas = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE) && i.ordem_chegada === false);
  const mapDates = uniqueDates.map((uniqueDate, index) => {
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

    return (
      <>
        <Td
          id={`td${index}`}
          borderRadius="15px"
          p="0.5em"
          fontSize="xl"
          textAlign="center"
          class="margin-td"
          onClick={() => {
            document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
            document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.getElementById('date' + index).style.border = `1px solid ${assinante ? '#EB8B2A' : '#529C94'}`;
            setPag(index);
            setDisabled(false);
            escolhaData(uniqueDate);
          }}
        >
          <HStack
            id={`date${index}`}
            backgroundColor="#EEEEEE"
            borderRadius="15px"
            style={pag === index ? { border: `1px solid ${assinante ? '#EB8B2A' : '#529C94'}` } : {}}
          >
            <Text width="60%">
              <Flex ml="1em">
                <Box backgroundColor="#FFFFFF" borderRadius="5px" margin="1em" color="black" padding="0 1em">
                  <Text fontWeight="bold" fontSize="xl">
                    {dataIcon[0]}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" mt="-1">
                    {dataIcon[1].toUpperCase()}
                  </Text>
                </Box>
                <Text margin="11% 0" fontSize="xs" align="left">
                  <chakra.p fontWeight="bold">{medico.dados.tipo ? medico.dados.tipo.toUpperCase() : ''}</chakra.p>
                  {event.toLocaleString('pt-br', { weekday: 'long' })}
                </Text>
              </Flex>
            </Text>
          </HStack>
        </Td>
      </>
    );
  });

  let agenda = '';
  function filterAllAgendas(array, ordem) {
    if (ordem) {
      agenda = filteredAgendasOrdemChegada.filter((item, index, self) => index === self.findIndex((i) => i.AVA_START_TIME === item.AVA_START_TIME));
      // setPriceData(1);
    } else {
      agenda = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE) && i.ordem_chegada === false);
    }
    return agenda;
  }

  if (filteredAgendasOrdemChegada.length > 0) {
    filterAllAgendas(horario_chegada, true);
  } else {
    filterAllAgendas(filteredAgendas, false);
  }

  console.log('allowsTelemed', user?.allowsTelemedicina?.paga_telemedicina === true);
  console.log('valorCoparticipacao', valorCoparticipacao);
  console.log(
    'parcelas',
    (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
      (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0') &&
      user.allowsTelemedicina.paga_telemedicina === false
  );
  console.log('valorParticular', updatedState.valorParticular);
  console.log('price', updatedState.valorParticular);
  const useFilteredAgendas = agenda.map((filteredAgenda, index) => {
    if (!escolha) {
      var dateTime = `${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}`;
      if (dateTime === defaultDateTime) {
        setEscolha({
          medicoNome: medico.dados.nome,
          tipo: medico.dados.tipo,
          especialidade: medico.dados.especialidade,
          valor:
            user?.allowsTelemedicina?.paga_telemedicina === true
              ? valorCoparticipacao
              : (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
                (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0') &&
                user.allowsTelemedicina.paga_telemedicina === false
              ? updatedState.price
              : updatedState.valorParticular,
          agenda: filteredAgenda,
          paciente: dependentes[pac],
          clinica: medico.dados.empresa,
          logradouro: medico.dados.logradouro,
          numero: medico.dados.numero,
          bairro: medico.dados.bairro,
          municipio: medico.dados.municipio,
          medico: medico,
          procedimento: procedimento,
          procedimentoId:
            user?.allowsTelemedicina?.paga_telemedicina === true
              ? procedimentoCoparticipacao
              : (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
                (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0') &&
                user?.allowsTelemedicina?.paga_telemedicina === false
              ? procedimentoId
              : procedimentoParticular,
        });
        //lockAgenda(filteredAgenda.AVAILABILITY_LID);
        //socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);
        setDisabled(true);
      }
    }

    return (
      <>
        <Td>
          <Badge
            id={'agenda' + index}
            borderRadius="0.5em"
            margin="0 0.5em"
            padding="0.5em 1.4em"
            size="lg"
            fontSize={filteredAgendasOrdemChegada.length > 0 ? 'sm' : 'md'}
            style={`${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}` === defaultDateTime ? { border: '1px solid orange' } : {}}
            backgroundColor="#EEEEEE"
            color="black"
            onClick={() => {
              handlePick(filteredAgenda.AVAILABILITY_LID, medico, agenda, filteredAgenda, filteredAgendasOrdemChegada, index);
              /**/
            }}
          >
            {filteredAgendasOrdemChegada.length > 0 ? `A partir de ${filteredAgenda.AVA_START_TIME}` : filteredAgenda.AVA_START_TIME}
          </Badge>
        </Td>
      </>
    );
  });

  const confirmEstimate = (id) => {
    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/confirm`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(user.solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
        }
      });
    }
  };

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }
  const dataAtual = new Date();
  const [telefonePequeno] = useMediaQuery('(max-height: 667px)');
  return dependentes.length > 0 && agendas.length > 0 && isLoading === false ? (
    <>
      <LoadingGenerico disclosure={loading} />
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH={telefonePequeno ? '110vh' : '100vh'}
        spacing={0}
        background={assinante ? '#EB8B2A' : '#529C94'}
        color="#585858"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt="1"
          id=""
          as="card"
          width="95%"
          minHeight="75vh"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        >
          <Img ml="40%" mt="15%" src={assinante ? Contact : TelemedDeslogado} w="15%" />

          {user.loggedIn === true ? <PopupDependenteSemCpf disclosure={disclosure} datas={dependentes[pac]} /> : null}
          <Modal isOpen={pickWarning} isCentered>
            <ModalOverlay />
            <ModalContent height="250px" width="300px" border-radius="1em">
              <ModalHeader></ModalHeader>

              <ModalBody>
                <VStack>
                  <Heading size="md" textAlign="center" pl="5" pr="5" mt="15">
                    Opa! O horário escolhido não está mais disponível.
                  </Heading>
                  <Stack direction="row" ml="-4" pt="1em" spacing={5} align="center">
                    <Button
                      id="id_agd_continuar"
                      backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
                      color="#FFF"
                      variant="solid"
                      size="lg"
                      onClick={() => {
                        setReload(!reload);
                      }}
                    >
                      continuar
                    </Button>
                  </Stack>
                </VStack>
              </ModalBody>

              <VStack ml="4"></VStack>
            </ModalContent>
          </Modal>
          <DrawerCompraPlano disclosure={disclosure} />
          <DrawerServico disclosure={scheduleDrawer} />
          <Formik initialValues={{ empresa: 0, dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
            <VStack
              w={{
                base: '90%',
                md: '500px',
              }}
              m="auto"
              justify="center"
            >
              <chakra.h1 fontWeight="bold">Selecione dia e horário</chakra.h1>
              {user.loggedIn === true &&
              (user.id_contrato === 0 || !user.id_contrato) &&
              user.titular === true &&
              (!updatedState.valorParticular || updatedState.valorParticular === null) ? (
                <>
                  <Text align="center" fontSize="0.8em" pb="20%">
                    Sem horarios disponíveis para não assinantes.
                  </Text>
                </>
              ) : (
                <>
                  {filteredAgendasOrdemChegada?.length > 0 ? (
                    <Tabs variant="enclosed" w="100%">
                      <TabList w="100%">
                        {filteredAgendas?.length > 0 ? <Tab w="50%">Hora Marcada</Tab> : <></>}
                        <Tab w="50%">Ordem de Chegada</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <TableContainer minWidth="100%">
                            <Table variant="simple" size="lg" class="table-agendamento">
                              <Tr>{mapDates}</Tr>
                            </Table>
                          </TableContainer>
                          <TableContainer minWidth="100%">
                            <Table variant="simple">
                              <Tr>{useFilteredAgendas}</Tr>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel>
                          <TableContainer minWidth="100%">
                            <Table variant="simple" size="lg" class="table-agendamento">
                              <Tr>{mapDates}</Tr>
                            </Table>
                          </TableContainer>
                          <TableContainer minWidth="100%">
                            <Table variant="simple">
                              <Tr w="100%">
                                <Td w="100%">
                                  <TableContainer minWidth="100%">
                                    <Table variant="simple">
                                      <Tr>{useFilteredAgendas}</Tr>
                                    </Table>
                                  </TableContainer>
                                </Td>
                              </Tr>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  ) : (
                    <>
                      <TableContainer minWidth="100%">
                        <Table variant="simple" size="lg" class="table-agendamento">
                          <Tr>{mapDates}</Tr>
                        </Table>
                      </TableContainer>
                      <TableContainer minWidth="100%">
                        <Table variant="simple">
                          <Tr>{useFilteredAgendas}</Tr>
                        </Table>
                      </TableContainer>
                    </>
                  )}

                  {(faixa_inicial !== undefined && faixa_inicial !== null && idade_paciente < faixa_inicial) ||
                  (faixa_final !== undefined && faixa_final !== null && idade_paciente > faixa_final) ? (
                    <Alert status="warning" borderRadius="15px">
                      <AlertIcon />
                      Esse profissional atende apenas pacientes da seguinte faixa etária:{' '}
                      {faixa_final > 0 ? `entre ${faixa_inicial} e ${faixa_final} anos` : `a partir de ${faixa_inicial} anos`}
                    </Alert>
                  ) : (
                    <></>
                  )}

                  <ButtonGroup pt="1rem" pb="25%" width="100%">
                    <Button
                      width="100%"
                      background={assinante ? '#EB8B2A' : '#529C94'}
                      textColor="white"
                      onClick={async () => {
                        if (
                          (faixa_inicial !== undefined && faixa_inicial !== null && idade_paciente < faixa_inicial) ||
                          (faixa_final !== undefined && faixa_final !== null && idade_paciente > faixa_final)
                        ) {
                          toast({
                            title: 'Importante',
                            description: `Esse profissional atende apenas pacientes da seguinte faixa etária: ${
                              faixa_final > 0 ? `entre ${faixa_inicial} e ${faixa_final} anos` : `a partir de ${faixa_inicial} anos`
                            }`,
                            status: 'error',
                            duration: 4000,
                            isClosable: true,
                            position: 'bottom',
                            colorScheme: 'red',
                            containerStyle: {
                              paddingTop: '50px',
                            },
                          });
                          return;
                        }

                        if (
                          user.qntdeParcelas?.countParcelas &&
                          user.qntdeParcelas?.countParcelas > 0 &&
                          dataAtual >= data_parcela &&
                          !updatedState?.valorParticular
                        ) {
                          toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                          return;
                        }
                        if (user.loggedIn === true) {
                          if (!assinante && !updatedState.valorParticular) {
                            toast({
                              title: 'Importante',
                              description: 'Esta agenda não possui horários para não assinantes.',
                              status: 'warning',
                              duration: 4000,
                              isClosable: true,
                              position: 'bottom',
                              colorScheme: 'red',
                              containerStyle: {
                                paddingTop: '50px',
                              },
                            });
                            return;
                          }

                          if (state.orcamento_id) {
                            confirmEstimate(state.orcamento_id);
                          }

                          let states = {
                            ...escolha,
                            paciente: dependentes[pac],
                            agenda: escolha.agenda,
                            orcamento_id: state.orcamento_id ? state.orcamento_id : null,
                            idClientClinica: idClientClinica ? idClientClinica : dependentes[pac],
                          };
                          navigate('/medico-agora/especialista/conferencia', {
                            state: states,
                          });
                        } else {
                          if (escolha) {
                            setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
                          }

                          disclosure.onOpen();
                        }
                      }}
                      disabled={user.loggedIn === true ? !disabled : false}
                    >
                      Continuar
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </VStack>
          </Formik>
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  ) : agendas?.length <= 0 && isLoading === false ? (
    <>
      <>
        <VStack
          align="center"
          justify={{
            base: 'center',
            md: 'space-around',
            xl: 'space-between',
          }}
          direction={{ base: 'column-reverse' }}
          wrap="no-wrap"
          minH="100vh"
          spacing={0}
          background={assinante ? '#EB8B2A' : '#529C94'}
          color="#585858"
        >
          <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
          <Box
            mt="1"
            id=""
            as="card"
            width="95%"
            minHeight="75vh"
            maxHeight="75vh"
            borderRadius="18px"
            fontSize="xs"
            size="10"
            backgroundColor="white"
            maxInlineSize="80"
            textAlign="center"
          >
            <VStack align="center" mt="15%">
              <chakra.div style={{ backgroundColor: '#B6C1BF', borderRadius: '50%', width: '50%' }}>
                <Image src={SadIcon} h="12em" w="100%" />
              </chakra.div>
            </VStack>
            <Heading textAlign="center" color="#EB8B2A" mt="2em" fontSize={24}>
              Oops...
            </Heading>
            <Heading textAlign="center" fontWeight={200} color="#585858" mt="2em" fontSize={18}>
              Estamos sem horário disponíveis<br></br>para o esse médico. Tente<br></br>novamente mais tarde
            </Heading>
          </Box>
        </VStack>
      </>
    </>
  ) : (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background={assinante ? '#EB8B2A' : '#529C94'}
        color="#585858"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          id=""
          as="card"
          maxW="65vh"
          minHeight="75vh"
          maxHeight="75vh"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          textAlign="center"
        >
          <Loading mt="-5em" w="" />
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Agendamento;
