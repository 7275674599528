import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
  Td,
  ButtonGroup,
  Button,
  Text,
  useDisclosure,
  Checkbox,
  useToast,
  VStack,
  Spacer,
  Flex,
  Heading,
  Image,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import _ from 'lodash';
import Loading from 'components/Loading';
import PagamentoLocalizado from 'components/Pagamento/PagamentoLocalizado';
import Desconto from '../../../images/Desconto.svg';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ListaParcelas(props) {
  const navigate = useNavigate();
  const { user, page, setPage } = useContext(AccountContext);
  const [selecionados, setSelecionados] = useState([]);
  const [valor_selecionado, setValorSelecionado] = useState(0);
  const [valor_desconto, setValorDesconto] = useState(0);
  const parcelas = props.parcelas;
  const status = props?.status;
  const motivo = props?.motivo;

  setPage('Parcelas');
  const url = `${ENDPOINT}/client/parcelas`;
  const { id_contrato, descricao_plano, titular } = user;
  const [todosMarcados, setTodosMarcados] = useState(false);
  const valor_a_pagar = user && user.qntdeParcelas && user.qntdeParcelas.valorParcelas ? user.qntdeParcelas.valorParcelas : null;

  function containsObject(object, list) {
    return list.some((item) => item.parcela_id === object.parcela_id);
  }

  const checkboxValues = (object) => {
    const index = selecionados.findIndex((item) => item.parcela_id === object.parcela_id);
    if (index !== -1) {
      setSelecionados(selecionados.filter((item) => item.parcela_id !== object.parcela_id));
    } else {
      setSelecionados([...selecionados, object]);
    }
  };

  const toast = useToast({
    title: 'Aviso',
    description: 'Selecione no mínimo uma parcela',
    status: 'info',
    duration: 2000,
    position: 'bottom',
    colorScheme: 'red',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  const enviarPagamento = () => {
    console.log(status === 'cancelamento_contrato' ? props : '')
    if (valor_selecionado >= 1.0) {
      const data = {
        paciente_id: user.id_cliente_deovita,
        username: user.name,
        parcelsActive: true,
        paciente_contrato_id: user.titular ? user.id_contrato : user.id_contrato_dependent.paciente_contrato_id,
        valor: valor_selecionado >= 1.0 && valor_desconto >= 1.0 && status === 'cancelamento_contrato' && user.id_forma_pagamento !== 13 ? valor_desconto : valor_selecionado,
        valor_desconto: valor_desconto,
        status: status,
        expiration_pix_in_min: 5,
        navig_to: status === 'cancelamento_contrato' ? '/perfil/assinaturas/contrato-cancelado-sucesso' : '/perfil/dependentes/processar-pagamento',
        item: {
          paciente_id: user.id_cliente_deovita,
          descricao: 'Pagamento parcelas em atraso',
          paciente_contrato_parcelas_id: selecionados.map((item) => ({ parcela_id: item.parcela_id, valor: item.valor, valor_desconto: item.valor_desconto })),
          paciente_contrato_id: user.titular ? user.id_contrato : user.id_contrato_dependent.paciente_contrato_id,
          valor: valor_selecionado,
          valor_desconto: valor_desconto,
          status: status,
          whatsapp: user.phone1 || user.phone2,
          nome: user.name,
          valor_pendente:  user?.qntdeParcelas?.valorParcelas,
          motivo: status === 'cancelamento_contrato' ? props?.motivo : '',
          desconto: valor_selecionado >= 1.0 && valor_desconto >= 1.0 && status === 'cancelamento_contrato' && user.id_forma_pagamento !== 13 ? true : false,
        },
        orientacoes: '',
        service: 'settle_the_debt',
        confim: true,
        arrayPagamento: selecionados,
      };

      navigate('/pagamentos', { state: data });
    } else {
      toast();
    }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    if (todosMarcados) {
      const dadosParcelas = checkboxValues(
        parcelas.map((parcela) => ({
          parcela_id: parcela.paciente_contrato_parcelas_id,
          valor: parcela.valor,
          valor_desconto: parcela.valor * 0.5
        }))
      );
      setValorSelecionado(user?.qntdeParcelas?.valorParcelas);
      return;
    }

    if (!todosMarcados) {
      setValorSelecionado(0);
      return;
    }
  }, [todosMarcados]);

  useEffect(() => {
    let valorSomado = 0;
    selecionados.forEach((selecionado) => {
      const valorFloat = parseFloat(selecionado.valor);
      if (!isNaN(valorFloat)) {
        valorSomado += valorFloat;
      }
    });
    setValorSelecionado(valorSomado);

    const clienteSelecionouMetadeOuMais = selecionados?.length >= parcelas.length / 2;

    if (!clienteSelecionouMetadeOuMais) {
      setValorDesconto(0);
    } else {
      const valorComDesconto = valorSomado * 0.5;
      setValorDesconto(valorComDesconto);
    }
  }, [selecionados, parcelas]);

  const handleMasterCheckboxChange = () => {
    setTodosMarcados(!todosMarcados);
    if (!todosMarcados) {
      setSelecionados(
        parcelas.map((parcela) => ({
          parcela_id: parcela.paciente_contrato_parcelas_id,
          valor: parcela.valor,
          valor_desconto: parcela.valor * 0.5
        }))
      );
    } else {
      setSelecionados([]);
    }
  };
  const useParcelas = parcelas.map((parcela, index) => {
    const data = parcela.data;
    const formData = new Date(data ? data.replace(' ', 'T') : '');
    const mesPorExtenso = formData.toLocaleDateString('pt-BR', { month: 'long' });
    const vals = { parcela_id: parcela.paciente_contrato_parcelas_id, valor: parcela.valor };

    return (
      <Tr key={index}>
        <Td>
          {parcela.data === null || parcela.data === '' || parcela.data === undefined ? (
            <WarningTwoIcon color="orange" />
          ) : (
            <WarningTwoIcon color="white" />
          )}
          <Text fontSize="16px" color={'#585858'} ml={'1em'}>
            Assinatura Mensal <br />
            plano {String(descricao_plano).charAt(0).toUpperCase() + String(descricao_plano).slice(1).toLowerCase()} (
            {capitalizeFirstLetter(mesPorExtenso)})
          </Text>
        </Td>

        <Td>
          {parcela.valor === null || parcela.valor === '' || parcela.valor === undefined ? (
            <WarningTwoIcon color="orange" />
          ) : (
            <WarningTwoIcon color="white" />
          )}
          <Text fontSize="16px" color={'#585858'} ml={'1em'} fontWeight={'bold'}>
            R${' ' + parcela.valor}
          </Text>
        </Td>

        <Td>
          <Text fontSize="16px" color={'#585858'} ml={'1em'}>
            <Checkbox
              name="paciente_contrato_parcelas_id"
              borderRadius={'0.8em'}
              iconColor="green.100"
              colorScheme="green"
              value={vals}
              bg={'#E4E4E4'}
              onChange={(e) => checkboxValues({ parcela_id: parcela.paciente_contrato_parcelas_id, valor: parcela.valor })}
              isChecked={selecionados.some((item) => item.parcela_id === parcela.paciente_contrato_parcelas_id)}
            ></Checkbox>
          </Text>
        </Td>
      </Tr>
    );
  });

  return (
    <>
      <VStack m="5%" spacing={3} align="start">
        <Text fontWeight="bold" fontSize="16px" color={'#585858'} mt={'1em'}>
          Selecione quais os itens pendentes você irá pagar
        </Text>
        <TableContainer
          minWidth="100%"
          style={{ maxWidth: '100%', maxHeight: '300px', overflow: 'auto', border: '2px solid #E4E4E4', borderRadius: '1em' }}
        >
          <Text fontWeight="bold" fontSize="16px" color={'#585858'} mt={'1em'} ml={'1em'}>
            Pagamentos pendentes
          </Text>
          <Table>
            <Tbody>
              <Tr>
                <Td>
                  <Text fontSize="16px" color={'#585858'} ml={'1em'}>
                    Selecionar todos os itens
                  </Text>
                  <p></p>
                </Td>
                <Td>
                  <Text fontSize="16px" color={'#585858'} ml={'1em'}></Text>
                </Td>
                <Td>
                  {' '}
                  <Text fontSize="16px" color={'#585858'} ml={'1em'}>
                    <Checkbox
                      name="paciente_contrato_parcelas_id"
                      borderRadius={'0.8em'}
                      iconColor="green.100"
                      colorScheme="green"
                      bg={'#E4E4E4'}
                      onChange={handleMasterCheckboxChange}
                      isChecked={todosMarcados}
                    ></Checkbox>
                  </Text>
                </Td>
              </Tr>
            </Tbody>
            <Tbody>{parcelas && useParcelas}</Tbody>
          </Table>
        </TableContainer>
        <Text fontWeight="bold" fontSize="16px" color={'#585858'} mt={'1em'}>
          Total:
          {valor_selecionado >= 1.0 && valor_desconto >= 1.0 && status === 'cancelamento_contrato' && user.id_forma_pagamento !== 13 ? (
            <span style={{ color: 'orange' }}> R$ {valor_desconto.toFixed(2)} (50%)</span>
          ) : (
            'R$ ' + valor_selecionado.toFixed(2)
          )}
        </Text>
      </VStack>
      {status === 'cancelamento_contrato' && user.id_forma_pagamento !== 13 ? (
        <>
          <VStack w={'80%'} bg={'#EB8B2A'} p={'1em'} borderRadius={'0.8em'} alignItems="flex-start">
            <Image boxSize="20px" objectFit="cover" src={Desconto} />
            <Heading fontSize={'19px'} color={'white'} fontWeight={'bold'} textAlign="left">
              Tenha 50% de desconto!
            </Heading>
            <Text fontSize={'14px'} color={'white'} textAlign="left">
              Selecionando metade dos pagamentos pendentes, você adquire 50% de desconto no pagamento de suas parcelas pendentes!
            </Text>
          </VStack>
        </>
      ) : (
        <></>
      )}
      {valor_selecionado >= '1.00' ? (
        <>
          <ButtonGroup>
            <Button
              name="btn"
              backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
              variant="outline"
              onClick={() => {
                if (valor_selecionado >= '1.00') {
                  enviarPagamento();
                } else {
                  toast();
                }
              }}
              w={{
                base: '100%',
              }}
              color={'white'}
            >
              Realizar pagamento
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <></>
      )}
      <Spacer></Spacer> <Spacer></Spacer>
    </>
  );
}

export default ListaParcelas;
