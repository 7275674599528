import React, { useEffect, useState } from 'react';

import AgendamentoCalendarioimg from '../../../../../images/agendamentoCalendario.svg';
import AgendamentoCalendarioimgPng from '../../../../../images/agendamentoCalendario.png';
import AgendamentoImagem from '../../../../../images/client-agender 1.svg';

import Google from '../../../../../images/Google.svg';
import Apple from '../../../../../images/Apple.svg';
import { Button, Image, Spacer, Text, VStack, chakra, useMediaQuery, useToast } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

function AgendamentoCalendario2() {
  const navigate = useNavigate();
  const state = useLocation();
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  const [iphone, setIphone] = useState(true);
  const toast = useToast();
  function isIOS() {
    const userAgent = window.navigator.userAgent;
    return /iPhone|iPad|iPod/i.test(userAgent);
  }
  const eventData = state.state.eventData;
  const date = new Date(eventData.startDate);
  const day = date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const time = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

  return (
    <>
      <VStack w={'100%'} h={'100%'} alignItems={'center'} spacing={4}>
        <Text fontWeight="bold" fontSize="24px" color={'#EB8B2A'} textAlign={'center'} mt={'3em'}>
          Agendamento <br /> Realizado
        </Text>
        <Image src={AgendamentoImagem} mt={'6em'} />
        <VStack alignItems={'center'} spacing={2} p={'2'}>
          {miniLargeHeight ? (
            <>
              <Text fontSize="14px" color={'#EB8B2A'} textAlign={'center'}>
                Seu atendimento está agendado para o dia {day} às {time}. Lembre-se de conectar-se ao aplicativo com 15 minutos de antecedência
              </Text>
            </>
          ) : (
            <>
              <Text fontSize="14px" color={'#EB8B2A'} textAlign={'center'}>
                Seu atendimento está agendado para o <br /> dia {day} às {time}. Lembre-se de <br /> conectar-se ao aplicativo com 15 <br /> minutos
                de antecedência
              </Text>
            </>
          )}
        </VStack>

        <VStack bg={'#EB8B2A'} w={'80%'} pt={'1em'} borderRadius={'0.8em'}>
          <Text fontWeight="bold" fontSize="14px" color={'white'} textAlign={'center'}>
            Clique no botão abaixo para adicionar <br /> um lembrete à sua agenda.
          </Text>
          <VStack mt={'3em'}>
            <Button
              bg={'#FFFFFF'}
              color={'#000000'}
              w={miniLargeHeight ? '18em' : '20em'}
              fontSize="12px"
              borderRadius={'0.8em'}
              onClick={() => {
                let eventData = {
                  title: state.state.eventData.title,
                  startDate: state.state.eventData.startDate,
                  endDate: state.state.eventData.endDate,
                  location: state.state.eventData.location,
                  notes: state.state.eventData.notes,
                  agenda_exames_id: state.state.eventData.agenda_exames_id
                };
                window.ReactNativeWebView.postMessage(JSON.stringify({ calendarEvent: eventData }));
                toast({
                  title: 'Sucesso!',
                  description: 'Seu lembrete foi adicionado ao seu calendário com sucesso!',
                  status: 'success',
                  duration: 4000,
                  isClosable: true,
                  position: 'bottom',
                  colorScheme: 'green',
                  containerStyle: {
                    paddingTop: '50px',
                  },
                });
                navigate('/consultas');
              }}
            >
              <Text ml={'1em'} color={'#333333'}>
                Adicionar lembrete
              </Text>
            </Button>
          </VStack>
          <Text fontSize="12px" color={'#E7F7F6'} textAlign={'center'}>
            Você será redirecionado a agenda padrão do seu dispositivo para salvar seu lembrete.
          </Text>
          <Spacer />
        </VStack>
        <Button bg={'#EB8D00'} fontSize={'12px'} color={'white'} w={'80%'} mb={'2em'} borderRadius={'0.8em'} onClick={() => navigate('/consultas')}>
          Ir para o início
        </Button>
        <Spacer />
      </VStack>
    </>
  );
}

export default AgendamentoCalendario2;
