import { Checkbox, CheckboxGroup, VStack, Button, Heading, ButtonGroup, Box, Center } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import TextField from '../../../TextField';
import ProgressBar from './ProgressBar';
import Loading from '../../../Loading';
var cont = 0;

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;


function ListItems(info, state) {
  var url = '';
  var heads = '';
  var navig = '';
  var progressBarValue = 1;
  switch (info) {
    case 'comorb':
      url = `${ENDPOINT}/service/comorb`;
      heads = 'Você possuí alguma comorbidade?';
      navig = '/sintomaspa';
      progressBarValue = 2;
      break;
    case 'sintoma':
      url = `${ENDPOINT}/service/sintomas`;
      heads = 'O que você está sentindo?';
      navig = '/fimpa';
      progressBarValue = 3;
      break;
    default:
      console.log(`Sorry, we are out of ${info}.`);
  }

  const Submit = () => {
    navigate(navig, {
      state: [...state, vals],
    });
  };
  const navigate = useNavigate();
  const [vals, setVals] = useState([]);
  console.log(vals);
  const [outros, setOutros] = useState({ index: 0, checked: false, text: '' });
  const [buttonActive, setButtonActive] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleChange(event) {
    var id = event.target.name;
    var nome = event.target.value;
    if (event.target.checked) {
      if (nome === 'Outros') {
        setOutros({ index: vals.length, checked: true, text: '' });
      }
      cont = cont + 1;
      setVals((prevState) => {
        return [...prevState, { id: id, nome: nome }];
      });
    } else {
      if (nome === 'Outros') {
        setOutros({ index: vals.length, checked: false, text: '' });
      }
      cont = cont - 1;
      setVals((prevState) => {
        let index = prevState.indexOf({ id: id, nome: nome });
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
    if (cont > 0) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  }
  const [item, setItem] = useState([]);
  useEffect(() => {
    async function carregarItems() {
      setLoading(true);
      const resposta = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: '',
      });
      resposta.json().then((res) => {
        if (res.sucess) {
          setItem(res.lista);
          setTimeout(() => {
            setLoading(false);
          }, 400);
        }
      });
    }
    carregarItems();
  }, []);

  const LoadingIcon = () => {
    return (
      <>
        <Center>
          <Box w={{ base: '20%', md: '170px' }} alignItems="center" h="20" marginBottom="20" ml="10">
            <Loading />
          </Box>
        </Center>
      </>
    );
  };

  return (
    <>
      <ChildHeader />
      <ProgressBar value={progressBarValue} />
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">{heads}</Heading>
          {loading ? (
            <LoadingIcon />
          ) : (
            <CheckboxGroup>
              {item.map((item) => (
                <Checkbox name={item.id} value={item.nome} onChange={handleChange}>
                  {item.nome}
                </Checkbox>
              ))}
            </CheckboxGroup>
          )}
          {outros.checked ? (
            <TextField
              name="outros"
              placeholder="Digite outros"
              value={outros.text}
              onChange={(e) => {
                const { value } = e.target;
                if (value.length <= 35) {
                  setOutros((prevState) => {
                    return { ...prevState, text: value };
                  });
                  setVals((prevState) => {
                    let prevOutros = prevState[outros.index];
                    prevState[outros.index] = {
                      id: prevOutros.id,
                      nome: value,
                    };
                    return [...prevState];
                  });
                }
              }}
            />
          ) : (
            ''
          )}
          <ButtonGroup w="100%" paddingBottom="25%">
            <Button colorScheme="teal" w="100%" onClick={Submit} isDisabled={!buttonActive}>
              Continuar
            </Button>
          </ButtonGroup>
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default ListItems;
