/**
 * Este hook contém funções que exibem notificações de toast pré-definidas
 * que podem ser utilizadas em qualquer lugar da aplicação
 * mantendo a consistência e o padrão visual.
 */

import { useToast } from "@chakra-ui/react";

export default function usePredefinedToastNotify(){
  const toast = useToast();

  const warning = (title = '', descricao='') => toast({
    title: title,
    description: descricao,
    status: 'warning',
    duration: 4000,
    isClosable: true,
    position: 'bottom',
    colorScheme: 'red',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  const error = (title = '', descricao='') => toast({
    title: title,
    description: descricao,
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'bottom',
    colorScheme: 'red',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  const success = (title = '', descricao='') => toast({
    title: title,
    description: descricao,
    status: 'success',
    duration: 4000,
    isClosable: true,
    position: 'bottom',
    colorScheme: 'green',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  return {
    warning,
    error,
    success,
  }
}