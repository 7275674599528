import { VStack, Heading, Text, Button, Wrap } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import TextField from '../../TextField';
import validaCPF from '../../Utils/Functions/validaCPF';
import NoUserHeader from '../../AreaLivre/NoUserHeader';
import { useContext } from 'react';
import { AccountContext } from '../../AccountContext';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const CheckCpf = () => {
  const { setPage } = useContext(AccountContext);
  setPage('Recuperação');
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={{ cpf: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          cpf: Yup.string()
            .required('Cpf Obrigatório!')
            .trim()
            /*.matches(
              "([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})",
              "Cpf inválido"
            )*/
            .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf)),
        })}
        onSubmit={(values, actions) => {
          const vals = { ...values };
          let cpf = onlyNumbers(vals.cpf);
          fetch(`${ENDPOINT}/clients/bycpf/${cpf}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .catch((err) => {
              return err;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                setError('Não foi possível completar a consulta do seu cpf nesse momento');
                return;
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              if (!data.success) {
                setError(
                  'Não encontramos o seu CPF na nossa base de clientes. Crie uma nova conta ou se já é cliente entre em contato com o suporte através do número 0800 280 2133 ou clique aqui para entrar em contato via whatsapp.'
                );
              } else {
                const regexPhone = /^\(?[1-9]{2}\)? ?(?:[1-9])([0-9]{3}|[0-9]{4})\-?[0-9]{4}$/;
                if (data.data[0].celular && data.data[0].celular.match(regexPhone)) {
                  navigate('/recuperacao/codigo', {
                    state: {
                      name: data.data[0].nome,
                      phone: data.data[0].celular,
                      cpf: data.data[0].cpf,
                      paciente_id: data.data[0].paciente_id,
                    },
                  });
                } else {
                  navigate('/recuperacao/security-questions', {
                    state: {
                      name: data.data[0].nome,
                      paciente_id: data.data[0].paciente_id,
                      cpf: data.data[0].cpf,
                    },
                  });
                }
              }
            });
        }}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" marginBottom="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">Vamos lá! Primeiro, insira seu número de CPF</Heading>
          <TextField name="cpf" type="tel" placeholder="Digite seu CPF" mask="999.999.999-99" maskChar={null} />
          <Text as="p" fontSize="small">
            Precisamos do seu número de CPF para identificação no sistema.
          </Text>
          <Text as="p" color="red.500" onClick={() => (window.open('https://wa.me/5508002802133'))}>
            {error}
            <Wrap pl="5px" color="green.500">
              {error2}
            </Wrap>
          </Text>
          <Button colorScheme="teal" w="100%" type="submit">
            Entrar
          </Button>
        </VStack>
      </Formik>
    </>
  );
};

export default CheckCpf;
