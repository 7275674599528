import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, Spinner, Center, ModalBody, Heading, Text, Image, HStack, VStack } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import Logo from '../../../images/spin.svg';
import '../../../components/loading.css';
function LoadingGenerico() {
  const { user, toastInadimplente, setUser, updateData, loadedMedicina } = useContext(AccountContext);
  return (
    <>
      <Modal isOpen={loadedMedicina} onClose={loadedMedicina} isCentered>
        <ModalOverlay />
        <ModalContent height="180px" width="280px" borderRadius="1em">
          <ModalBody>
            <VStack w="100%" alignItems="center">
              <img src={Logo} style={{ marginTop: '2em', width: '36px' }} className="rotate" alt="Loading..." />
            </VStack>
            <Text ml="3" mt="15" color={'#585858'} fontSize={'18px'}>
              Estamos carregando as informações, aguarde <b style={{ color: 'orange' }}>...</b>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default LoadingGenerico;
