import { React, useContext, useEffect, useState } from 'react';
import {
  chakra,
  Button,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Box,
  Image,
  Grid,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import socketIOClient from 'socket.io-client';
import { AccountContext } from 'components/AccountContext';
import { ReactComponent as CheckCircle } from '../../images/check-circle.svg';
import WarningIcon from '../../images/WarningBranco.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
const REACT_APP_DEOVITA_RECIPIENT_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_DEOVITA_RECIPIENT_ID_PROD
    : process.env.REACT_APP_DEOVITA_RECIPIENT_ID_TEST;

const BoletoGenerico = (selecionado) => {
  const disclosureBoleto = useDisclosure();
  const valor_selecionado = selecionado.selecionado;
  const mdvPercent = selecionado.mdvPercent ?? 0;
  const mdvGerente = selecionado.mdvGerente ?? 0;
  const mdvDiretor = selecionado.mdvDiretor ?? 0;
  const [lg] = useMediaQuery('(min-width: 425px)');

  const { state } = useLocation();
  const { user } = useContext(AccountContext);
  console.log(state);
  const { expiration_pix_in_min, navig_to, split, voucher } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;
  const valor = fromCadastro ? valor_selecionado : state.valor;
  const product_name = fromCadastro ? 'Contratação DeoVita' : state.item.product_name;
  const navigate = useNavigate();
  const [boleto, setBoleto] = useState({ sucess: '', boleto_url: '', boleto_boleto_barcode: '', id: 0, paid: false });
  const [copy, setCopy] = useState(false);
  const [error, setError] = useState(null);
  const [socket] = useState(socketIOClient(ENDPOINT));
  const fromCadastroData = {
    client_id: state.client_id,
    nome: state.name,
    fone: user.phone1 ? user.phone1 : state.phone,
    adicionais: state.adicionais,
    plano: state.id_plano,
  };

  useEffect(() => {
    socket.on('retorno_pix', (response) => {
      if (response === true) {
        //setPix({ ...pix, paid: true });
        console.log('pix pago');
      }
    });
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_cliente: user.id_cliente_deovita ? user.id_cliente_deovita : state.client_id,
        nome_cliente: user.name ? user.name : state.name,
        document: user.username ? user.username : state.cpf,
        email: user.email ? user.email : 'novoassinante@deovita.com.br',
        phone: user && user.loggedIn === true ? (user.phone1 ? onlyNumbers(user.phone1) : onlyNumbers(user.phone2)) : state.phone,
        amount: fromCadastro === true ? valor_selecionado * 100 : valor * 100,
        paid: false,
        frequencia: state.frequencia,
        split_rules: voucher
          ? voucher.recipient_id_gerente
            ? [
                {
                  amount: mdvGerente,
                  recipient_id: voucher.recipient_id_gerente,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: mdvDiretor,
                  recipient_id: voucher.recipient_id_diretor,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: mdvPercent,
                  recipient_id: voucher.recipient_id_pagarme,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: 100 - mdvPercent - mdvDiretor - mdvGerente,
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: true,
                    charge_remainder_fee: true,
                    liable: true,
                  },
                },
              ]
            : voucher.recipient_id_diretor
            ? [
                {
                  amount: mdvDiretor,
                  recipient_id: voucher.recipient_id_diretor,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: mdvPercent,
                  recipient_id: voucher.recipient_id_pagarme,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: 100 - mdvPercent - mdvDiretor,
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: true,
                    charge_remainder_fee: true,
                    liable: true,
                  },
                },
              ]
            : [
                {
                  amount: mdvPercent,
                  recipient_id: voucher.recipient_id_pagarme,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: false,
                    charge_remainder_fee: false,
                    liable: false,
                  },
                },
                {
                  amount: 100 - mdvPercent,
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                  type: 'percentage',
                  options: {
                    charge_processing_fee: true,
                    charge_remainder_fee: true,
                    liable: true,
                  },
                },
              ]
          : split,

        voucher: voucher,
        item: fromCadastro === true ? fromCadastroData : state.item,
      }),
    };

    const erroMsg = '"Não foi possível gerar seu código pix no momento"';
    fetch(`${ENDPOINT}/payment/${state.service}/boleto`, requestOptions)
      .catch((err) => {
        setError(erroMsg);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError(erroMsg);
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.sucess === false && data.message === 'Já existe um contrato para esse paciente') {
          navigate('/alerts/sucesso/cadastro_contrato/');
        }
        if (data.sucess) {
          setError(null);
          setBoleto({ ...data, paid: false });
          console.log(boleto);
        } else {
          setError(erroMsg);
        }
      });
  }, []);

  function renderBoletoPaid() {
    return (
      <Modal isOpen={true} isCentered>
        <ModalOverlay />
        <ModalContent p={8} bgColor={'#F3F3F3'} borderRadius={10} color={'#585858'} m={4}>
          <CheckCircle width={25} height={25}></CheckCircle>
          <Text mt={4} fontWeight={'bold'} fontSize={20}>
            Seu <span style={{ color: '#529C94' }}>pagamento</span> foi realizado <span style={{ color: '#529C94' }}>com sucesso!</span>
          </Text>
          <Text mt={4}>Ficamos felizes por escolher o DeoVita! Você será redirecionado para a Home do app.</Text>

          <Button isFullWidth colorScheme="teal" onClick={() => navigate('/')} mt={6}>
            Continuar
          </Button>
        </ModalContent>
      </Modal>
    );
  }

  function renderBoleto() {
    return (
      <>
        <Box bgColor={'#E4E4E4'} borderRadius={10} p={4} color={'#585858'}>
          <Text fontSize={'xl'} textAlign={'center'} fontWeight={'bold'} mb={4}>
            Boleto bancário
          </Text>
          <Text>Código de Barras:</Text>
          <Box bgColor={' #EDEDED'} textAlign={'center'} py={4} mt={2} borderRadius={10} mb={4}>
            <Text>{boleto.boleto_barcode ? boleto.boleto_barcode : 'carregando...'}</Text>
          </Box>

          <Text color={'red.700'} textAlign={'center'} mb={4}>
            {error}
          </Text>

          <Grid gridTemplateColumns={lg ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'} gap={4}>
            <GridItem>
              <CopyToClipboard text={boleto.boleto_barcode} onCopy={() => setCopy(true)}>
                <Button isFullWidth name="copy" colorScheme="teal" disabled={boleto.boleto_barcode ? false : true}>
                  {copy ? 'Copiado!' : 'Copiar código'}
                </Button>
              </CopyToClipboard>
            </GridItem>
            <GridItem>
              {boleto.boleto_url && (
                <Button
                  isFullWidth
                  colorScheme="teal"
                  variant="outline"
                  onClick={() => {
                    window?.ReactNativeWebView?.postMessage(
                      JSON.stringify({
                        service: true,
                        openInBrowser: true,
                        url: `${boleto.boleto_url}`,
                      })
                    );
                  }}
                >
                  Baixar Boleto
                </Button>
              )}
            </GridItem>
          </Grid>

          <Box display={'flex'} mt={4} p={4} bgColor={'#CB0000'} borderRadius={10} color={'white'} gap={4}>
            <Image src={WarningIcon}></Image>
            <Text fontWeight={'bold'} fontSize={12}>
              Seu pagamento será confirmado em até 3 dias úteis
            </Text>
          </Box>
        </Box>
        <Box w={'100%'} border={'2px solid #E4E4E4'} borderRadius={20} p={4} color={'#585858'} my={4}>
          <Text fontSize={'lg'} fontWeight={'bold'} mb={2}>
            Resumo da compra:
          </Text>
          <HStack justifyContent={'space-between'}>
            <Box>
              <Text color={'#FF8000'} fontWeight={'bold'}>
                Plano {state.nome_plano}
              </Text>
              <Text>Período: {state.frequenciaText} </Text>
            </Box>
            <Text fontWeight={'bold'}>{Number(valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
          </HStack>
        </Box>
      </>
    );
  }

  const ModalBoleto = (props) => {
    const disclosure = props.disclosure;
    const url = props.url;
    console.log(disclosure);
    return (
      <Modal size="full" isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton
              marginTop="4em"
              onClick={() => {
                disclosure.onClose();
              }}
            />
          </ModalHeader>

          <ModalBody>
            <chakra.iframe height="100vh" width="90%" scrolling="auto" frameborder="1" src={url} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" variant="outline" mr={3} onClick={disclosure.onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  return (
    <>
      <ModalBoleto disclosure={disclosureBoleto} url={boleto.boleto_url ? boleto.boleto_url : ''} />
      <Formik validateOnChange={false} validateOnBlur={false}>
        <Form>
          {boleto.paid ? renderBoletoPaid() : renderBoleto()}
          <Button
            colorScheme="teal"
            w="100%"
            isDisabled={boleto.boleto_barcode !== '' ? false : true}
            onClick={() => {
              navigate(navig_to, {
                state: {
                  ...state,
                  valor: valor,
                  transaction_id: boleto.id,
                  forma_pgto: 'boleto',
                  boleto_url: boleto.boleto_url,
                },
              });
            }}
          >
            Continuar
          </Button>
        </Form>
      </Formik>
    </>
  );
};

export default BoletoGenerico;
