import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Menu,
  Center,
  MenuButton,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  VStack,
  Checkbox,
  CheckboxGroup,
  useDisclosure,
  chakra,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useField } from 'formik';

const SelectFieldMultiplo = ({ label, ...props }) => {
  const { options, placeholder, onChange } = props;
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const colorErro = meta.error ? { colorScheme: 'red' } : {};
  let textButton;
  if (Array.isArray(value) && value.length > 0) {
    textButton = value
      .map((val) => options[val])
      .join(', ')
      .substring(0, 25);
    if (textButton.length === 25) {
      textButton += '...';
    }
  } else if (placeholder) {
    textButton = placeholder;
  } else {
    textButton = 'Selecionar';
  }

  const handleCheckboxChange = (index) => {
    const isNenhumChecked = value.includes(options.indexOf('Nenhum'));

    if (options[index] === 'Nenhum') {
      if (isNenhumChecked) {
        setValue([]);
      } else {
        setValue([index]);
      }
    } else {
      if (value.includes(index)) {
        setValue(value.filter((i) => i !== index));
      } else {
        if (!isNenhumChecked) {
          setValue([...value, index]);
        }
      }
    }
  };

  return (
    <>
      <FormControl isInvalid={meta.error}>
        <FormLabel>{label}</FormLabel>
        <Menu>
          <Center>
            <MenuButton as={Button} onClick={onOpen} rightIcon={<ChevronDownIcon />} {...field} {...props} {...colorErro}>
              {textButton}
            </MenuButton>
          </Center>
        </Menu>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
      <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} mb="-4" width="90" maxH="50vh">
          <DrawerHeader mt="5">Selecione</DrawerHeader>
          <DrawerBody>
            <CheckboxGroup value={value}>
              <VStack align="start" w="100" spacing={1}>
                {options.map((option, index) => (
                  <Checkbox key={index} value={index} isChecked={value.includes(index)} onChange={() => handleCheckboxChange(index)} padding="1em">
                    <chakra.p fontWeight="bold" fontSize="sm">
                      {option}
                    </chakra.p>
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SelectFieldMultiplo;
