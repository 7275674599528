import { Box, Button, ButtonGroup, Image, Text, VStack, useMediaQuery } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useState } from 'react';
import EmojiCoracoes from '../../../images/EmojiCoracoes.svg';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;


function ContinuaContrato() {
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const [isInRange] = useMediaQuery('(min-height: 840px) and (max-height: 1000px)');
  const [mdv_link, setMdvLinkl] = useState('https://deovita.com.br/clube-de-vantagens/');
  async function fetchMdv() {
    const url2 = ENDPOINT + '/clients/getAppConfigTelemedicina';
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({}),
    });
    const resp = await response.json();
    console.log(resp);
    setMdvLinkl(resp);
    return resp;
  }
  
  return (
    <>
      <ChildHeader />
      <VStack w={'100%'} mt={'1.5em'} >
        <Text color={'#EB8B2A'} fontSize={'18px'} fontWeight={'bold'} mb={'-0.5em'} ml={'-3em'}>
          Que bom que você continuou
          <br />
          com a gente!
        </Text>
        <VStack w={'100%'}>
          <Image w="60px" h={'60px'} src={EmojiCoracoes} ml={'-16em'} mt={'-0.5em'} />
        </VStack>
        <Box minW="210px" maxW="390px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
          <Text color={'#585858'} fontSize={'16px'} mt={'-1em'} ml={isInRange ? '1em' : '0.5em'}>
            O DeoVita existe para levar saúde de qualidade por um preço acessível para você e sua família. 😍
          </Text>
        </Box>
        <VStack w="100%" align="left" transform="translateX(-5%)" p={4} bg="white" zIndex="999" position="fixed" bottom="0" left="0" onClick={() =>
              fetchMdv().then(async (resposta) => {
                console.log(resposta);
                window?.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    service: true,
                    openInBrowser: true,
                    url: mdv_link,
                  })
                );
              })
            }>
          <ButtonGroup sx={{ width: '85%' }} ml={'12%'} mb="1em" mt={'1em'}>
            <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
              Indique e ganhe até R$ 20,00 💵
            </Button>
          </ButtonGroup>
        </VStack>
      </VStack>
    </>
  );
}

export default ContinuaContrato;
