import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
  Td,
  ButtonGroup,
  Button,
  Text,
  useDisclosure,
  Checkbox,
  useToast,
  VStack,
  Spacer,
  Flex,
  Heading,
  Image,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import _ from 'lodash';
import Loading from 'components/Loading';
import PagamentoLocalizado from 'components/Pagamento/PagamentoLocalizado';
import Desconto from '../../../images/Desconto.svg';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function TaxaCancelamentoValor() {
  const navigate = useNavigate();
  const { user, page, setPage } = useContext(AccountContext);
  const [selecionados, setSelecionados] = useState([]);
  const [valor_selecionado, setValorSelecionado] = useState(0);
  const [valor_desconto, setValorDesconto] = useState(0);
  const { id_contrato, descricao_plano, titular } = user;
  const [todosMarcados, setTodosMarcados] = useState(false);
  const state = useLocation();
  const dados = state?.state;

  const checkboxValues = (object) => {
    const index = selecionados.findIndex((item) => item.parcela_id === object.parcela_id);
    if (index !== -1) {
      setSelecionados(selecionados.filter((item) => item.parcela_id !== object.parcela_id));
    } else {
      setSelecionados([...selecionados, object]);
    }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const dataAtual = new Date();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const enviarPagamento = () => {
    const data = {
      username: user.name,
      parcelsActive: true,
      paciente_contrato_id: user.id_contrato,
      valor: dados.valor_multa,
      valor_desconto: 0,
      status: 'cancelamento_contrato',
      expiration_pix_in_min: 5,
      navig_to: '/perfil/assinaturas/contrato-cancelado-sucesso',
      item: {
        descricao: 'Cancelamento contrato 12 meses antecipados',
        paciente_contrato_parcelas_id: dados.dados.parcelas.map((item) => ({
          parcela_id: item.paciente_contrato_parcelas_id,
          valor: item.valor_parcela,
        })),
        paciente_contrato_id: user.id_contrato,
        valor: valor_selecionado,
        valor_desconto: 0,
        status: 'cancelamento_contrato',
        cancelamento: dados.motivo,
        whatsapp: user.phone1 || user.phone2,
        nome: user.name,
        valor_pendente: user?.qntdeParcelas?.valorParcelas,
        motivo: dados.motivo_cancelamento,
        desconto: false,
      },
      orientacoes: '',
      service: 'settle_the_debt',
      confim: true,
      arrayPagamento: selecionados,
    };

    navigate('/pagamentos', { state: data });
  };

  const toast = useToast({
    title: 'Aviso',
    description: 'Selecione a multa',
    status: 'info',
    duration: 2000,
    position: 'bottom',
    colorScheme: 'red',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  return (
    <>
      <ChildHeader />
      <VStack m="5%" spacing={3} align="start">
        <Text fontWeight="bold" fontSize="16px" color={'#585858'} mt={'1em'}>
          Selecione quais os itens pendentes você irá pagar
        </Text>
        <TableContainer
          minWidth="100%"
          style={{ maxWidth: '100%', maxHeight: '300px', overflow: 'auto', border: '2px solid #E4E4E4', borderRadius: '1em' }}
        >
          <Text fontWeight="bold" fontSize="16px" color={'#585858'} mt={'1em'} ml={'1em'}>
            Pagamentos pendentes
          </Text>
          <Table>
            <Tbody>
              <Tr>
                <Td>
                  <Text fontSize="16px" color={'#585858'} ml={'1em'}>
                    Multa contratual {String(descricao_plano).charAt(0).toUpperCase() + String(descricao_plano).slice(1).toLowerCase()}
                  </Text>
                </Td>

                <Td>
                  <Text fontSize="16px" color={'#585858'} ml={'1em'} fontWeight={'bold'}>
                    R${' ' + dados.valor_multa.toFixed(2)}
                  </Text>
                </Td>

                <Td>
                  <Text fontSize="16px" color={'#585858'} ml={'1em'}>
                    <Checkbox
                      name="paciente_contrato_parcelas_id"
                      borderRadius={'0.8em'}
                      iconColor="green.100"
                      colorScheme="green"
                      bg={'#E4E4E4'}
                      isChecked={isChecked}
                      onChange={handleCheckboxChange}
                    ></Checkbox>
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
      {isChecked ? <Flex justifyContent="center" mt={4}></Flex> : <></>}
      {isChecked ? (
        <Flex justifyContent="center" mt={4}>
          <ButtonGroup>
            <Button
              name="btn"
              backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
              variant="outline"
              onClick={() => {
                if (isChecked) {
                  enviarPagamento();
                } else {
                  toast();
                }
              }}
              w={{
                base: '100%',
              }}
              color={'white'}
            >
              Realizar pagamento
            </Button>
          </ButtonGroup>
        </Flex>
      ) : null}
    </>
  );
}

export default TaxaCancelamentoValor;
