import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  DrawerOverlay,
  Button,
  Heading,
  Flex,
  Text,
  HStack,
  Radio,
  TableContainer,
  Table,
  Tr,
  LinkBox,
  Td,
  Badge,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectField from 'components/SelectField';
import { AccountContext } from 'components/AccountContext';
import { Formik } from 'formik';
import Loading from 'components/Loading';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function DrawerHorario(props) {
  const [pag, setPag] = useState(0);
  const [pac, setPac] = useState(0);
  const [horario, setHorario] = useState();
  const [escolha, setEscolha] = useState(false);
  const [tarde, setTarde] = useState(true);
  const [manha, setManha] = useState(true);
  const [dataseleciada, setData] = useState(0);
  const [dependentes, setDependentes] = useState([]);
  const { user, setUser } = useContext(AccountContext);
  const { state } = useLocation();
  const { id_contrato, titular } = user;
  const disclosure = props.disclosure;
  const itemList = props.empresa;
  const valor = props.valor;
  const economia = props.economia;
  const defaultDateTime = null;
  const isByOrder = true;
  const navigate = useNavigate();
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];

  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
      ? '#EB8B2A'
      : titular === true && id_contrato > 0
      ? '#EB8B2A'
      : '#529C94';

  function isSameDate(date1, date2) {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  function generateTimestampsForNext30Days(
    horario_seg_sex_inicio = '07:30',
    horario_seg_sex_fim = '18:00',
    horario_sab_inicio = '08:00',
    horario_sab_fim = '18:00'
  ) {
    const startDate = new Date(Date.now());
    const endDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);

    // Feriados mockados temporariamente. Devido ao UTC, tive que por 1 dia na frente.
    const currentYear = new Date().getFullYear();
    const feriadosMock = [
      new Date(`${currentYear}-01-02`), // Ano Novo (domingo) – Feriado nacional
      new Date(`${currentYear}-02-21`), // Carnaval (segunda-feira) – Ponto facultativo
      new Date(`${currentYear}-02-22`), // Carnaval (terça-feira) – Ponto facultativo
      new Date(`${currentYear}-02-23`), // Quarta-feira de Cinzas (quarta-feira) – Ponto facultativo até as 14h
      new Date(`${currentYear}-03-09`), // Dia da Mulher (quarta) – Data comemorativa
      new Date(`${currentYear}-04-08`), // Sexta-feira Santa (sexta-feira) – Feriado nacional
      new Date(`${currentYear}-04-10`), // Páscoa (domingo) – Data comemorativa
      new Date(`${currentYear}-04-22`), // Tiradentes (sexta-feira) – Feriado nacional
      new Date(`${currentYear}-05-02`), // Dia do Trabalho (segunda-feira) – Feriado nacional
      new Date(`${currentYear}-05-15`), // Dia das mães (domingo) – Data comemorativa
      new Date(`${currentYear}-06-09`), // Corpus Christi (quinta-feira) – Ponto facultativo
      new Date(`${currentYear}-06-13`), // Dia dos Namorados (segunda-feira) – Data comemorativa
      new Date(`${currentYear}-06-25`), // Dia de São João (sábado) – Data comemorativa
      new Date(`${currentYear}-07-27`), // Dia dos Avós (quarta-feira) – Data comemorativa
      new Date(`${currentYear}-08-14`), // Dia dos Pais (domingo) – Data comemorativa
      new Date(`${currentYear}-09-08`), // Independência do Brasil (quinta-feira)
      new Date(`${currentYear}-10-13`), // Nossa Sra. Aparecida (quinta-feira) – Feriado nacional
      new Date(`${currentYear}-10-29`), // Dia do Servidor Público (sábado) – Ponto facultativo
      new Date(`${currentYear}-11-03`), // Finados (quinta-feira) – Feriado nacional
      new Date(`${currentYear}-11-16`), // Proclamação da República (quarta-feira) – Feriado nacional
      new Date(`${currentYear}-11-21`), // Consciência Negra (segunda-feira) – Data comemorativa
      new Date(`${currentYear}-12-25`), // Véspera de Natal (domingo) – Ponto facultativo após 14h
      new Date(`${currentYear}-12-26`), // Natal (segunda-feira) – Feriado nacional
      new Date(`${currentYear + 1}-01-01`), // Véspera do Ano Novo 2023 (domingo) – Ponto facultativo após 14h
    ];

    const days = _.range(0, 31)
      .map((index) => {
        const day = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
        if (day < startDate || day > endDate || day.getDay() === 0 || feriadosMock.some((feriado) => isSameDate(feriado, day))) {
          return null;
        }
        return day;
      })
      .filter((day) => day !== null);

    const timestamps = _.map(days, (day) => {
      const isCurrentDay = _.isEqual(day.toDateString(), new Date().toDateString());
      const dayTimestamps = _.range(0, 24 * 2)
        .map((index) => {
          const hour = _.floor(index / 2);
          const minute = (index % 2) * 30;
          const timestamp = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

          if (isCurrentDay) {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();
            if (hour < currentHour || (hour === currentHour && minute < currentMinute)) {
              return null;
            }
            if (hour === currentHour && minute < currentMinute) {
              return null;
            }
            if (hour === currentHour && minute === currentMinute && timestamp < horario_seg_sex_inicio) {
              return null;
            }
          } else if (day.getDay() === 6) {
            if (timestamp < horario_sab_inicio || timestamp >= horario_sab_fim) {
              return null;
            }
          } else {
            if (timestamp < horario_seg_sex_inicio || timestamp >= horario_seg_sex_fim) {
              return null;
            }
          }
          return timestamp;
        })
        .filter((timestamp) => timestamp !== null);
      if (_.isEmpty(dayTimestamps)) {
        return null;
      }
      // console.log("day", day)
      return { date: day, timestamps: dayTimestamps };
    }).filter((timestamp) => timestamp !== null);
    return timestamps;
  }

  useEffect(() => {
    const fetchDependentes = async () => {
      const url2 = ENDPOINT + '/client/dependents2';
      const titularId = {
        id_contrato: user.id_contrato,
      };
      const response = await fetch(url2, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(titularId),
      });
      const resp = await response.json();

      if (resp.data[0] !== null) {
        setDependentes(resp.data);
      } else {
        setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
      }
    };
    fetchDependentes();
  }, []);

  const generatedDatas = generateTimestampsForNext30Days(
    itemList[0].horario_seg_sex_inicio,
    itemList[0].horario_seg_sex_fim,
    itemList[0].horario_sab_inicio,
    itemList[0].horario_sab_fim
  );

  function verificarhorariolivre(event) {
    setData(event);

    const data = new Date(event);
    // Verifique se o dia da semana é sábado (6)
    if (data.getDay() === 6) {
      // // Converte os horários em strings para objetos Date
      // const horarioInicio = new Date(`1970-01-01T${itemList[0].horario_sab_inicio}:00`);
      // const horarioFim = new Date(`1970-01-01T${itemList[0].horario_sab_fim}:00`);
      // // Cria objetos Date para representar o horário de início e fim da manhã e tarde
      // const horarioInicioManha = new Date(`1970-01-01T05:00:00`);
      // const horarioFimManha = new Date(`1970-01-01T13:00:00`);
      // const horarioInicioTarde = new Date(`1970-01-01T13:00:00`);
      // const horarioFimTarde = new Date(`1970-01-01T23:00:00`);
      // // Verifica se há horário de manhã
      // if (
      //   (horarioInicio < horarioFimManha && horarioFim >= horarioInicioManha) ||
      //   (horarioInicio >= horarioInicioManha && horarioInicio < horarioFimManha)
      // ) {
      //   document.getElementById('t1').style.display = 'block';
      // } else {
      //   document.getElementById('t1').style.display = 'none';
      // }
      // // Verifica se há horário de tarde
      // if (
      //   (horarioInicio <= horarioFimTarde && horarioFim > horarioInicioTarde) ||
      //   (horarioInicio >= horarioInicioTarde && horarioInicio <= horarioFimTarde)
      // ) {
      //   document.getElementById('t2').style.display = 'block';
      // } else {
      //   document.getElementById('t2').style.display = 'none';
      // }
    }
  }

  const useDatas = generatedDatas.map((generated, index) => {
    const uniqueDate = generated.date;
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate);
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

    return (
      <>
        <Td
          key={index}
          id={`td${index}`}
          borderRadius="15px"
          p="0.5em"
          fontSize="xl"
          textAlign="center"
          class="margin-td"
          data={event}
          onClick={() => {
            document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
            document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.getElementById('date' + index).style.border = '1px solid orange';
            setPag(index);
            setEscolha(false);
            verificarhorariolivre(event);
          }}
        >
          <HStack
            id={`date${index}`}
            backgroundColor="rgba(90, 178, 173, 0.25)"
            borderRadius="15px"
            style={pag === index ? { border: '1px solid orange' } : {}}
          >
            <Text width="60%">
              <Flex ml="1em">
                <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="1em" color="rgba(62, 100, 255, 1)" padding="0 1em">
                  <Text fontWeight="bold" fontSize="xl">
                    {dataIcon[0]}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" mt="-1">
                    {dataIcon[1].toUpperCase()}
                  </Text>
                </Box>
                <Text margin="11% 0" fontSize="xs" align="left">
                  {event.toLocaleString('pt-br', { weekday: 'long' })}
                </Text>
              </Flex>
            </Text>
          </HStack>
        </Td>
      </>
    );
  });

  const useFilteredAgendas = generatedDatas[pag]['timestamps'].map((filteredAgenda, index) => {
    // console.log(filteredAgenda);
    if (!escolha) {
      var dateTime = filteredAgenda;
      if (dateTime === defaultDateTime) {
        setEscolha(true);
      }
    }

    return (
      <>
        <Td>
          <Badge
            id={'agenda' + index}
            borderRadius="0.5em"
            margin="0 0.5em"
            padding="0.5em 1.4em"
            size="lg"
            fontSize="md"
            style={`${filteredAgenda} ${filteredAgenda}` === defaultDateTime ? { border: '1px solid orange' } : {}}
            backgroundColor="rgba(90, 178, 173, 0.3)"
            color="rgba(62, 100, 255, 1)"
            onClick={() => {
              document.getElementById('agenda' + index).scrollIntoView({ behavior: 'smooth' });
              document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.getElementById('agenda' + index).scrollIntoView({ inline: 'center', behavior: 'smooth' });
              document.getElementById('agenda' + index).style.border = '1px solid orange';

              setEscolha(true);
              setHorario(filteredAgenda);
            }}
          >
            {filteredAgenda}
          </Badge>
        </Td>
      </>
    );
  });

  /*   useEffect(() => {
    if(disclosure.isOpen){
      var data = "";
      if(dataseleciada === 0){
        data = new Date(useDatas[0].props.children.props.data);
      }else{
        data = new Date(dataseleciada);
      }
      if (data.getDay() !== 6) {
        // itemList[0].horario_seg_sex_inicio = '07:30';
        // itemList[0].horario_seg_sex_fim = '12:40';
        const horarioInicio = new Date(`1970-01-01T${itemList[0].horario_seg_sex_inicio}:00`);
        const horarioFim = new Date(`1970-01-01T${itemList[0].horario_seg_sex_fim}:00`);

        const horarioInicioManha = new Date(`1970-01-01T06:30:00`);
        const horarioFimManha = new Date(`1970-01-01T12:30`);

        const horarioInicioTarde = new Date(`1970-01-01T12:30:00`);
        const horarioFimTarde = new Date(`1970-01-01T23:00:00`);

        // Verifica se há horário de manhã
        if (
          (horarioInicio < horarioFimManha && horarioFim >= horarioInicioManha) ||
          (horarioInicio >= horarioInicioManha && horarioInicio < horarioFimManha)
        ) {
          setManha(true);
        } else {
          setManha(false);
        }
        // Verifica se há horário de tarde
        if (
          (horarioInicio <= horarioFimTarde && horarioFim > horarioInicioTarde) ||
          (horarioInicio >= horarioInicioTarde && horarioInicio <= horarioFimTarde)
        ) {
          setTarde(true);
        } else {
          setTarde(false);
        }
      }else{
        const horarioInicio = new Date(`1970-01-01T${itemList[0].horario_sab_inicio}:00`);
        const horarioFim = new Date(`1970-01-01T${itemList[0].horario_sab_fim}:00`);

        // Cria objetos Date para representar o horário de início e fim da manhã e tarde
        const horarioInicioManha = new Date(`1970-01-01T05:00:00`);
        const horarioFimManha = new Date(`1970-01-01T13:00:00`);

        const horarioInicioTarde = new Date(`1970-01-01T13:00:00`);
        const horarioFimTarde = new Date(`1970-01-01T23:00:00`);

        // Verifica se há horário de manhã
        if (
          (horarioInicio < horarioFimManha && horarioFim >= horarioInicioManha) ||
          (horarioInicio >= horarioInicioManha && horarioInicio < horarioFimManha)
        ) {
          setManha(true);
        } else {
          setManha(false);
        }
        // Verifica se há horário de tarde
        if (
          (horarioInicio <= horarioFimTarde && horarioFim > horarioInicioTarde) ||
          (horarioInicio >= horarioInicioTarde && horarioInicio <= horarioFimTarde)
        ) {
          setTarde(true);
        } else {
          setTarde(false);
        }
      }
    }
  }, [disclosure.isOpen, useDatas, dataseleciada]); */

  function adicionarInformacoes(item, itemList) {
    return itemList
      .map((element) => {
        const correspondente = item.find((item) => item.procedimento_tuss_id === element.procedimento_tuss_id);
        if (correspondente) {
          element.deovita_id = correspondente.deovita_id;
          element.empresa_id = correspondente.empresa_id;
        }

        return element;
      })
      .filter((element) => element.dia_selecionado);
  }

  return (
    <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
      <DrawerOverlay />
      <DrawerContent borderTopRadius={20} width="100%" h="60%" borderTopWidth={1} boxShadow="dark-lg">
        <DrawerBody pb="10%">
          <Stack direction="column" spacing={4} paddingTop={30} align="center">
            {dependentes.length > 0 ? (
              <>
                <Radio w="3em" autoFocus hidden></Radio>
                <Text w="95%" size="sm" color="#585858" fontWeight="bold">
                  Selecione o paciente:
                </Text>
                <LinkBox id="" as="card" p="3" borderWidth="2px" borderColor="green" borderRadius="15px" fontSize="xs" width="100%">
                  <Formik initialValues={{ dependente: 0 }}>
                    <Heading size="sm" my="2">
                      <SelectField
                        name="dependente"
                        colorScheme="teal"
                        border="green"
                        color="black"
                        borderRadius="15px"
                        borderWidth="2px"
                        variant="ghost"
                        fontWeight="bold"
                        onChange={setPac}
                        options={arrayNomeDependentes}
                      />
                    </Heading>
                  </Formik>
                </LinkBox>
                <Button
                  colorScheme="teal"
                  w="100%"
                  borderRadius="15px"
                  onClick={() => {
                    navigate('/exames/finalizar/', {
                      state: {
                        item: adicionarInformacoes(itemList, user.cart.itens),
                        empresa: {
                          empresa_id: itemList[0].empresa_id,
                          empresa_nome: itemList[0].empresa,
                          endereco: `${String(itemList[0].endereco).trim()}, ${itemList[0].numero} - ${itemList[0].municipio} / ${
                            itemList[0].estado
                          }`,
                          id_recipient: itemList[0].id_recipient,
                        },
                        paciente: dependentes[pac],
                        valor: valor,
                        data: generatedDatas[pag]['date'],
                        horario: horario,
                        convenio:
                          (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
                          (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0')
                            ? '252'
                            : '251',
                        economia: economia,
                        orcamento_id: state.orcamento_id,
                        isByOrder: isByOrder,
                        solicitacao: state && state.orcamento_id ? true : false,
                        id_recipient_solicitante: state.id_recipient_solicitante,
                        id_empresa_solicitante: state.empresa_id_solicitante,
                      },
                    });
                  }}
                >
                  Continuar
                </Button>
                <Button colorScheme="teal" w="100%" boterRadius="15px" variant="ghost" onClick={disclosure.onClose}>
                  Voltar
                </Button>
              </>
            ) : (
              <>
                <Loading />
              </>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerHorario;
