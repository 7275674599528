import { useCallback, useContext, useEffect, useState } from "react";
import useDeovitaApi from "./api/useDeovitaApi";
import { AccountContext } from "components/AccountContext";

const usePushNotificationToken = () => {

  const [tokenAndPlatform, setTokenPlatform] = useState({token: '', platform: ''});
  const [isDeviceRegistered, setIsDeviceRegistered] = useState(false);

  const {user} = useContext(AccountContext);

  const {post} = useDeovitaApi();

  const getUrlParams = (url, ...params) => {
    console.log('loading token from url...', url);

    const urlObject = new URL(url);
    const searchParams = new URLSearchParams(urlObject.search);

    const objects = params.map((param) => {
      if ( !searchParams.has(param) ) {
        return undefined;
      }
      const value = searchParams.get(param);
      return {[param]: value};
    });
    
    const hash = objects.reduce((objetoAcumulado, objetoAtual) => {
      return { ...objetoAcumulado, ...objetoAtual };
    }, {});

    console.log(`url params value are`, hash);

    return hash;
  };

  /**
   * Get the push notification token from url 
   * for be used in registerDevice function.
   */
  const loadParamsFromUrl = useCallback((url) => {
    const params = getUrlParams(url, 'token', 'platform');
    setTokenPlatform({token: params?.token, platform: params?.platform});
  }, []);
  
  /**
   * Register the user device on the push notifications service.
   */
  const registerDevice = useCallback(async () => {
    console.log('trying register the device ...');

    const {platform, token} = tokenAndPlatform;
    const cpf = user?.cpf;

    try {
      if (!token) {
        throw new Error('FCM or APNSs push notification token are required');
      }
      if (!cpf) {
        throw new Error('CPF is required');
      }
      if(!platform) {
        throw new Error('Device Platform is required');
      }

      const {error} = await post('notifications/registerdevice', {cpf, platform, token});

      if (error) {
        throw error;
      }

      setIsDeviceRegistered(true);
      console.log('Device token registred succssessfuly');
    } catch (error) {
      console.error('Error:', error);
    }
  }, [tokenAndPlatform, user?.cpf, post]);

  // this effect get token and platform on first rendering
  useEffect(() => {
    loadParamsFromUrl(window.location.href);
  }, [loadParamsFromUrl])


  // after token and platform are abaliable in this hook state
  // this effect register the device on deovita api
  useEffect(()=> {
    try {

      if (!isDeviceRegistered) {
        registerDevice();
      }

    } catch (err) {
      console.error(err);
    }
  
    return ()=>{};
  }, [tokenAndPlatform, isDeviceRegistered, registerDevice]);

};

export default usePushNotificationToken;
