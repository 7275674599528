import { Button, Image, Text, VStack } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React from 'react';
import CinemaImage from '../../../../images/carrosel/descontos/Banners/Cinepolis.svg';
function Cinema() {
  return (
    <>
      <ChildHeader />
      <VStack alignItems="left" spacing={2.5} w={'100%'} p={'2em'}>
        <Image src={CinemaImage} borderRadius={20} w="100%" objectFit="cover" />
        <Text textAlign={'justify'} fontSize={'16px'} color={'#474747'} fontWeight={'bold'}>
          O DeoVita garante um desconto de até 40% para você aproveitar a magia do cinema. Porque entretenimento também é saúde
        </Text>
        <Text textAlign={'justify'} fontSize={'16px'} color={'#474747'} fontWeight={'bold'}>
           Clique e compre com desconto.
        </Text>
        <VStack alignItems="left">
          <Button
            bg="#EB8B2A"
            color="#FFFFFF"
            w="100%"
            mt={'2em'}
            onClick={() => {
              window.open('https://www.cinepolis.com.br/', '_blank');
            }}
          >
            Obter meus Descontos
          </Button>
        </VStack>
      </VStack>
    </>
  );
}

export default Cinema;
