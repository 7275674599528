function formatHour(time) {
    const hour = String(time).split(":")[0]
    const minute = String(time).split(":")[1]
    return `${hour}:${minute}`
}

function formatDate(date) {
    const year = String(date).split("-")[0]
    const month = String(date).split("-")[1]
    const day = String(date).split("-")[2]
    return `${day}/${month}/${year}`
}


export {
    formatHour,
    formatDate
}