import React, {  } from 'react';
import {chakra, Center, Heading, Modal, ModalBody, ModalContent, ModalOverlay, VStack, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Props = {
  icon: null,
  isOpen: true,
  headingTitle: '',
  bodyText: '',
  buttonText: '',
  onButtonPress: ()=>{},
};

const ModalAvisoCadastroDadosBancarios = (props = Props) => {
  const navigate = useNavigate();

  const handleEntendiPress = () => {
    navigate('/dashboard');
  };

  return (
    <Modal isOpen={props.isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent m={'5%'} borderRadius="0.8em">
        <ModalBody>
          <Center>
            {props.icon || null}
          </Center>
          <Center>
            <Heading textAlign="center" color="#333333" mt="5" size="md" mb={'1em'}>
              {props.headingTitle}
            </Heading>
          </Center>
          <VStack bg={'#E5E5E5'} p={'1'} borderRadius={'0.8em'}>
            <chakra.h3 mt="0.16em" color="#333333" textAlign="center" fontSize="1em">
              {props.bodyText}
            </chakra.h3>
          </VStack>
          { props.buttonText && (
            <Center mt={'1em'} mb={'1em'}>
              <Button
                bg={'orange'}
                color={'white'}
                h={'2.5em'}
                borderRadius={'0.8em'}
                onClick={props.onButtonPress}
              >
                {props.buttonText}
              </Button>
            </Center>
          )}
          <Center mt={'1em'} mb={'1em'}>
            <Button
              bg={'white'}
              color={'orange'}
              h={'2.5em'}
              borderRadius={'0.8em'}
              onClick={handleEntendiPress}
            >
              Entendi
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalAvisoCadastroDadosBancarios;