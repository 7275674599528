import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { AccountContext } from './AccountContext';
import Opening from './Auth/Abertura';
//import Opening from './Auth/SistemaEmManutencao';
import Login from './Auth/Login';
import SucessoGenerico from './Alerts/Sucesso';
import ErroGenerico from './Alerts/Error';
import PrivateRoutes from './PrivateRoutes';
// cadastro
import Cadastro from './Cadastro/Cpf';
import DadosBasicos from './Cadastro/DadosBasicos';
import DadosAdc from './Cadastro/DadosAdc';
import Senha from './Cadastro/Senha';
import Endereco from './Cadastro/Endereco';
import Conferencia from './Cadastro/Conferencia';
import FimCadastroCliente from './Cadastro/FimCadastroCliente';
import Adicionais from './Cadastro/Adicionais';
import ResumoPagamento from './Cadastro/ResumoPagamento';
import Plano from './Cadastro/Plano';
import PlanoModo from './Cadastro/PlanoModo';
import CadastroFim from './Cadastro/Fim';
import ConfirmaTelefone from './Cadastro/ConfirmacaoTelefone';
import TrocarSenha from './Auth/Recuperacao/TrocarSenha';
import PlanoPendente from './Auth/PagamentoEmAnalise';
import QrCodeCadastro from './Cadastro/QrCode';
// welcome-screen
import ComprarAdicionais from './Dashboard/Main/WelcomeScreen/ComprarAdicionais';
import ResumoCompra from './Dashboard/Main/WelcomeScreen/ResumoPagamento';
import ProcessarCompraAdicionais from './Dashboard/Main/WelcomeScreen/ProcessarCompra';
// dashboard
import Main from './Dashboard/Main/Main';
// pronto atendimento
import InicioPa from './Dashboard/Main/ProntoAtendimento/Inicio';
import TermosPa from './Dashboard/Main/ProntoAtendimento/Termos';
import ComorbidadesPa from './Dashboard/Main/ProntoAtendimento/Comorbidades';
import SintomasPa from './Dashboard/Main/ProntoAtendimento/Sintomas';
import FimPa from './Dashboard/Main/ProntoAtendimento/Fim';
import AtendimentoPa from './Dashboard/Main/ProntoAtendimento/Atendimento';
import ProcessarCompra from './Dashboard/Main/ProntoAtendimento/ProcessarCompra';
// atendimento
import EscolhaProcedimento from './Dashboard/Main/Atendimento/EscolhaProcedimento';
import Agendamento from './Dashboard/Main/Atendimento/Agendamento';
import ConferePedido from './Dashboard/Main/Atendimento/ConferePedido';
import LoadingAtendimento from './Dashboard/Main/Atendimento/Loading';
// Agendamento de Exames
import AgendamentoExames from './Dashboard/Main/Exames/Agendamento';
import EscolheEmpresa from './Dashboard/Main/Exames/EscolheEmpresa';
import EscolheMedicoProcedimento from './Dashboard/Main/Exames/EscolheMedicoProcedimento';
import PedidoExame from './Dashboard/Main/Exames/ConferePedido';
import FinalizarExame from './Dashboard/Main/Exames/Finalizar';
import ExamesLoading from './Dashboard/Main/Exames/Loading';
// perfil
import Consultas from './Perfil/Historico/ConsultasMarcadas';
import AtualizarEndereco from './Perfil/Usuario/Endereco';
import Perfil from './Perfil/Main';
import DadosPessoais from './Perfil/Usuario/DadosPessoais';
import DadosBancarios from './Perfil/Usuario/DadosBancarios';
import EditarCartao from './Perfil/Usuario/Cartao';
import Detalhes from './Perfil/Historico/Detalhes';
import DetalhesAnalise from './Perfil/Historico/DetalhesEmAnalise';
import DetalhesAnaliseSistema from './Perfil/Historico/DetalhesAnaliseSistema';
import CancelamentoConsulta from './Perfil/Historico/CancelamentoConsulta';
// pagamento
import Pagamentos from './Pagamento/Menu';
import PagamentoParcelas from './Perfil/Dependentes/Cadastro/PagamentoParcelas';
import ProcessarPagamentoPendente from './Perfil/Dependentes/Cadastro/ProcessarPagamento';
import TaxaCancelamentoValor from './Perfil/Dependentes/TaxaCancelamentoValor';
//Assinaturas
import Assinaturas from './Perfil/Assinaturas/Main';
import MaisOpcoes from './Perfil/Assinaturas/MaisOpcoes';
import TitularFaleceu from './Perfil/Assinaturas/TitularFaleceu';
import CancelarContrato from './Perfil/Assinaturas/CancelarContrato';
import CancelamentoMusicaSaudade from './Perfil/Assinaturas/CancelamentoMusicaSaudade';
import TentativaReterCancelamento from './Perfil/Assinaturas/TentativaReterCancelamento';
import ContinuaContrato from './Perfil/Assinaturas/ContinuaContrato';
import MotivosCancelamentoContrato from './Perfil/Assinaturas/MotivosCancelamentoContrato';
import DividaPendente from './Perfil/Assinaturas/DividaPendente';
import SucessoCadastroPet from './Perfil/Assinaturas/Modal/SucessoCadastroPet';
import TaxaCancelamento from './Perfil/Assinaturas/TaxaCancelamento';
import ExcluirContaPagina from './Perfil/Assinaturas/ExcluiContaPagina';

// dependentes
import Dependentes from './Perfil/Dependentes/Main';
import CadastraDependente from './Perfil/Dependentes/Cadastro/Cadastro';
import CadastraDependentePet from './Perfil/Dependentes/Cadastro/CadastroPet';
import EditarDependente from './Perfil/Dependentes/Cadastro/Editar';
import AvisoExclusaoDependente from './Perfil/Dependentes/Cadastro/Exluir';
import ProcessarExclusaoDependente from './Perfil/Dependentes/Cadastro/ProcessarExclusao';
import ProcessarDadosAssinatura from './Perfil/Dependentes/Cadastro/ProcessaDadosAssinatura';
// recuperacao
import GeraCodigo from './Auth/Recuperacao/GeraCodigo';
import RecuperaSenha from './Auth/Recuperacao/RecuperaSenha';
import CheckCpf from './Auth/Recuperacao/CheckCpf';
import SecurityQuestions from './Auth/Recuperacao/SecurityQuestions';
import UpdatePhone from './Auth/Recuperacao/UpdatePhone';
import Loading from './Loading';
// perfil empresa
import DetalhesEmpresa from './PerfilEmpresa/Empresa/DetalhesEmpresa';
import CurriculoMedico from './PerfilEmpresa/Medico/CurriculoMedico';
import CurriculoDetalhes from './PerfilEmpresa/Medico/CurriculoDetalhes';
// Exames
import PedidoExameLivre from './AreaLivre/Exames/ConferePedidoLivre';
import ExamesCpfLivre from './AreaLivre/Exames/CpfLivre';
import ExamesDadosLivre from './AreaLivre/Exames/DadosBasicosLivre';
// empreendedor
import AreaEmpreendedor from './Dashboard/Main/Empreendedor/Main';
import AtivaEmpreendedor from './Dashboard/Main/Empreendedor/AtivaEmpreendedor';
import DadosBancariosEmpreendedor from './Dashboard/Main/Empreendedor/DadosBancarios';
import DadosResponsavel from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DadosResponsavel';
import EnderecoCredenciado from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/Endereco';
import SenhaClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/SenhaClinica';
import DadosClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DadosClinica';
import DadosBancariosClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DadosBancariosClinica';
import DetalhesClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DetalhesClinica';
import FimCadastroEstabelecimento from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/Fim';
import QrCode from './Dashboard/Main/Empreendedor/QrCode/GerarQrCode';
import MaterialApoio from './Dashboard/Main/Empreendedor/MaterialApoio/Main';
import ExtratoEmpreendedor from './Dashboard/Main/Empreendedor/Extrato/Main';
import Contratos from './Dashboard/Main/Empreendedor/Contratos/Main';
import Vendedores from './Dashboard/Main/Empreendedor/Vendedores/Main';
import Estabelecimentos from './Dashboard/Main/Empreendedor/Estabelecimentos/Main';

// -------------------------Area Livre--------------------------------
import MainLivre from './AreaLivre/DashboardLivre/MainLivre';
import SucessoLivre from './AreaLivre/DashboardLivre/Orientacoes';
// cadastro area livre
import DadosBasicosLivre from './AreaLivre/Cadastro/DadosBasicosLivre';
import DetalhesLivre from './AreaLivre/Atendimento/DetalhesLivre';
import CpfLivre from './AreaLivre/Cadastro/CpfLivre';
// atendimeto area livre
import AgendamentoLivre from './AreaLivre/Atendimento/AgendamentoLivre';
import ConferePedidoLivre from './AreaLivre/Atendimento/ConferePedidoLivre';
import ConsultasLivre from './AreaLivre/Atendimento/ConsultasLivre';
// pronto atendimento livre
import AtendimentoLivre from './AreaLivre/ProntoAtendimentoLivre/AtendimentoLivre';
import AvisoLivre from './AreaLivre/ProntoAtendimentoLivre/AvisoLivre';
import ComorbidadesLivre from './AreaLivre/ProntoAtendimentoLivre/ComorbidadesLivre';
import FimLivre from './AreaLivre/ProntoAtendimentoLivre/FimLivre';
import TermosLivre from './AreaLivre/ProntoAtendimentoLivre/TermosLivre';
import SintomasLivre from './AreaLivre/ProntoAtendimentoLivre/SintomasLivre';
import ProcessarCompraLivre from './AreaLivre/ProntoAtendimentoLivre/ProcessarCompraLivre';
import CpfLivrepa from './AreaLivre/ProntoAtendimentoLivre/CpfLivrepa';
import DadosBasicosLivrepa from './AreaLivre/ProntoAtendimentoLivre/DadosBasicosLivrepa';
// perfil empresa livre
import DetalhesEmpresaLivre from './AreaLivre/PerfilEmpresa/Empresa/DetalhesEmpresaLivre';
import ListaMedicosLivre from './AreaLivre/PerfilEmpresa/Medico/ListaMedicosLivre';
import ListaMedicosMain from './Dashboard/Main/ListaMedicos';
import MedicosGeral from './Dashboard/Main/Medicos';
import DescontoDrogasil from '../components/AreaLivre/DashboardLivre/ClubeVantagens/Drogasil';
import DescontoCinepolis from '../components/AreaLivre/DashboardLivre/ClubeVantagens/Cinema';
import DescontoBurguerKing from '../components/AreaLivre/DashboardLivre/ClubeVantagens/BurguerKing';
import DescontoDominos from '../components/AreaLivre/DashboardLivre/ClubeVantagens/Dominos';
import DescontoMagalu from '../components/AreaLivre/DashboardLivre/ClubeVantagens/Magalu';
import DescontoNetshoes from '../components/AreaLivre/DashboardLivre/ClubeVantagens/Netshoes';
import DescontoCasasBahia from '../components/AreaLivre/DashboardLivre/ClubeVantagens/CasasBahia';
import DescontoBluefit from '../components/AreaLivre/DashboardLivre/ClubeVantagens/Bluefit';
import ClubeVatagens from './Dashboard/Main/ClubeVantagem';
import CurriculoMedicoLivre from './AreaLivre/PerfilEmpresa/Medico/CurriculoMedicoLivre';
// Telemedicina
import Telemedicina from './Telemedicina/Main';
import Step1 from './Dashboard/Main/Telemedicina/MedicoAgora/triagem/Step1';
// Carrinho de Compras
import CarrinhoCompras from './Dashboard/Main/Carrinho/Main';
import Checkin from './Dashboard/Main/Checkin/Checkin';
//Telepet
import TelepetTermos from './Dashboard/Main/Telemedicina/Telepet/Termos';
import TelepetFila from './Dashboard/Main/Telemedicina/Telepet/Fila';
//MedicoAgora
import MedicoAgora from './Dashboard/Main/Telemedicina/MedicoAgora/Main';
import MedicoAgora2 from './Dashboard/Main/Telemedicina/MedicoAgora/Main2';
import AtendimentoTelemed from './Dashboard/Main/Telemedicina/MedicoAgora/Atendimento';
import MedicoAgoraEspecialistas from './Dashboard/Main/Telemedicina/Especialista/Main2';
import ListaEspecialista from './Dashboard/Main/Telemedicina/Especialista/ListaEspecialistas';
import AgendamentoEspecialista from './Dashboard/Main/Telemedicina/Especialista/agendamento';

import ConfereEspecialista from './Dashboard/Main/Telemedicina/Especialista/ConferePedido';
import EscolheProcedimentoEspecialista from './Dashboard/Main/Telemedicina/Especialista/EscolheProcedimento';
//import MedicoAgoraEspecialistas2 from './Dashboard/Main/Telemedicina/Especialista/Main2';

import EscolheGrupo from './Dashboard/Main/Exames/EscolheGrupo';
import TermosTelemed from './Telemedicina/TermosTelemed';
import TermosEmergencia from './Telemedicina/TermosEmergencia';

import TermosTelemedAgora from './Dashboard/Main/Telemedicina/MedicoAgora/TermosTelemed';
import TermosEmergenciaAgora from './Dashboard/Main/Telemedicina/MedicoAgora/TermosEmergencia';
import AvisoPreAtendimento from './Dashboard/Main/Telemedicina/MedicoAgora/AvisoPreAtendimento';
import AvisoPreAtendimento2 from './Dashboard/Main/Telemedicina/MedicoAgora/AvisoPreAtendimento2';
import TelaDadosBanner from './Banners/TelaDadosBanner';
import AgendamentoDependente from './Dashboard/Main/Exames/AgendamentoDependente';
import withPushNotifications from './Utils/Functions/withPushNotifications';
import CancelamentoFinal from './Perfil/Assinaturas/CancelamentoFinal';
import ContratoCanceladoSucesso from './Perfil/Assinaturas/ContratoCanceladoSucesso';
import VirarTitular from './Perfil/Assinaturas/VirarTitular';
import ExcluirContaDependente from './Perfil/Assinaturas/ExcluirContaDependente';
import ContaExcluida from './Perfil/Assinaturas/ContaExcluida';
import ConfirmacaoExclusaoConta from './Perfil/Assinaturas/ConfirmacaoExclusaoConta';
import AtualizarTelefone from './Dashboard/Main/Telemedicina/MedicoAgora/AtualizaTelefone';
import TermosDeUso from './Dashboard/Main/Telemedicina/TermosDeUso';

import MdvNaoAssinante from './Dashboard/Main/MdvNaoAssinante';
import Step2 from './Dashboard/Main/Telemedicina/MedicoAgora/triagem/Step2';
import EspecialidadesDeslogado from './Dashboard/Main/EspecialidadesDeslogado';
import TesteConexao from './Dashboard/Main/Telemedicina/TesteConexao';
import AgendamentoCalendario from './Dashboard/Main/Telemedicina/Especialista/AgendamentoCalendario';
import AgendamentoCalendario2 from './Dashboard/Main/Telemedicina/Especialista/AgendamentoCalendario2';
import VerificacaoIdentidadeEnel from './Enel/VerificacaoIdentidadeEnel';
import GanhosTotais from './MDV/GanhosTotais';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Views() {
  const { user, toastSolicitacao, setUser, setClubeItens, clubeVantagens } = useContext(AccountContext);
  const [toastShown, setToastShown] = useState(false);
  const [searchParams] = useSearchParams();

  const payload = {
    cpf: user.cpf,
    cart: user.cart,
  };

  useEffect(() => {
    if (user.loggedIn === true) {
      fetch(`${ENDPOINT}/cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
        body: JSON.stringify(payload),
      }).then((response) => {
        response.json();
      });
    }
  }, [user]);
  console.log('USER', user);
  useEffect(() => {
    if (
      (!toastShown &&
        user.loggedIn !== null &&
        user.loggedIn !== false &&
        user.solicitacoes &&
        user?.solicitacoes &&
        Object.keys(user.solicitacoes).length > 0) ||
      (!toastShown &&
        user.loggedIn !== null &&
        user.loggedIn !== false &&
        user.solicitacoes &&
        user?.solicitacoes &&
        Object.keys(user.solicitacoes_consulta).length > 0)
    ) {
      if (!toastShown) {
        toastSolicitacao();
        setToastShown(false);
      }
    } else {
      setToastShown(true);
    }
  }, [user, toastShown]);

  useEffect(() => {
    console.log('views');
    const lat = searchParams.get('latitude') ?? null;
    const long = searchParams.get('longitude') ?? null;

    setUser((prevState) => ({
      ...prevState,
      coords: {
        latitudeAtual: lat,
        longitudeAtual: long,
      },
    }));
  }, []);
  const fetchClubeVantagens = async () => {
    try {
      const response = await fetch(ENDPOINT + `/clients/getClubeVantagens`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      console.log(postsData);
      setClubeItens(postsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClubeVantagens();
  }, []);
  return user.loggedIn === null ? (
    <Loading />
  ) : clubeVantagens && clubeVantagens.length > 0 ? (
    <Routes>
       <Route path="/" element={<Cadastro />} />
       <Route path="/login" element={<QrCodeCadastro />} />
       <Route path="/cadastro/qrcode" element={<QrCodeCadastro />} />
      <Route path="/cadastro" element={<Cadastro />} />
      <Route path="/cadastro/dadosbasicos" element={<DadosBasicos />} />
      <Route path="/cadastro/dadosadc" element={<DadosAdc />} />
      <Route path="/cadastro/senha" element={<Senha />} />
      <Route path="/cadastro/endereco" element={<Endereco />} />
      <Route path="/cadastro/conferencia" element={<Conferencia />} />
      <Route path="/cadastro/fim-cadastro-cliente" element={<FimCadastroCliente />} />
      <Route path="/cadastro/plano" element={<Plano />} />
      <Route path="/cadastro/plano-modo" element={<PlanoModo />} />
      <Route path="/cadastro/adicionais" element={<Adicionais />} />
      <Route path="/cadastro/resumo-pagamento" element={<ResumoPagamento />} />
      <Route path="/cadastro/fim" element={<CadastroFim />} />
      <Route path="/cadastro/confirmacao" element={<ConfirmaTelefone />} />
      <Route path="/cadastro/analise" element={<PlanoPendente />} />
      <Route path="/agendamento" element={<Agendamento />} />
      <Route path="/fimpa" element={<FimPa />} />
      <Route path="/iniciopa" element={<InicioPa />} />
      <Route path="/termospa" element={<TermosPa />} />
      <Route path="/comorbidadespa" element={<ComorbidadesPa />} />
      <Route path="/sintomaspa" element={<SintomasPa />} />
      <Route path="/pa/atendimento" element={<AtendimentoPa />} />
      <Route path="/pronto-atendimento/compra" element={<ProcessarCompra />} />

      {/* -------------------------- Atendimento ------------------------------------------ */}
      <Route path="/atendimento/escolha-procedimento" element={<EscolhaProcedimento />} />
      <Route path="/atendimento/agendamento" element={<Agendamento />} />
      <Route path="/atendimento/agendamentoDependente" element={<AgendamentoDependente />} />
      <Route path="/atendimento/pedido" element={<ConferePedido />} />
      <Route path="/atendimento/loading/" element={<LoadingAtendimento />} />
      {/* -------------------------- Atendimento ------------------------------------------ */}

      <Route path="/recuperacao/codigo" element={<GeraCodigo />} />
      <Route path="/recuperacao/cpf" element={<CheckCpf />} />
      <Route path="recuperacao/trocarsenha" element={<TrocarSenha />} />
      <Route path="/recuperacao/security-questions" element={<SecurityQuestions />} />
      <Route path="/perfil/main" element={<Perfil />} />
      <Route path="/perfil/dependentes" element={<Dependentes />} />
      <Route path="/perfil/assinaturas" element={<Assinaturas />} />
      <Route path="/perfil/assinaturas/mais-opcoes" element={<MaisOpcoes />} />
      <Route path="/perfil/assinaturas/conta-excluida" element={<ContaExcluida />} />
      <Route path="/perfil/assinaturas/confirma-conta-excluida" element={<ConfirmacaoExclusaoConta />} />
      <Route path="/perfil/assinaturas/titular-faleceu" element={<TitularFaleceu />} />
      <Route path="/perfil/assinaturas/vira-titular" element={<VirarTitular />} />
      <Route path="/perfil/assinaturas/cancelar-contrato-dependente" element={<ExcluirContaDependente />} />
      <Route path="/perfil/assinaturas/cancelar-contrato" element={<CancelarContrato />} />
      <Route path="/perfil/assinaturas/cancelar-contrato-musica-saudade" element={<CancelamentoMusicaSaudade />} />
      <Route path="/perfil/assinaturas/tentativa-reter-cancelamento" element={<TentativaReterCancelamento />} />
      <Route path="/perfil/assinaturas/cancelamento-final" element={<CancelamentoFinal />} />
      <Route path="/perfil/assinaturas/contrato-cancelado-sucesso" element={<ContratoCanceladoSucesso />} />
      <Route path="/perfil/assinaturas/continua-contrato" element={<ContinuaContrato />} />
      <Route path="/perfil/assinaturas/movitos-cancelamento-contrato" element={<MotivosCancelamentoContrato />} />
      <Route path="/perfil/assinaturas/parcela-mes-pendente" element={<DividaPendente />} />
      <Route path="/perfil/assinaturas/sucesso-cadastro-pet" element={<SucessoCadastroPet />} />
      <Route path="/perfil/assinaturas/taxa-cancelamento" element={<TaxaCancelamento />} />
      <Route path="/perfil/dependentes/cadastro" element={<CadastraDependente />} />
      <Route path="/perfil/dependentes/cadastro-pet" element={<CadastraDependentePet />} />
      <Route path="/perfil/dependentes/editar" element={<EditarDependente />} />
      <Route path="/perfil/dependentes/aviso-exclusao" element={<AvisoExclusaoDependente />} />
      <Route path="/perfil/dependentes/processar-exclusao" element={<ProcessarExclusaoDependente />} />
      <Route path="/perfil/dependentes/processar-dados-assinatura" element={<ProcessarDadosAssinatura />} />
      <Route path="/perfil/dependentes/processar-pagamento" element={<ProcessarPagamentoPendente />} />
      <Route path="/perfil/dependentes/taxa-cancelamento-valor" element={<TaxaCancelamentoValor />} />
      <Route path="/perfil/dependentes/excluir-conta-pagina" element={<ExcluirContaPagina />} />

      <Route path="/perfil/dados-pessoais" element={<DadosPessoais />} />
      <Route path="/perfil/dados-bancarios" element={<DadosBancarios />} />
      <Route path="/perfil/editar-cartao" element={<EditarCartao />} />
      <Route path="/recuperacao/codigo" element={<GeraCodigo />} />
      <Route path="/recuperacao/senha" element={<RecuperaSenha />} />
      <Route path="/recuperacao/atualizar-fone" element={<UpdatePhone />} />
      <Route path="/perfil/main" element={<Perfil />} />
      <Route path="/perfil/endereco" element={<AtualizarEndereco />} />
      <Route path="/empresa/detalhes" element={<DetalhesEmpresa />} />
      <Route path="/empresa/medico/curriculo" element={<CurriculoMedico />} />
      <Route path="/empresa/medico/curriculodetalhes" element={<CurriculoDetalhes />} />
      <Route path="/alerts/sucesso/:rota" element={<SucessoGenerico />} />
      <Route path="/alerts/erro/:rota" element={<ErroGenerico />} />
      <Route path="/pagamentos" element={<Pagamentos />} />
      <Route path="/verificacao-enel" element={<VerificacaoIdentidadeEnel />} />
      <Route path="/pagamentoparcelas" element={<PagamentoParcelas />} />
      <Route path="/consultas" element={<Consultas />} />
      <Route path="/consultas/detalhes" element={<Detalhes />} />
      <Route path="/consultas/detalhes-analise" element={<DetalhesAnalise />} />
      <Route path="/consultas/detalhes-analise-sistema" element={<DetalhesAnaliseSistema />} />

      <Route path="/consultas/cancelamento" element={<CancelamentoConsulta />} />
      <Route path="/welcome-screen/comprar-adicionais" element={<ComprarAdicionais />} />
      <Route path="/welcome-screen/resumo-compra" element={<ResumoCompra />} />
      <Route path="/welcome-screen/processar-compra" element={<ProcessarCompraAdicionais />} />
      <Route path="/carrinho" element={<CarrinhoCompras />} />

      <Route path="/telemedicina" element={<Telemedicina />} />

      <Route path="/lista-medico-main" element={<ListaMedicosMain />} />
      <Route path="/medicosGeral" element={<MedicosGeral />} />

      {/* ------------------------------Descontos------------------------------------------*/}
      <Route path="/descontos-drogasil" element={<DescontoDrogasil />} />
      <Route path="/descontos-cinepolis" element={<DescontoCinepolis />} />
      <Route path="/descontos-ClubeVatagens" element={<ClubeVatagens />} />
      <Route path="/descontos-burguerking" element={<DescontoBurguerKing />} />
      <Route path="/descontos-dominos" element={<DescontoDominos />} />
      <Route path="/descontos-magalu" element={<DescontoMagalu />} />
      <Route path="/descontos-netshoes" element={<DescontoNetshoes />} />
      <Route path="/descontos-casasBahia" element={<DescontoCasasBahia />} />
      <Route path="/descontos-bluefit" element={<DescontoBluefit />} />

      {/* ------------------------------Exames------------------------------------------*/}
      <Route path="/exames/agendamento" element={<AgendamentoExames />} />
      <Route path="/exames/empresa" element={<EscolheEmpresa />} />
      <Route path="/exames/medico" element={<EscolheMedicoProcedimento />} />
      <Route path="/exames/grupo" element={<EscolheGrupo />} />
      <Route path="/exames/conferencia" element={<PedidoExame />} />
      <Route path="/exames/loading" element={<ExamesLoading />} />
      <Route path="/exames/finalizar" element={<FinalizarExame />} />
      {/* ------------------------------Exames------------------------------------------*/}

      {/* ------------------------------Empreendedor------------------------------------------*/}
      <Route path="/empreendedor" element={<AreaEmpreendedor />} />
      <Route path="/empreendedor/ativar" element={<AtivaEmpreendedor />} />
      <Route path="/empreendedor/dados-bancarios" element={<DadosBancariosEmpreendedor />} />
      <Route path="/empreendedor/cadastro-estabelecimento/dados-responsavel" element={<DadosResponsavel />} />
      <Route path="/empreendedor/cadastro-estabelecimento/endereco" element={<EnderecoCredenciado />} />
      <Route path="/empreendedor/cadastro-estabelecimento/senha" element={<SenhaClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/dados-clinica" element={<DadosClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/dados-bancarios" element={<DadosBancariosClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/detalhes-clinica" element={<DetalhesClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/fim" element={<FimCadastroEstabelecimento />} />
      <Route path="/empreendedor/qrcode" element={<QrCode />} />
      <Route path="/emprendedor/materialapoio" element={<MaterialApoio />} />
      <Route path="/empreendedor/extrato" element={<ExtratoEmpreendedor />} />
      <Route path="/empreendedor/contratos" element={<Contratos />} />
      <Route path="/empreendedor/vendedores" element={<Vendedores />} />
      <Route path="/empreendedor/estabelecimentos" element={<Estabelecimentos />} />
      <Route path="/arealivre/sucesso" element={<SucessoLivre />} />
      {/* ------------------------------Empreendedor------------------------------------------*/}
      <Route path="/checkin" element={<Checkin />} />
      {/* ------------------------------Telepet------------------------------------------*/}
      <Route path="/telepet/termos" element={<TelepetTermos />} />
      <Route path="/telepet/fila" element={<TelepetFila />} />
      {/* ------------------------------Telepet------------------------------------------*/}
      {/* ------------------------------MedicoAgora------------------------------------------*/}
      <Route path="/termos-de-uso/" element={<TermosDeUso />} />
      <Route path="/medico-agora/" element={<MedicoAgora2 />} />
      <Route path="/medico-agora/triagem" element={<Step1 />} />
      <Route path="/medico-agora/triagem2" element={<Step2 />} />
      <Route path="/medico-agora-rapidoc/" element={<MedicoAgora />} />
      <Route path="/medico-agora/atendimento" element={<AtendimentoTelemed />} />
      <Route path="/medico-agora/especialista" element={<MedicoAgoraEspecialistas />} />
      <Route path="/medico-agora/especialista/lista" element={<ListaEspecialista />} />
      <Route path="/medico-agora/especialista/agendamento" element={<AgendamentoEspecialista />} />
      <Route path="/medico-agora/especialista/procedimento" element={<EscolheProcedimentoEspecialista />} />
      <Route path="/medico-agora/especialista/conferencia" element={<ConfereEspecialista />} />
      <Route path="/termostelemed/" element={<TermosTelemed />} />
      <Route path="/termosemergencia/" element={<TermosEmergencia />} />
      <Route path="/termostelemedagora/" element={<TermosTelemedAgora />} />
      <Route path="/termosemergenciaagora/" element={<TermosEmergenciaAgora />} />
      <Route path="/aviso-pre-atendimento/" element={<AvisoPreAtendimento />} />
      <Route path="/aviso-pre-atendimento2/" element={<AvisoPreAtendimento2 />} />
      <Route path="/medico-agora-atualiza-telefone/" element={<AtualizarTelefone />} />
      <Route path="/mdv-nao-assinante/" element={<MdvNaoAssinante />} />
      <Route path="/especialidades-deslogado/" element={<EspecialidadesDeslogado />} />
      <Route path="/teste-conexao/" element={<TesteConexao />} />
      <Route path="/agendamento-calendario/" element={<AgendamentoCalendario />} />
      <Route path="/agendamento-calendario2/" element={<AgendamentoCalendario2 />} />

      {/* ------------------------------MedicoAgora------------------------------------------*/}

      {/* ------------------------------DadosBanners------------------------------------------*/}
      <Route path="/banner" element={<TelaDadosBanner />} />

      {/* ------------------------------MDV------------------------------------------*/}
      <Route path="/mdv/ganhos-totais/" element={<GanhosTotais />} />


      <Route element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Cadastro />} />
      </Route>
      <Route path="*" element={<Cadastro />} />
    </Routes>
  ) : (
    <Loading />
  );
}

export default withPushNotifications(Views);
