import { HStack, VStack, Heading, Text, Image, Box, Grid, GridItem } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import Loading from '../../../Loading';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function EscolheGrupo() {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);

  setPage(state.tipo);

  useEffect(() => {
    const fetchPosts = async () => {
      const url = ENDPOINT + '/schedule/getGroups/' + state.tipo;
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json();
      setGroups(postsData);
      if (postsData.length === 0) setError('Serviço indisponível no momento');
    };
    fetchPosts();
  }, []);

  const useGroups = groups.map((group, index) => {
    return (
      <GridItem
        as="card"
        fontWeight="bold"
        borderRadius="10px"
        shadow="md"
        width="100%"
        backgroundColor="#F0F0F0"
        onClick={() => {
          navigate('/exames/agendamento', {
            state: { grupo_nome: group.nome, grupo: group.ambulatorio_grupo_id, lista_grupos: groups },
          });
        }}
      >
        <HStack w="100%" h="8em" justify="center">
          <Image name={group.NAME} src={`${process.env.REACT_APP_URL_GRUPO}/` + group.ambulatorio_grupo_id + '.png'} />
        </HStack>
      </GridItem>
    );
  });
  return groups.length > 0 ? (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack m="5%" pb="25%" spacing={3} align="start">
        <Heading fontSize="lg">Escolha um grupo de {state.tipo.toLowerCase()}</Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={5} w="100%">
          {groups && useGroups}
        </Grid>
      </VStack>
    </>
  ) : (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack m="5%" pb="25%" spacing={3} align="start">
        <Heading fontSize="lg">Escolha um grupo de {state.tipo.toLowerCase()}</Heading>
        <Box height="10%" w="100%">
          {error === null ? <Loading /> : <Text>{error}</Text>}
        </Box>
      </VStack>
    </>
  );
}

export default EscolheGrupo;
