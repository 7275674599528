import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  DrawerOverlay,
  Button,
  Heading,
  Flex,
  Text,
  HStack,
  Badge,
  useDisclosure,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Center,
  Avatar,
  List,
  Divider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  chakra,
  ModalFooter,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import Calendario from './Calendario';
import { GoBriefcase } from 'react-icons/go';
import { StarIcon } from '@chakra-ui/icons';
import { formatHour } from 'components/Utils/Functions/DateFunctions';
import Loading from 'components/Loading';
import { format, startOfDay } from 'date-fns';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function DrawerAgendamentoDependente({ disclosure, procedure, empresa }) {
  const navigate = useNavigate();
  const modalDisclosure = useDisclosure();
  const { user, setPage, setUser } = useContext(AccountContext);
  const { state } = useLocation();
  const [cart, setCart] = useState(user.cart ? user.cart.itens : []);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);
  const [dependentes, setDependentes] = useState(null);
  const [medicos, setMedicos] = useState(null);
  const [dependenteSelecionado, setDependenteSelecionado] = useState(null);
  const [selecionado, setSelecionado] = useState(false);
  const [medicoStep, setMedicoStep] = useState(1);
  const [turno, setTurno] = useState('');
  const [medicosDatas, setMedicosDatas] = useState([]);
  const [dataMedicoHoras, setDataMedicoHoras] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [horaDataSelecionada, setHoraDataSelecionada] = useState(null);
  const [horaDataSelecionadaDisplay, setHoraDataSelecionadaDisplay] = useState(null);
  const [horario, setHorario] = useState(null);
  const [isByOrder, setIsByOrder] = useState(true);
  const [dadosMedico, setDadosMedico] = useState(null);
  const [isSaturday, setIsSaturday] = useState(false);
  const [horariosEmpresa, setHorarioEmpresa] = useState([]);
  const [horarioLoading, setHorarioLoading] = useState(true);
  const [horarioTurno, setHorarioTurno] = useState(false);
  useEffect(() => {
    if (disclosure.isOpen === false) {
      setLoading(false);
      setDate(null);
      setDependentes(null);
      setMedicos(null);
      setDependenteSelecionado(null);
      setSelecionado(false);
      setMedicoStep(1);
      setTurno('');
      setMedicosDatas([]);
      setDataMedicoHoras([]);
      setActiveTab(0);
      setHoraDataSelecionada(null);
      setHoraDataSelecionadaDisplay(null);
      setHorario(null);
      setIsByOrder(true);
      setDadosMedico(null);
      setIsSaturday(false);
    }
  }, [disclosure]);

  const toast = useToast();
  const ShowToast = (title, description, status, duration, isClosable, position, colorScheme) => {
    toast({
      title,
      description,
      status,
      duration,
      isClosable,
      position,
      colorScheme,
      containerStyle: {
        paddingBottom: '5em',
      },
    });
  };

  setPage('Agendamento');

  const { titular, id_contrato } = user;
  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
        ? '#EB8B2A'
        : titular === true && id_contrato > 0
          ? '#EB8B2A'
          : '#529C94';

  const currentDate = (currentDateTime) => {
    if (currentDateTime) {
      currentDateTime.setUTCHours(currentDateTime.getUTCHours());
      return currentDateTime.toISOString().replace('T', ' ').split('.')[0].split(' ')[0];
    }
  };

  // Obter Dependentes
  const fetchDependentes = async () => {
    const url2 = ENDPOINT + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    if (resp.data[0] !== null) {
      setDependentes(resp.data);
    } else {
      setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita }]);
    }
  };

  // Obter Médicos
  const fetchMedicos = async () => {
    if (procedure.medico === true) {
      const url3 = ENDPOINT + '/schedule/getMedicosProcedimento';
      const payload = {
        empresaId: procedure.empresa_id,
        procedimentoTussId: procedure.procedimento_tuss_id,
      };
      const response = await fetch(url3, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      setMedicos(resp);
    }
  };

  // Obter datas dos médicos
  const fetchMedicosDatas = async () => {
    if (procedure.medico === true) {
      const url4 = ENDPOINT + '/schedule/getMedicosDatas';
      const payload = {
        empresaId: procedure.empresa_id,
        procedimentoTussId: procedure.procedimento_tuss_id,
        medicoAgenda: selecionado,
      };
      const response = await fetch(url4, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      setMedicosDatas(resp);
    }
  };

  // Obter horas dos dias
  const fetchHorasDatas = async () => {
    if (procedure.medico === true) {
      const url5 = ENDPOINT + '/schedule/getHorasDatasMedico';
      const payload = {
        empresaId: procedure.empresa_id,
        procedimentoTussId: procedure.procedimento_tuss_id,
        medicoAgenda: selecionado,
        data: currentDate(date),
      };
      const response = await fetch(url5, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      let inicioMap = {};
      resp.forEach((item) => {
        // Check if 'inicio' value is already in the map
        if (inicioMap.hasOwnProperty(item.inicio)) {
          // If duplicate found, remove one at random
          if (Math.random() < 0.5) {
            // Remove the current item
            delete inicioMap[item.inicio];
          } else {
            // Skip this item and keep the existing one
            return;
          }
        }
        // Add the current item to the map
        inicioMap[item.inicio] = item;
      });
      const resultArray = Object.values(inicioMap).sort((a, b) => {
        // Convert 'inicio' strings to Date objects for comparison
        let inicioA = new Date(`2000-01-01T${a.inicio}`);
        let inicioB = new Date(`2000-01-01T${b.inicio}`);
        // Compare 'inicio' values
        return inicioA - inicioB;
      });
      setDataMedicoHoras(resultArray);
      setLoading(false);
    }
  };



  const fetchHorariosEmpresas = async () => {
    try {
      fetch(`${ENDPOINT}/clients/fetchHorariosEmpresa`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ empresa_id: procedure.empresa_id }),
      }).then(async (resposta) => {
        return resposta.json()
      }).then(async (resposta) => {
        setHorarioEmpresa(resposta[0])
        setHorarioLoading(false)
      })
    } catch (error) {
      console.log('Error pesquisando horario funcionanemtno empresa ', error)
    }
  };

  useEffect(() => {
    fetchDependentes();
    fetchMedicos();
    fetchHorariosEmpresas()
    if (!procedure.medico) {
      setActiveTab(1);
    }
  }, [disclosure]);

  // useEffect para obter as datas quando o usuario selecionar o medico na lista
  useEffect(() => {
    if (medicosDatas.length > 0) {
      setMedicosDatas([]);
    }
    fetchMedicosDatas();
  }, [selecionado]);

  // useEffect para obter os horarios dos dias quando apertar na data
  useEffect(() => {
    setLoading(true);
    fetchHorasDatas();
  }, [date]);

  // Datas para deixar apenas ativadas (quando não houver datas, deixar o array vazio)
  var datasDisponiveis = [];

  // Card Item médico
  const ListaMedicoItem = ({ medico }) => {
    const avaliacao = Math.round(medico.avaliacao_media);
    const handleClick = () => {
      setSelecionado(medico.medico_agenda);
      setDadosMedico({ operador_id: medico.operador_id, split: medico.split, id_recipient: medico.id_recipient });
    };

    return (
      <>
        <Box
          display={'flex'}
          backgroundColor={'#F3F3F3'}
          borderRadius={10}
          w={'100%'}
          padding={2}
          onClick={handleClick}
          borderWidth={1}
          borderColor={selecionado === medico.medico_agenda ? '#529C94' : '#F3F3F3'}
        >
          <Box flex={2}>
            <Avatar
              borderRadius="15px"
              size={'lg'}
              name={medico.nome}
              src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.operador_id + '/foto_profissional.webp'}
            />
          </Box>

          <VStack flex={8} paddingLeft={1}>
            <Text color={'#585858'} w={'100%'} fontWeight={'bold'} fontSize={'0.9em'} textAlign={'left'}>
              {medico.sexo === 'M' ? 'Dr. ' : 'Dra. '}
              {medico.nome}
            </Text>
            <HStack w={'100%'}>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                {avaliacao >= 1 ? <StarIcon color="#EB8B2A" fontSize="1em" mt={-0.3} /> : <StarIcon color="gray.500" fontSize="1em" mt={-0.3} />}
                <Text fontSize="1.em" color="#EB8B2A" align={'center'} fontWeight={600} ml={1}>
                  {avaliacao >= 5 ? '5' : avaliacao}
                </Text>
              </Box>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} color={'#529C94'}>
                <GoBriefcase color="#529C94" />{' '}
                <Text
                  ml={1}
                  fontWeight={'bold'}
                  onClick={() => {
                    navigate('/empresa/medico/curriculo', {
                      state: {
                        medico: {
                          dados: { medico: medico.operador_id, nome: medico.nome, avaliacao_media: medico.avaliacao_media },
                          horarios: {},
                          fromExame: true,
                        },
                      },
                    });
                  }}
                >
                  Ver curriculo
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Box>
        <Divider h={2} />
      </>
    );
  };

  function checaHorarioMeioDia() {
    const now = new Date();
    const hourNow = now.getHours();
    const minuteNow = now.getMinutes();
    const dateCal = new Date(date);

    if (dateCal.getDate() !== now.getDate() || (dateCal.getDate() === now.getDate() && hourNow < 11)) {
      return true;
    } else {
      return false;
    }
  }

  // Calendário com condicao de medico nao
  const TabCalendario = () => {

    if (!horarioLoading) {
      const hoje = startOfDay(new Date());
      const agora = new Date();
      const horaAtual = agora.getHours() + ':' + (agora.getMinutes() < 10 ? '0' : '') + agora.getMinutes();
      const dataAgendamento = new Date(date)
      const dataFormatada = format(new Date(dataAgendamento), "dd/MM/yyyy");

      if (date === null || date === hoje) {
        let horaInicio, horaFim;

        switch (new Date().getDay()) { // Verifica o dia da semana do agendamento
          case 0: // Domingo
            horaInicio = horariosEmpresa?.horario_integral_dom_inicio;
            horaFim = horariosEmpresa?.horario_integral_dom_fim;
            break;
          case 6: // Sábado
            horaInicio = horariosEmpresa?.horario_integral_sab_inicio;
            horaFim = horariosEmpresa?.horario_integral_sab_fim;
            break;
          default: // Dias da semana (segunda a sexta)
            horaInicio = horariosEmpresa?.horario_integral_seg_sex_inicio;
            horaFim = horariosEmpresa?.horario_integral_seg_sex_fim;
            break;
        }

        var tempState = user.cart.itens;
        const indiceItem = user.cart.itens.findIndex((item) => item.procedimento_tuss_id === procedure.procedimento_tuss_id);

        tempState[indiceItem].dia_selecionado = currentDate(dataAgendamento); // Passa a data de agendamento formatada

        if (horaInicio !== null && horaFim !== null && horaInicio !== undefined && horaFim !== undefined) {
          if (horaAtual >= horaFim) {
            // Se o horário de funcionamento já passou hoje, então o dia não está disponível
            setHorarioTurno(false);
          } else {
            // Se não, ficará disponível para agendar
            setHorarioTurno(true);
          }
        } else {
          // Caso seja nulo, não tratei para bloquear -> não sei se existe algum lugar que não informe horário de funcionamento
          setHorarioTurno(true);
        }
      } else {
        setHorarioTurno(true);
      }
    }
    return (
      <>
        <VStack>
          <Calendario
            date={date}
            setDate={setDate}
            setIsSaturday={setIsSaturday}
            datasDisponiveis={datasDisponiveis}
            backgroundColor={backgroundColor}
            empresa={procedure.empresa_id}
            horarios={horariosEmpresa}
          />
          {isByOrder === true && horarioTurno ? (
            <VStack w={'100%'} pr={4} pl={4}>
              {checaHorarioMeioDia() ? (
                <>
                  <Badge
                    id="turno_manha"
                    padding="0.5em 1.4em"
                    fontSize="md"
                    w={'100%'}
                    backgroundColor={turno === 'manha' ? backgroundColor : '#A3A3A3'}
                    borderRadius={10}
                    color={'#FFF'}
                    onClick={() => {
                      setTurno('manha');
                      setHorario('07:30');
                      setHoraDataSelecionadaDisplay('07:30');
                    }}
                  >
                    Manhã
                  </Badge>
                </>
              ) : (
                <></>
              )}

              {!isSaturday ? (
                <>
                  <Badge
                    id="turno_tarde"
                    padding="0.5em 1.4em"
                    fontSize="md"
                    w={'100%'}
                    borderRadius={10}
                    color={'#FFF'}
                    backgroundColor={turno === 'tarde' ? backgroundColor : '#A3A3A3'}
                    onClick={() => {
                      setTurno('tarde');
                      setHorario('12:30');
                      setHoraDataSelecionadaDisplay('12:30');
                    }}
                  >
                    Tarde
                  </Badge>
                </>
              ) : (
                <></>
              )}
            </VStack>
          ) : (
            <></>
          )}
          <Button
            w={'100%'}
            backgroundColor={backgroundColor}
            color={'#FFF'}
            onClick={() => {
              if (empresa && empresa.length < 2) {
                checWithoutMedicoAndContinue();
              } else {
                modalDisclosure.onOpen();
              }
            }}
          >
            Continuar
          </Button>
        </VStack>
      </>
    )
  };

  function updateState() {
    var tempState = user.cart.itens;
    const indiceItem = user.cart.itens.findIndex((item) => item.procedimento_tuss_id === procedure.procedimento_tuss_id);

    tempState[indiceItem].medico_selecionado = selecionado;

    if (procedure.medico && dadosMedico) {
      tempState[indiceItem].medico_id_recipient = dadosMedico.id_recipient;
      tempState[indiceItem].medico_split = dadosMedico.split;
    }

    tempState[indiceItem].dia_selecionado = currentDate(date);
    tempState[indiceItem].hora_selecionada = horaDataSelecionada;
    tempState[indiceItem].hora_selecionada_display = horaDataSelecionadaDisplay;

    tempState[indiceItem].isByOrder = isByOrder;
    tempState[indiceItem].empresa_id = procedure.empresa_id;

    tempState[indiceItem].descricao = procedure.descricao;
    tempState[indiceItem].medico = procedure.medico;
    tempState[indiceItem].bairro = procedure.bairro;
    tempState[indiceItem].estado = procedure.estado;
    tempState[indiceItem].cep = procedure.cep;
    tempState[indiceItem].distancia = procedure.distancia;
    tempState[indiceItem].horario_sab_fim = procedure.horario_sab_fim;
    tempState[indiceItem].horario_sab_inicio = procedure.horario_sab_inicio;
    tempState[indiceItem].horario_seg_sex_fim = procedure.horario_seg_sex_fim;
    tempState[indiceItem].horario_seg_sex_inicio = procedure.horario_seg_sex_inicio;
    tempState[indiceItem].latitude = procedure.latitude;
    tempState[indiceItem].longitude = procedure.longitude;
    tempState[indiceItem].numero = procedure.numero;
    tempState[indiceItem].telefone_01 = procedure.telefone_01;
    tempState[indiceItem].telefone_02 = procedure.telefone_02;
    tempState[indiceItem].empresa = procedure.empresa;
    tempState[indiceItem].preparo_descricao = procedure.preparo_descricao;
    tempState[indiceItem].preparo = procedure.preparo;
    tempState[indiceItem].logradouro = procedure.logradouro;
    tempState[indiceItem].numero = procedure.numero;

    tempState[indiceItem].particular_id = procedure.particular_id;

    tempState[indiceItem].valor_deovita = procedure.valor_deovita;
    tempState[indiceItem].valor_particular = procedure.valor_particular;

    if (isByOrder === true) {
      tempState[indiceItem].horario = horario;
    }

    setUser((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        itens: tempState,
      },
    }));
  }

  function updateStateAll() {
    empresa.forEach((element, index) => {
      if (element.medico === false) {
        var tempState = user.cart.itens;
        const indiceItem = user.cart.itens.findIndex((item) => item.procedimento_tuss_id === element.procedimento_tuss_id);

        tempState[indiceItem].medico_selecionado = selecionado;

        if (procedure.medico && dadosMedico) {
          tempState[indiceItem].medico_id_recipient = dadosMedico.id_recipient;
          tempState[indiceItem].medico_split = dadosMedico.split;
        }

        tempState[indiceItem].dia_selecionado = currentDate(date);
        tempState[indiceItem].hora_selecionada = horaDataSelecionada;
        tempState[indiceItem].hora_selecionada_display = horaDataSelecionadaDisplay;

        tempState[indiceItem].isByOrder = isByOrder;
        tempState[indiceItem].empresa_id = element.empresa_id;

        tempState[indiceItem].descricao = element.descricao;
        tempState[indiceItem].medico = element.medico;
        tempState[indiceItem].bairro = element.bairro;
        tempState[indiceItem].estado = element.estado;
        tempState[indiceItem].cep = element.cep;
        tempState[indiceItem].distancia = element.distancia;
        tempState[indiceItem].horario_sab_fim = element.horario_sab_fim;
        tempState[indiceItem].horario_sab_inicio = element.horario_sab_inicio;
        tempState[indiceItem].horario_seg_sex_fim = element.horario_seg_sex_fim;
        tempState[indiceItem].horario_seg_sex_inicio = element.horario_seg_sex_inicio;
        tempState[indiceItem].latitude = element.latitude;
        tempState[indiceItem].longitude = element.longitude;
        tempState[indiceItem].numero = element.numero;
        tempState[indiceItem].telefone_01 = element.telefone_01;
        tempState[indiceItem].telefone_02 = element.telefone_02;
        tempState[indiceItem].empresa = element.empresa;
        tempState[indiceItem].preparo_descricao = element.preparo_descricao;
        tempState[indiceItem].preparo = element.preparo;
        tempState[indiceItem].logradouro = element.logradouro;
        tempState[indiceItem].numero = element.numero;

        tempState[indiceItem].particular_id = element.particular_id;

        tempState[indiceItem].valor_deovita = element.valor_deovita;
        tempState[indiceItem].valor_particular = element.valor_particular;

        if (isByOrder === true) {
          tempState[indiceItem].horario = horario;
        }

        setUser((prevState) => ({
          ...prevState,
          cart: {
            ...prevState.cart,
            itens: tempState,
          },
        }));
      }
    });
  }
  const checWithoutMedicoAndContinue = () => {
    if (turno === '' || date === null) {
      modalDisclosure.onClose();
      ShowToast('Selecione uma data', 'Verifique o dia e o horário para o procedimento.', 'warning', 4000, true, 'bottom', 'green');
      return;
    } else if (isByOrder === true) {
      modalDisclosure.onClose();
      updateState();
      //navigate(-1);
      disclosure.onClose();
    }
  };
  const checWithoutMedicoAndContinueAll = () => {
    if (turno === '' || date === null) {
      modalDisclosure.onClose();
      ShowToast('Selecione uma data', 'Verifique o dia e o horário para o procedimento.', 'warning', 4000, true, 'bottom', 'green');
      return;
    } else if (isByOrder === true) {
      modalDisclosure.onClose();
      updateStateAll();
      //navigate(-1);
      disclosure.onClose();
    }
  };

  const lockSchedule = async (selecionado, cpf) => {
    const response = await fetch(`${ENDPOINT}/schedule/lockByAgendaExames/${selecionado}/${cpf}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include',
      },
    })
    const result = await response.json()
  }



  const checkMedicosAndContinue = () => {
    if (procedure.medico === true && !selecionado) {
      ShowToast('Aviso', 'Selecione um médico para continuar.', 'warning', 4000, true, 'bottom', 'green');
      return;
    } else if (medicoStep === 2) {
      if (date === null || horaDataSelecionada === null) {
        ShowToast('Selecione uma data', 'Verifique o dia e o horário para o procedimento.', 'warning', 4000, true, 'bottom', 'green');
      } else {
        lockSchedule(horaDataSelecionada, user.cpf)
        updateState();
        //navigate('/exames/empresa', { state: { procedureModalDisclosure: true } });
        disclosure.onClose();
      }
    } else {
      setMedicoStep(2);
    }
  };

  const ListaHorasDatas = () => {
    const handleClick = (agendaExamesId, hour) => {
      setHoraDataSelecionada(agendaExamesId);
      setHoraDataSelecionadaDisplay(hour);
      setIsByOrder(false);
    };


    // obter todas as horas selecionados do carrinho:
    let horasSelecionadas = [];
    if(user.cart){
      user.cart.itens.map((e) => {
        horasSelecionadas.push(e.hora_selecionada)
      })
    }

    return (
      <>
        {loading ? (
          <>
            <VStack w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
              <Loading />
            </VStack>
          </>
        ) : (
          <>
            <Box w={'100%'}>
              <Text w={'100%'} align={'left'} mb={2} fontWeight={'bold'}>
                Selecione um horário disponível
              </Text>
              <List overflow="auto" h={'10.625em'}>
                {dataMedicoHoras.filter(item => !horasSelecionadas.includes(item.agenda_exames_id)).map((e, i) => {
                  return (
                    <>
                      <Box
                        key={i}
                        h={'30px'}
                        bgColor={'#F3F3F3'}
                        display={'flex'}
                        alignItems={'center'}
                        paddingLeft={2}
                        onClick={() => {
                          handleClick(e.agenda_exames_id, formatHour(e.inicio));
                        }}
                        borderRadius={10}
                        borderWidth={1}
                        borderColor={horaDataSelecionada === e.agenda_exames_id ? '#529C94' : '#F3F3F3'}
                      >
                        <Text>{formatHour(e.inicio)}</Text>
                      </Box>
                      <Divider h={'5px'} />
                    </>
                  );
                })}
              </List>
            </Box>
          </>
        )}
      </>
    );
  };

  const TabMedicos = () => {
    if (medicoStep === 1) {
      return (
        <>
          {loading ? (
            <Loading />
          ) : (
            <List overflow="auto" h={'100%'}>
              {medicos && medicos.length > 0 ? (
                medicos && medicos.map((e, i) => <ListaMedicoItem medico={e} key={i} />)
              ) : (
                <Text>Sem agendas dispníveis para agendamento via app no momento.</Text>
              )}
            </List>
          )}
        </>
      );
    } else if (medicoStep === 2) {
      if (medicosDatas.length > 0) {
        const arrayDeDatas = medicosDatas.map((item) => new Date(item.data));
        datasDisponiveis = [...datasDisponiveis, ...arrayDeDatas];
      }

      return (
        <>
          <Text w={'100%'} align={'left'} fontWeight={'bold'}>
            Selecione uma Data disponível
          </Text>
          <Calendario date={date} setDate={setDate} datasDisponiveis={datasDisponiveis} backgroundColor={backgroundColor} />
          {date ? (
            <VStack w={'100%'}>
              <ListaHorasDatas />
            </VStack>
          ) : (
            <></>
          )}
        </>
      );
    }
  };

  // Tabs
  const TabsMedicoCalendario = () => {
    return (
      <Tabs align="center" w="100%" colorScheme="teal" index={activeTab} onChange={(index) => setActiveTab(index)}>
        <TabList w="100%">
          <Tab isDisabled={!procedure.medico} fontSize="14px">
            AGENDA MÉDICO
          </Tab>
          <Tab fontSize="14px" isDisabled={procedure.medico}>
            AGENDA CALENDÁRIO
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Center>
              <VStack paddingLeft={9} paddingRight={9} h={'60vh'}>
                <TabMedicos />
                <Box w={'100%'}>
                  {procedure.medico ? (
                    <Button w={'100%'} backgroundColor={backgroundColor} color={'#FFF'} onClick={checkMedicosAndContinue}>
                      Continuar
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>
              </VStack>
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <TabCalendario />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };
  return (
    <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
      <Modal isOpen={modalDisclosure.isOpen} isCentered onClose={modalDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="#808080" mt="5" size="md">
                Deseja utilizar esse mesmo turno e data para os outros procedimentos?{' '}
              </Heading>
            </Center>
            <chakra.h4 mt="0.2em" color="#808080" textAlign="justify" fontSize="0.8em">
              Ainda será necessário escolher um horario para procedimentos que dependam de uma agenda médica.
            </chakra.h4>
          </ModalBody>
          <Center>
            <Stack direction="column" spacing={4} align="center" w="100%">
              <Button
                bgColor={backgroundColor}
                mt="5%"
                _hover="none"
                color="white"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                autofocus="true"
                onClick={checWithoutMedicoAndContinueAll}
              >
                Utilizar o mesmo horário
              </Button>
              <Button variant="ghost" _hover="none" w="88%" size="lg" borderRadius="0.6em" onClick={checWithoutMedicoAndContinue}>
                Ignorar
              </Button>
            </Stack>
          </Center>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={20} width="100%" h="80%" borderTopWidth={1} boxShadow="dark-lg">
        <DrawerBody pb="10%">
          <VStack h={'80vh'}>
            <Box paddingLeft={9} paddingRight={9}>
              <Flex direction="row" justifyContent="space-between" w="4.em" h="50px" backgroundColor="#F3F3F3" padding={2} mt={4} borderRadius="5px">
                <Box w="40%" display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Text fontSize={14} color="#585858">
                    Exame Selecionado:
                  </Text>
                </Box>

                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Text fontWeight={'bold'} fontSize={12}>
                    {procedure.nome}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <TabsMedicoCalendario />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerAgendamentoDependente;
