import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader'
import React, { useContext, useRef } from 'react'
import { VStack, useDisclosure, chakra, Center, HStack, Flex, Heading, Spacer, Image, ButtonGroup, Button, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import PetCadastrado from '../../../../images/petCadastrado.svg'
function SucessoCadastroPet() {
    const { setPage, setUser, user } = useContext(AccountContext);
    setPage('Cadastro Pet');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = useRef(null);
    const navigate = useNavigate();

    return (
        <>
            <ChildHeader />
            <Flex
                minW="210px"
                maxW="340px"
                mx="auto"
                overflow="hidden"
                padding="1em"
                px={5}
                pt={5}
            >
                <VStack alignItems="left" spacing={2.5} w={'100%'} mt={'6em'}>
                    <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} h={'10em'}>
                        <Image boxSize="4em" objectFit="cover" src={PetCadastrado} alt="" color={'#585858'} mt={'1em'} ml={'-13em'}/>
                        <Heading fontSize="20" color={'#EB8B2A'} display="flex" alignItems="left" padding={'1em'} textAlign={'left'} >
                            O novo Pet foi adicionado com sucesso!
                        </Heading>
                    </VStack>

                    <Flex style={{ minHeight: window.innerWidth >= 412 ? '45vh' : '35vh' }} flexDirection="column" >
                        <Box flex="1" />
                        <VStack w="100%" align="left" bg="white">
                            <HStack align="left" spacing={4} onClick={() => navigate('/perfil/assinaturas')}>
                                <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2.5em'}>
                                    Visualizar Pet
                                </Button>
                            </HStack>
                        </VStack>
                    </Flex>
                </VStack>
            </Flex>
        </>
    )
}

export default SucessoCadastroPet
