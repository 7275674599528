import { Badge, Box, chakra, HStack } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineOpenInBrowser } from 'react-icons/md';

function info(props) {
  const { data } = props;
  const name = data.nome.split(' ')[1] ? data.nome.split(' ')[0] + ' ' + data.nome.split(' ')[1] : data.nome.split(' ')[0];
  const date = data.data_cadastro ? data.data_cadastro.split('T')[0] : 'Sem data';
  let totalGanho = 0;
  const dateObject = new Date(data.data_compra);
  const changeDate = new Date('2024-06-01');
  console.log(data);
  if (dateObject > changeDate) {
    if (data.plano_id === 55) {
      if (data.valor_pago === '299.00') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 7 : 0;
      }
      if (data.valor_pago === '29.90') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 67 : 0;
      }
    }
    if (data.plano_id === 29) {
      if (data.valor_pago === '399.00') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 7 : 0;
      }
      if (data.valor_pago === '39.90') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 67 : 0;
      }
    }
  } else {
    if (data.plano_id === 55) {
      if (data.valor_pago === '99.00') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 7.21 : 0;
      }
      if (data.valor_pago === '9.90') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 66.66 : 0;
      }
      if (data.valor_pago === '19.90') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 33.16 : 0;
      }
    }
    if (data.plano_id === 29) {
      if (data.valor_pago === '299.00') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 7.33 : 0;
      }
      if (data.valor_pago === '29.90') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 66.88 : 0;
      }
      if (data.valor_pago === '39.90') {
        totalGanho = data.valor_pago ? (parseFloat(data.valor_pago.replace(',', '.')) / 100) * 50.12 : 0;
      }
    }
  }
  return (
    <>
      <HStack>
        <chakra.p fontSize="sm" color="gray.500">
          {date.split('-').reverse().join('/')}
        </chakra.p>
      </HStack>
      <HStack w="100%">
        <Box>
          <MdOutlineOpenInBrowser color="#0EAA8B" />
        </Box>
        <Box align="center" w="50%">
          {name}
        </Box>
        <Badge borderRadius="15%" backgroundColor="green.200">
          {data.plano.toLowerCase()}
        </Badge>
        <Box textColor="#0EAA8B" align="center" w="30%">
          {totalGanho.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </Box>
      </HStack>
    </>
  );
}

export default info;
