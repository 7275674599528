import React, { useContext, useState } from 'react';
import MainChildHeader from '../Dashboard/Main/MainChildHeader';
import {
  VStack,
  chakra,
  Button,
  Text,
  Image,
  WrapItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  useDisclosure,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import BottomNavBar from '../BottomNavBar';
import png from '../../images/assets/icons/Component-1.png';
import png2 from '../../images/assets/icons/Component-2.png';
import png3 from '../../images/assets/icons/Component-3.png';
import png4 from '../../images/assets/icons/Component-4.png';
import png5 from '../../images/assets/icons/Component-5.png';
import png6 from '../../images/assets/icons/Component-6.png';
import png7 from '../../images/assets/icons/Component-9.png';
import TrocarPlano from '../../images/TrocarPlano.svg';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sair, setSair] = useState(false);
  const { setPage, setUser, user } = useContext(AccountContext);
  setPage('Perfil');
  const handleLogout = async () => {
    try {
      await fetch(`${ENDPOINT}/auth/logout`, {
        method: 'GET',
        credentials: 'include',
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  let abaAssinaturas = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) abaAssinaturas = true;
  return (
    <>
      <Modal isOpen={sair} isCentered>
        <ModalOverlay />
        <ModalContent height="250px" width="300px" border-radius="16px">
          <ModalHeader></ModalHeader>

          <ModalBody>
            <Image ml="110" src={png6} size="lg"></Image>
            <Heading size="md" ml="3" mt="15">
              Deseja realmente sair do sistema?
            </Heading>
            <VStack></VStack>
          </ModalBody>

          <VStack ml="4">
            <ModalFooter></ModalFooter>

            <ModalFooter>
              <Stack direction="row" ml="-4" mt="-5" spacing={5} align="center">
                <Button
                  colorScheme="teal"
                  color="#EB8B2A"
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    setUser((prevState) => ({ loggedIn: false, build: prevState.build ? prevState.build : null }));
                    handleLogout();
                    navigate('/dashboard');
                  }}
                >
                  Sim
                </Button>
                <Button
                  backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
                  color="#FFF"
                  variant="solid"
                  size="lg"
                  onClick={() => {
                    setSair(false);
                  }}
                >
                  Não
                </Button>
              </Stack>
            </ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
      <MainChildHeader />
      <VStack
        align="center"
        justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="10vh"
        ml="9"
        px={10}
        spacing={8}
        mb="25%"
      >
        <Flex>
          <chakra.h1 fontWeight="bold" mt="1em" ml="-12" fontSize="xl">
            Editar informações
          </chakra.h1>
        </Flex>

        {/*{user.titular === true && user.id_contrato > 0 && (
          <Flex>
            <Text colorScheme="teal" variant="outline" fontWeight="bold" onClick={() => navigate('/perfil/dependentes')}>
              Adicionar/editar dependentes
              <Image boxSize="28px" objectFit="cover" src={png7} alt="" ml="260" mt="-7" />
              <Image boxSize="40px" objectFit="cover" src={png2} alt="" ml="-14" mt="-8" />
            </Text>
          </Flex>
        )} */}
        {abaAssinaturas && (
          <Flex>
            <Text colorScheme="teal" variant="outline" fontWeight="bold" onClick={() => navigate('/perfil/assinaturas')}>
              Assinaturas
              <Image boxSize="28px" objectFit="cover" src={png7} alt="" ml="260" mt="-7" />
              <Image boxSize="40px" objectFit="cover" src={TrocarPlano} alt="" ml="-14" mt="-8" />
            </Text>
          </Flex>
        )}
        <Flex>
          <Text colorScheme="teal" variant="outline" fontWeight="bold" onClick={() => navigate('/perfil/dados-pessoais')}>
            Dados pessoais
            <Image boxSize="28px" objectFit="cover" src={png7} alt="" ml="260" mt="-7" />
            <Image boxSize="40px" objectFit="cover" src={png} alt="" ml="-14" mt="-7" />
          </Text>
        </Flex>

        <Flex>
          <Text colorScheme="teal" variant="outline" fontWeight="bold" onClick={() => navigate('/perfil/dados-bancarios')}>
            Dados Bancários
            <Image boxSize="28px" objectFit="cover" src={png7} alt="" ml="260" mt="-7" />
            <Image boxSize="40px" objectFit="cover" src={png3} alt="" ml="-14" mt="-7" />
          </Text>
        </Flex>

        <Flex>
          <Text colorScheme="teal" variant="outline" fontWeight="bold" onClick={() => navigate('/perfil/endereco')}>
            Endereço
            <Image boxSize="28px" objectFit="cover" src={png7} alt="" ml="260" mt="-7" />
            <Image boxSize="40px" objectFit="cover" src={png4} alt="" ml="-14" mt="-7" />
          </Text>
        </Flex>

        <Flex>
          <Text colorScheme="teal" variant="outline" fontWeight="bold" onClick={() => navigate('/recuperacao/trocarsenha')}>
            Senha
            <Image boxSize="28px" objectFit="cover" src={png7} alt="" ml="260" mt="-8" />
            <Image boxSize="40px" objectFit="cover" src={png5} alt="" ml="-14" mt="-7" />
          </Text>
        </Flex>

        <Flex mb="5%">
          <Text
            colorScheme="teal"
            variant="outline"
            fontWeight="bold"
            onClick={() => {
              setSair(true);
            }}
          >
            Sair
            <Image boxSize="28px" objectFit="cover" src={png7} alt="" ml="260" mt="-9" />
            <Image boxSize="40px" objectFit="cover" src={png6} alt="" ml="-14" mt="-6" />
          </Text>
        </Flex>
      </VStack>

      <Flex></Flex>
      <WrapItem mt="75">
        <BottomNavBar name="perfil" />
      </WrapItem>
    </>
  );
}

export default Main;
