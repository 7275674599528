import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import validaCPF from 'components/Utils/Functions/validaCPF';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, VStack, chakra, ButtonGroup, Text, Box, Grid, useMediaQuery } from '@chakra-ui/react';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import TextField from '../../TextField';
import BottomNavBar from '../../BottomNavBar';
import { ReactComponent as Chip } from '../../../images/card-chip.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

export const useCartaoViewModel = () => {
  const [loading, setLoading] = useState(null);
  const [active, setActive] = useState(false);
  const { setPage, user, setUser } = useContext(AccountContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const card = state.card;
  const [aviso, setAviso] = useState(null);
  const paciente_id = user.id_cliente_deovita;
  setPage('Dados Bancários');

  var initCardValues = { cvv: '', name: '', cardNumber: '', expiration_date: '', holder_document: '' };
  if (state.op !== 'new') {
    initCardValues = {
      name: card.holder_name.toUpperCase(),
      cardNumber: card.first_digits + 'xxxxxx' + card.last_digits,
      cvv: 'xxx',
      expiration_date: card.expiration_date,
      holder_document: card.holder_document,
    };
  }
  const [data, setData] = useState(initCardValues);

  const createCreditCard = async (values = {}) => {
    try {
      const res = await fetch(`${ENDPOINT}/pagamento/criar-cartao`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          cpf: onlyNumbers(user.cpf),
          number: onlyNumbers(values.cardNumber),
          holder_name: values.name,
          holder_document: onlyNumbers(values.holder_document),
          exp_month: values.expiration_date.split('/')[0],
          exp_year: values.expiration_date.split('/')[1],
          cvv: values.cvv,
        }),
      });

      if (!res || !res.ok || res.status >= 400) {
        throw new Error('Não foi possível cadastrar o no momento.');
      }

      return res;
    } catch (error) {
      console.error(error);
      throw new Error('Não foi possível cadastrar o no momento.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (data) => {
    setLoading(true);
    setAviso(null);

    console.log('Data before createCreditCard:', data);

    createCreditCard(data)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setLoading(false);
        if (response?.id) {
          fetch(`${ENDPOINT}/client/add_credit_card`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              paciente_id: paciente_id,
              first_digits: response.first_six_digits,
              last_digits: response.last_four_digits,
              brand: response.brand,
              holder_name: response.holder_name,
              holder_document: onlyNumbers(data.holder_document),
              expiration_date: onlyNumbers(`${response.exp_month}/${response.exp_year.toString().slice(-2)}`),
              card_id_pagarme: response.id,
              fingerprint_card_pagarme: '', // v5 do pagarme nao requer mais fingerprint
              default: user.cards.length > 0 ? false : true,
            }),
          })
            .catch((error) => {
              setAviso('Não foi possível atualizar os dados no momento.');
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                setAviso('Não foi possível atualizar os dados no momento.');
                return;
              }
              return res.json();
            })
            .then((data) => {
              setLoading(false);
              if (!data) return;
              if (data.success) {
                let dataNewCard = {
                  paciente_cartao_credito_pagarme_id: data.id,
                  holder_name: response.holder_name,
                  holder_document: response.holder_document,
                  brand: response.brand,
                  first_digits: response.first_six_digits,
                  last_digits: response.last_four_digits,
                  expiration_date: onlyNumbers(`${response.exp_month}/${response.exp_year.toString().slice(-2)}`),
                  card_id_pagarme: response.id,
                  default: user.cards.length > 0 ? false : true,
                };
                setUser((prevState) => ({
                  ...prevState,
                  cards: [...prevState.cards, dataNewCard],
                }));
                setAviso('Atualizado com sucesso');
                navigate(-1);
                setActive(false);
              } else {
                if (data.msg === 'cartão já cadastrado') {
                  setAviso(data.msg);
                } else {
                  setAviso('Não foi possível atualizar os dados no momento.');
                }
              }
            });
        } else {
          setAviso('Cartão inválido');
        }
      })
      .catch((err) => {
        setLoading(false);
        setAviso('Não foi possível atualizar o cartão no momento. Verifique os dados inseridos ou tente mais tarde');
        console.error(err);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    fetch(`${ENDPOINT}/client/rm_credit_card/${card.paciente_cartao_credito_pagarme_id}`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .catch((error) => {
        setAviso('Não foi possível atualizar os dados no momento.');
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setAviso('Não foi possível atualizar os dados no momento.');
          return;
        }
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        if (!data) return;
        if (data.success) {
          let newCards = user.cards.filter((creditCard) => creditCard.paciente_cartao_credito_pagarme_id !== card.paciente_cartao_credito_pagarme_id);
          setUser((prevState) => ({ ...prevState, cards: newCards }));
          setAviso('Atualizado com sucesso');
          setActive(false);
          navigate(-1);
        } else {
          setAviso('Não foi possível atualizar os dados no momento.');
        }
      });
  };

  const validate = (values) => {
    try {
      console.log('validate', values);
      const errors = {}; // deve retornar esse objeto vazio quando nao houver erros

      if (!values.cardNumber?.trim()) {
        errors.cardNumber = 'Campo obrigatório';
      }

      if (!values.cvv?.trim()) {
        errors.cvv = 'Campo obrigatório';
      }

      if (!values.expiration_date?.trim()) {
        errors.expiration_date = 'Campo obrigatório';
      }

      if (!values.name?.trim()) {
        errors.name = 'Campo obrigatório';
      }

      if (!values.holder_document?.trim()) {
        errors.holder_document = 'Campo obrigatório';
      } else if (!validaCPF(onlyNumbers(values.holder_document.trim()))) {
        errors.holder_document = 'Número de CPF inválido';
      }

      console.log('validation errors', errors);
      return errors;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    data,
    handleDelete,
    loading,
    active,
    aviso,
    validate,
    handleSubmit,
    user,
    card,
    state,
  };
};

const Cartao = () => {
  const [lg] = useMediaQuery('(min-width: 425px)');
  const { data, handleDelete, loading, active, aviso, validate, handleSubmit, user, card, state } = useCartaoViewModel();

  return (
    <>
      <ChildHeader />
      <Formik initialValues={data} validate={validate} validateOnChange={false} validateOnBlur={true} onSubmit={(data) => handleSubmit(data)}>
        {({ values, isValid, dirty }) => (
          <VStack as={Form} paddingBottom="5%" spacing="1rem" align="start" p={8}>
            <Box
              bg={isValid && dirty ? 'linear-gradient(180deg, #5AB2AD 0%, #529C94 100%)' : 'linear-gradient(180deg, #F0F0F0 0%, #D7D7D7 100%)'}
              width={'100%'}
              borderRadius={20}
              style={{ aspectRatio: '86/53' }}
              position={'relative'}
              transition={'color 0.2s'}
              color={isValid && dirty ? 'white' : '#585858'}
            >
              <Box position={'absolute'} top={6} left={6}>
                <Chip />
              </Box>
              <Box position={'absolute'} bottom={6} left={6}>
                <Text textTransform={'uppercase'} fontWeight={'bold'} mb={2}>
                  {values.name ? values.name.toUpperCase() : 'Nome'}
                </Text>
                <Text>{values.cardNumber || 'xxxx xxxx xxxx xxxx'}</Text>
              </Box>
            </Box>

            <chakra.p color={aviso === 'Atualizado com sucesso' ? 'green' : 'red'}>{aviso}</chakra.p>

            <Field name="name">
              {({ field }) => (
                <TextField
                  label={'Titular do cartão'}
                  type="text"
                  placeholder="Digite o nome impresso no cartão"
                  isDisabled={state.op !== 'new'}
                  required
                  {...field}
                />
              )}
            </Field>

            <Field name="cardNumber">
              {({ field }) => (
                <TextField
                  label={'Número do Cartão'}
                  type="tel"
                  placeholder="xxxx xxxx xxxx xxxx"
                  mask="9999 9999 9999 9999"
                  maskChar={null}
                  isDisabled={state.op !== 'new'}
                  required
                  {...field}
                  value={state.op !== 'new' ? 'xxxx xxxx xxxx xxxx' : field.value}
                />
              )}
            </Field>

            <Grid gridTemplateColumns={lg ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'} gap={4} w={'100%'}>
              <Field name="expiration_date">
                {({ field }) => (
                  <TextField
                    label={'Data de validade'}
                    placeholder="xx/xx"
                    type="tel"
                    mask="99/99"
                    maskChar={null}
                    isDisabled={state.op !== 'new'}
                    required
                    {...field}
                    value={state.op !== 'new' ? 'xx/xx' : field.value}
                  />
                )}
              </Field>

              <Field name="cvv">
                {({ field }) => (
                  <TextField
                    label={'CVV'}
                    type="tel"
                    placeholder="xxx"
                    isDisabled={state.op !== 'new'}
                    mask="999"
                    maskChar={null}
                    required
                    {...field}
                  />
                )}
              </Field>
            </Grid>

            <Field name="holder_document">
              {({ field }) => (
                <TextField
                  label={'CPF'}
                  type="tel"
                  placeholder="xxx.xxx.xxx-xx"
                  mask="999.999.999-99"
                  maskChar={null}
                  isDisabled={state.op !== 'new'}
                  required
                  {...field}
                  value={state.op !== 'new' ? 'xxx.xxx.xxx-xx' : field.value}
                />
              )}
            </Field>

            <ButtonGroup width="100%" paddingBottom="20%">
              {state.op !== 'new' ? (
                <VStack w={{ base: '100%', md: '500px' }}>
                  <Button
                    colorScheme="red"
                    w="100%"
                    isDisabled={user.cards.length <= 1 || card.default}
                    isLoading={loading}
                    loadingText="Carregando"
                    onClick={handleDelete}
                  >
                    Excluir
                  </Button>
                  {(user.cards.length <= 1 || card.default) && (
                    <Text fontSize="xs" color="red">
                      Não é permitido excluir todos os cartões ou o cartão preferencial
                    </Text>
                  )}
                </VStack>
              ) : (
                <Button
                  colorScheme="teal"
                  type="submit"
                  w="100%"
                  isLoading={loading}
                  loadingText="Carregando"
                  //isDisabled={!active && !(isValid && dirty)}
                >
                  Salvar alterações
                </Button>
              )}
            </ButtonGroup>
          </VStack>
        )}
      </Formik>
      <BottomNavBar />
    </>
  );
};

export default Cartao;
