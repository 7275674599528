import { Search2Icon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  ButtonGroup,
  HStack,
  VStack,
  Text,
  Center,
  useDisclosure,
  InputGroup,
  Input,
  InputRightElement,
  useToast,
  TableContainer,
  Table,
  Tr,
  Td,
  Flex,
  Image,
  Img,
  ModalFooter,
  Stack,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import { Formik, Form } from 'formik';
import Loading from 'components/Loading';
import _ from 'lodash';
import { MdAddCircleOutline } from 'react-icons/md';
import HandClick from '../../../../images/assets/icons/hand_click.svg';
import TodosExame from '../../../../images/assets/categorias/todos/todos_exame.svg';
import TodosOdonto from '../../../../images/assets/categorias/todos/todos_odonto.svg';
import '../../../Dashboard/css/lista-datas.css';
import soundex from 'components/Utils/Functions/soundex';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Agendamento() {
  const navigate = useNavigate();
  const { user, setPage, setUser, toastInadimplente } = useContext(AccountContext);
  const [procedures, setProcedures] = useState([]);
  const [otherProcedures, setOthers] = useState([]);
  const { state } = useLocation();
  const [pag, setPag] = useState(0);
  const { lista_grupos } = state;
  const [subgroup, setSubgroup] = useState([]);
  const [cart, setCart] = useState(user.cart ? user.cart.itens : []);
  const [contratar, setContratar] = useState(false);
  const [valorParcial, setValorParcial] = useState(user.cart ? user.cart.valor : 0);
  const [loading, setLoading] = useState(true);
  const [textSearch, setTextSearch] = useState(null);

  const { titular, id_contrato } = user;
  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
      ? '#EB8B2A'
      : titular === true && id_contrato > 0
      ? '#EB8B2A'
      : '#529C94';

  const disclosure = useDisclosure();

  const toast = useToast({
    title: 'Aviso',
    description: 'Não foi possível completar sua solicitação no momento, tente novamente mais tarde',
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'top',
    colorScheme: 'red',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  setPage(state.grupo_nome);

  const fetchPosts = async () => {
    const url = ENDPOINT + '/schedule/getProcedures/' + state.grupo + '/0/' + state.tipo;
    console.log('url', url);
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setProcedures(postsData);
    let uniqueSubgroups;
    if (state.tipo) {
      uniqueSubgroups = _.uniqBy(postsData, 'grupo');
    } else {
      uniqueSubgroups = _.uniqBy(postsData, 'subgrupo_nome');
    }
    uniqueSubgroups.unshift({ subgrupo_nome: 'TODOS', grupo: 'TODOS' });
    const index = uniqueSubgroups.findIndex((subgroup) => subgroup.subgrupo_nome === 'LABORATORIAL');
    if (index !== -1) {
      const subgroup = uniqueSubgroups.splice(index, 1)[0];
      uniqueSubgroups.splice(1, 0, subgroup);
    }
    setSubgroup(uniqueSubgroups);
    setLoading(false);
  };

  const saveCart = async (cart) => {
    const url = `${ENDPOINT}/cart/`;
    const payload = {
      cpf: user.username,
      cart: cart,
    };
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(payload),
    });

    const postsData = await response.json();
  };

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }

  const dataAtual = new Date();
  useEffect(() => {
    if (
      user.loggedIn &&
      !user.titular &&
      (user?.id_contrato_dependent?.paciente_contrato_id === null ||
        user?.id_contrato_dependent?.paciente_contrato_id === 0 ||
        user?.id_contrato_dependent?.paciente_contrato_id === undefined)
    ) {
      setContratar(true);
      return;
    }

    if (user.loggedIn && user.titular && (user.id_contrato === 0 || user.id_contrato === null || user.id_contrato === undefined)) {
      setContratar(true);
      return;
    }
  }, []);

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (cart.length) {
      setUser((prevState) => ({
        ...prevState,
        cart: {
          itens: cart,
        },
      }));
    }
  }, [cart]);

  useEffect(() => {
    if (user.cart && user.cart.itens.length < cart.length) {
      setCart(user.cart.itens);
    }
    if (!user.cart) {
      setCart([]);
    }
  }, [user.cart]);

  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (_.isEqual(list[i], obj)) {
        return true;
      }
    }

    return false;
  };

  const search = async (text) => {
    setLoading(true);
    setTextSearch(text);
    if (text === '' || text === null) {
      await fetchPosts();
      setLoading(false);
      return;
    }
    try {
      const url = `${ENDPOINT}/schedule/searchProcedures?text=${text}&group=${state.grupo}&tipo=${
        state.grupo_nome === 'Exames' ? 'EXAME' : 'undefined'
      }`;
      const responseData = await fetch(url);
      const response = await responseData.json();
      if (response.success) {
        const proceduresData = response.data;
        setProcedures([]);
        setOthers([]);
        setProcedures(proceduresData);
      } else {
        toast();
      }
    } catch (err) {
      toast();
    }
    setLoading(false);
  };

  const useSubgroup = subgroup.map((item, index) => {
    const nomeSubgrupo = item ? (state.tipo ? item.grupo : item.subgrupo_nome) : '';
    return item.subgrupo_nome != 'OUTROS' ? (
      <Td id={`td${index}`} borderRadius="15px" p="0.5em" fontSize="xl" textAlign="center" class="margin-td">
        <HStack
          id={`date${index}`}
          backgroundColor="#e7e7e7"
          shadow="base"
          borderRadius="15px"
          height="5em"
          w="100%"
          style={pag === index ? { border: '1px solid #5AB2ADB3' } : {}}
          onClick={() => {
            setPag(index);
          }}
        >
          <Box w="100%" borderRadius="5px">
            <Center w="100%" p="0.15em" borderRadius="5px">
              <Image
                borderRadius="5px"
                name={item ? (state.tipo ? item.grupo : item.subgrupo_nome) : ''}
                src={
                  item
                    ? item.subgrupo_nome === 'TODOS'
                      ? state.grupo_nome === 'Exame'
                        ? TodosExame
                        : TodosOdonto
                      : state.tipo
                      ? `${process.env.REACT_APP_URL_GRUPO}/` + item.grupo_id + '.png'
                      : `${process.env.REACT_APP_URL_SUBGRUPO}/` + item.subgrupo_id + '.png'
                    : ''
                }
                fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
                alt={item ? (state.tipo ? item.subgrupo_nome : item.grupo) : ''}
              />
            </Center>
          </Box>
        </HStack>
        <Text
          fontSize="0.4em"
          textAlign="center"
          color="#808080"
          whiteSpace={nomeSubgrupo.length > 20 ? 'normal' : 'nowrap'}
          overflow="hidden"
          textOverflow="ellipsis"
          height={nomeSubgrupo.length > 20 ? '2em' : '2em'}
          lineHeight="1"
          marginTop="0.5em"
        >
          {nomeSubgrupo}
        </Text>
      </Td>
    ) : (
      <></>
    );
  });

  const useProcedures = procedures.map((item, index) => {
    return item && subgroup[pag] ? (
      (subgroup[pag].subgrupo_nome === 'TODOS' && cart.indexOf(item) === -1 && containsObject(item, cart) === false) ||
      (state.tipo
        ? item.grupo === subgroup[pag].grupo && cart.indexOf(item) === -1 && containsObject(item, cart) === false
        : item.subgrupo_nome === subgroup[pag].subgrupo_nome && cart.indexOf(item) === -1 && containsObject(item, cart) === false) ? (
        <Tr h="5em">
          <Flex key={index} borderRadius="10px" shadow="md" mt="1em" width="95%" backgroundColor="#F0F0F0">
            <VStack ml="1em" align="start" w="100%" mt="1em">
              <Text noOfLines={4} minH={55} color="#585858" fontWeight="bold" fontSize="0.8em">
                {item.nome} {item.medico ? '(necessário agenda médica disponível)' : ''}
              </Text>
            </VStack>
            <VStack w={20} bgColor={backgroundColor} borderRightRadius="10px">
              <Center h={100}>
                <MdAddCircleOutline
                  size="2em"
                  color="white"
                  onClick={() => {
                    setCart([...cart, item]);
                  }}
                />
              </Center>
            </VStack>
          </Flex>
        </Tr>
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  });

  const useProceduresWithoutSubgroup = procedures.map((item, index) => {
    return item && cart.indexOf(item) === -1 && containsObject(item, cart) === false ? (
      <Tr h="5em">
        <Flex borderRadius="10px" shadow="md" mt="1em" width="95%" backgroundColor="#F0F0F0">
          <VStack ml="1em" align="start" w="100%">
            <Text noOfLines={3} minH={55}>
              {item.nome}
            </Text>
          </VStack>
          <VStack w={20} bgColor="#5AB2AD" borderRightRadius="10px">
            <Center h={100}>
              <MdAddCircleOutline
                size="2em"
                color="white"
                onClick={() => {
                  setCart([...cart, item]);
                }}
              />
            </Center>
          </VStack>
        </Flex>
      </Tr>
    ) : (
      <></>
    );
  });

  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="#808080" mt="5" size="md">
                Deseja realizar outros procedimentos?{' '}
              </Heading>
            </Center>
            <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
              Antes de prosseguir, adicione todos os procedimentos que deseja realizar.
            </chakra.h4>
          </ModalBody>
          <Center>
            <Stack direction="column" spacing={4} align="center">
              <Button
                bgColor={backgroundColor}
                mt="5%"
                _hover="none"
                color="white"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                autofocus="true"
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                Adicionar mais procedimentos
              </Button>
              <Button
                bgColor={backgroundColor}
                color="white"
                _hover="none"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                onClick={() => {
                  setUser((prevState) => ({
                    ...prevState,
                    cart: {
                      itens: cart,
                    },
                  }));
                  navigate('/exames/empresa', {
                    state: { cart, grupo: state.grupo },
                  });
                }}
              >
                Já selecionei todos
              </Button>
            </Stack>
          </Center>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Formik initialValues={{ searchBar: '' }} validateOnChange={false} validateOnBlur={false}>
        {(propsFormik) => {
          const { handleChange, values } = propsFormik;
          return (
            <VStack as={Form} m="5%" spacing={3} align="start">
              <HStack width="100%">
                <InputGroup>
                  <Input
                    name="searchBar"
                    placeholder="Buscar"
                    focusBorderColor="teal"
                    backgroundColor="white"
                    shadow="lg"
                    borderRadius="50px"
                    onChange={handleChange}
                    autocomplete="off"
                    onKeyPress={(event) => {
                      let textSearch = event.target.value;
                      if (event.key === 'Enter') {
                        search(soundex(textSearch));
                      }
                    }}
                  />
                  <InputRightElement width="15%">
                    <Button
                      backgroundColor="#EB8B2A"
                      borderRadius="50px"
                      width="100%"
                      children={<Search2Icon color="white" />}
                      onClick={() => {
                        let textSearch = values.searchBar;
                        search(soundex(textSearch));
                      }}
                      isLoading={loading}
                    ></Button>{' '}
                  </InputRightElement>
                </InputGroup>
              </HStack>{' '}
              <chakra.h1 fontWeight="bold">Escolha os procedimentos: </chakra.h1>
              {loading ? (
                <VStack w="100%">
                  <VStack w="53.7%" m="auto" justify="top">
                    <Loading />
                  </VStack>
                </VStack>
              ) : textSearch === null || textSearch === undefined || textSearch === '' ? (
                <>
                  <TableContainer minWidth="100%">
                    <Table variant="simple" size="lg" class="table-agendamento-exame">
                      <Tr>{useSubgroup}</Tr>
                    </Table>
                  </TableContainer>
                  {contratar ? (
                    <>
                      {' '}
                      <Box w="100%">
                        <Center>
                          {' '}
                          <Button
                            color="white"
                            bgColor="#5AB2AD"
                            style={{
                              boxShadow: 'inset 0px -4px 4px rgba(0, 0, 0, 0.25)',
                            }}
                            w="100%"
                            onClick={() => {
                              if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
                                toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                                return;
                              }

                              setUser((prevState) => ({
                                ...prevState,
                                cart: {
                                  itens: cart,
                                },
                              }));
                              navigate('/cadastro/plano', {
                                state: {
                                  client_id: user.id_cliente_deovita,
                                  name: user.name,
                                  cpf: user.cpf,
                                },
                              });
                            }}
                          >
                            <Img src={HandClick} mr="2%" />
                            Seja um assinante e economize
                          </Button>
                        </Center>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                  <TableContainer minWidth="100%" height="20em" overflowY="auto" whiteSpace="none">
                    <Table variant="simple" size="lg" minW="100%" id="table-procedures">
                      <Tr></Tr>
                      {useProcedures}
                    </Table>
                  </TableContainer>

                  <ButtonGroup pb="25%" width="100%">
                    <Button
                      width="100%"
                      bgColor={backgroundColor}
                      color="white"
                      disabled={cart.length > 0 ? false : true}
                      onClick={() => {
                        disclosure.onOpen();
                      }}
                    >
                      Continuar
                    </Button>
                  </ButtonGroup>
                </>
              ) : (
                <>
                  <TableContainer minWidth="100%" height="23em" overflowY="auto" whiteSpace="none">
                    <Table variant="simple" size="lg" minW="100%">
                      {useProceduresWithoutSubgroup}
                    </Table>
                  </TableContainer>
                  <ButtonGroup pb="25%" width="100%">
                    <Button
                      width="100%"
                      bgColor="#5AB2AD"
                      color="white"
                      disabled={cart.length > 0 ? false : true}
                      onClick={() => {
                        setUser((prevState) => ({
                          ...prevState,
                          cart: {
                            itens: cart,
                          },
                        }));

                        navigate('/exames/empresa', {
                          state: { cart, grupo: state.grupo },
                        });
                      }}
                    >
                      Continuar
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </VStack>
          );
        }}
      </Formik>
    </>
  );
}

export default Agendamento;
