import { Button, chakra, Box, Flex, Image, Text, Img, useDisclosure } from '@chakra-ui/react';
import React, { useContext } from 'react';
import LogoDashboard from '../../../images/logo_deovita_blank.svg';
import '../css/main.css';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import Carrinho from '../../../images/carrinho.svg';
import DrawerCarrinho from './Carrinho/DrawerCarrinho';
import CircleCart from 'components/AreaLivre/CircleCart';
import { MdCircleNotifications, MdKeyboardArrowLeft } from 'react-icons/md';

function ChildHeader() {
  const navigate = useNavigate();
  const { user, page } = useContext(AccountContext);
  const { id_contrato, descricao_plano, titular } = user;

  const backgroundColor = (titular === true && id_contrato === 0) ? "#529C94" :
    (titular === false && id_contrato === 0) ? "#EB8B2A" :
      (titular === true && id_contrato > 0) ? "#EB8B2A" : "#529C94";

  let message = id_contrato && descricao_plano ? `Assinante plano ${String(descricao_plano).charAt(0).toUpperCase() + String(descricao_plano).slice(1).toLowerCase()}` : "Seja um assinante!";
  if (titular === false) message = "Assinante dependente"
  if (user.situacao_contrato === 'P') message = "Contrato em análise";

  const disclosureCarrinho = useDisclosure();
  let firstName = user.name.split(' ');
  let url = window.location.href;
  

  return (
    <>
      <DrawerCarrinho disclosure={disclosureCarrinho} />
      <chakra.header
        w="full"
        py={0}
        shadow="md"
        backgroundColor={backgroundColor}
        minHeight="136px"
        borderBottomRadius="30px"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto" ml={{sm: "40px", base: "10px"}}>
          <Flex direction="column">
            <chakra.h2 fontSize="xl" fontWeight="bold" mt="43px" color="white">
              Olá, {firstName[0]}
            </chakra.h2>
            <Button
              fontSize="12px"
              minW="136px"
              h="20px"
              color={backgroundColor}
              borderRadius={20}
              onClick={() => {
                if (message === "Seja um assinante!") {
                  navigate('/cadastro/plano')
                }
              }}
            >
              {message}
            </Button>
          </Flex>
          <Box display={{ base: 'inline-flex' }}>
            <Image w="70px" h="55px" me={{sm: "30px", base: "10px"}} position="absolute" right="0.02rem" top="43px" src={LogoDashboard} alt="teste" />
          </Box>
        </Flex>

        <Flex direction='row' justifyContent='space-between' alignItems='center' mt='10px'>
          <Box ml={8} onClick={() => {navigate(-1)}} >
            <MdKeyboardArrowLeft color='#FFF' size={30} />
          </Box>

          <Text
            fontSize="xl"
            fontWeight="bold"
            textColor="white"
            textAlign="center"
            width="100%"
            zIndex="1"
          >{page}</Text>

          {url.includes('/exames/agendamento') || url.includes('/exames/empresa') ? (
            <>
              <Img
                backgroundColor='#FFF'
                src={Carrinho}
                borderRadius={15}
                w="45px"
                h="25px"
                padding={1}
                onClick={() => disclosureCarrinho.onOpen()}
                color="orange"
                zIndex="101"
                mr={7}
              />

              {user.cart && user.cart.itens ? <CircleCart quantidade={user.cart.itens.length} /> : null}
            </>
          ) : (
            <Box mr={7} onClick={() => { alert('show notification') }} visibility="hidden">
              <MdCircleNotifications color='#FFF' size={26} />
            </Box>
          )}
        </Flex>
      </chakra.header>
    </>
  );
}

export default ChildHeader;
