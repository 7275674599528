import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Heading, VStack, Text, ButtonGroup } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import ChildHeader from '../../../Dashboard/Main/ChildHeader';
import BottomNavBar from '../../../BottomNavBar';
import { AccountContext } from '../../../AccountContext';

function Excluir() {
  const { state } = useLocation();
  const {
    user: { taxa_remocao_dependente, id_contrato },
    setPage,
  } = useContext(AccountContext);
  setPage('Exluir dependente');
  const navigate = useNavigate();

  function continuar() {
    let data = {
      id: state.id,
      parcelsActive: true,
      paciente_contrato_id: id_contrato,
      valor: taxa_remocao_dependente,
      expiration_pix_in_min: 5,
      navig_to: state?.navigate_to ? state?.navigate_to : '/perfil/dependentes/processar-exclusao',
      item: { id: state.id, taxa_remocao_dependente: taxa_remocao_dependente },
      orientacoes: '',
      service: 'remove_dependent',
      confim: true,
    };
 
    navigate('/pagamentos', { state: data });
  }

  function Alerta(formikProps) {
    return (
      <VStack
        as={Form}
        w={{ base: '90%', md: '500px' }}
        m="auto"
        justify="center"
        mt="5%"
        align="center"
        bg="gray.100"
        p="5"
        borderRadius="10"
        spacing="5"
      >
        <Heading color="orange">Aviso!</Heading>
        <Text align="center">
          Para efetivar a remoção de um dependente é necessário pagar uma taxa de R$ {taxa_remocao_dependente}. Deseja prosseguir?
        </Text>
        <ButtonGroup>
          <Button colorScheme="teal" onClick={() => continuar()}>
            Sim
          </Button>
          <Button colorScheme="red" onClick={() => navigate('/perfil/assinaturas')}>
            Não
          </Button>
        </ButtonGroup>
      </VStack>
    );
  }

  return (
    <>
      <ChildHeader />
      <Formik validateOnChange={false} validateOnBlur={false}>
        {(props) => Alerta(props)}
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default Excluir;