import { Box, Flex, HStack, Heading, Text, VStack, useMediaQuery, chakra, Spacer, ButtonGroup, Button, Image } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext } from 'react';
import EmojiCoracoes from '../../../images/EmojiCoracoes.svg';
import AvisoPendencias from '../../../images/AvisoPendencias.svg';
import { useNavigate } from 'react-router-dom';

function ExcluirContaDependente() {
  const { setPage, setUser, user } = useContext(AccountContext);
  setPage('Excluir conta');
  const navigate = useNavigate();
  return (
    <>
      <ChildHeader />
      <Box>
        <Flex minW="210px" maxW="340px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
          <VStack w={'100%'} mt={'2em'}>
            <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} p={'1em'} w={'100%'}>
              <Image boxSize="30px" objectFit="cover" src={AvisoPendencias} mr={'15em'}></Image>
              <VStack w={'100%'}>
                <Heading fontSize={'19px'} color={'#585858'} fontWeight={'bold'}>
                  Tem certeza que deseja excluir sua conta?
                </Heading>
              </VStack>

              <chakra.span color={'#585858'} fontSize="16" textAlign="left">
                Se excluir sua conta, não terá mais acesso aos benefícios de um dependente DeoVita. Além disso, o titular só poderá adicionar outro
                dependente após 90 dias da remoção de um dependente.
              </chakra.span>

              <HStack w={'100%'} justify="space-between" alignItems="center">
                <ButtonGroup pt="1rem" pb="15%" sx={{ width: '100%' }}>
                  <Button name="btn" color="#909090" bg={'#F3F3F3'} type="submit" w={'50%'} h={'2.5em'} onClick={() => navigate('/perfil/assinaturas/conta-excluida')}>
                    Quero excluir
                  </Button>
                  <Button name="btn" color="#F3F3F3" bg={'#EB8B2A'} type="submit" w={'50%'} h={'2.5em'} onClick={() => navigate('/perfil/assinaturas')}>
                    Manter Conta
                  </Button>
                </ButtonGroup>
              </HStack>
            </VStack>
          </VStack>
        </Flex>
      </Box>
    </>
  );
}

export default ExcluirContaDependente;
