const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const loadPlans = async () => {
  const resposta = await fetch(`${ENDPOINT}/plans`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await resposta.json().then((res) => {
    return res.map((item) => ({
      id: item.forma_pagamento_id,
      nome: item.nome_impressao,
      qtd_dependentes: item.parcelas - 1,
      beneficios: item.beneficios_plano,
      parcelas: item.valor_parcelas,
      recomendado: item.recomendado,
    }));
  });

  return data;
};

const getPagamentos = async (idPlano, frequencia) => {
  const resposta = await fetch(`${ENDPOINT}/plans/${idPlano}/pagamentos/${frequencia}`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });

  return await resposta.json();
};

export { getPagamentos, loadPlans };
