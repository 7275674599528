/**
 * Verifica o horário da variável time
 * Usa um regex para extrair as horas, minutos e sgundos
 * Compara os tempos de startTime e midDayTime
 * se for maior, retorna tarde. Se menor, retorna manhã
 * @returns String
 */
function verifyPeriodOfTheDay(time) {
    var startTime = time;
    var midDayTime = "12:00:00";
    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
    if (startTime && startTime !== null && startTime !== undefined && startTime) {
        if (parseInt(midDayTime.replace(regExp, "$1$2$3")) < parseInt(startTime.replace(regExp, "$1$2$3"))) {
            return "tarde";
        }
        else {
            return "manhã";
        }
    } else {
        return ""
    }
}
export default verifyPeriodOfTheDay;