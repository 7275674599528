import { useCallback, useEffect, useState } from "react";

/**
 * Get the current url params
 */
export const useURLParams = () => {

  const [urlParams, setUrlParams] = useState({});


  const loadParamsFromUrl = useCallback((url) => {
    const urlObject = new URL(url);
    const params = Object.fromEntries(new URLSearchParams(urlObject.search))
    setUrlParams(params);
  }, []);
  
  useEffect(() => {
    loadParamsFromUrl(window.location.href);
  }, [loadParamsFromUrl]);

  return {
    urlParams
  };
};
