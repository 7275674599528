import { HStack, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import Spin from '../images/spin.svg';
import './loading.css';

function Loading() {
  return (
    <>
      <VStack
        w={{
          base: '50%',
          md: '500px',
        }}
        m="70% auto"
        justify="center"
      >
        <Img size="sm" margin="auto" src={Spin} className="rotate" />
      </VStack>
    </>
  );
}

export default Loading;
