import { useContext, useState } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import validaCPF from 'components/Utils/Functions/validaCPF';
import { ReactComponent as InfoCircle } from '../../images/info-circle.svg';
import { ReactComponent as InfoEnel } from '../../images/image-info-enel.svg';
import { ReactComponent as CheckCircle } from '../../images/check-circle.svg';
import { ReactComponent as SadCircle } from '../../images/sad-circle.svg';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const GEOLOCATION_KEY = process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY;

const validationSchemaFormEnel = Yup.object({
  titular: Yup.string().trim().required('Campo obrigatório'),
  cpf: Yup.string()
    .trim()
    .required('Campo obrigatório')
    .test('valida_cpf', 'CPF inválido', (cpf) => validaCPF(cpf)),
  unidade: Yup.number().typeError('Unidade de consumo deve conter apenas números').required('Campo obrigatório'),
  isTitular: Yup.bool().oneOf([true], 'Somente o titular pode realizar cadastro desta forma de pagamento. Selecione a caixa acima para prosseguir.'),
});

export default function FormularioEnel(props) {
  const { valor } = props;
  const { state } = useLocation();
  const { user, setUser } = useContext(AccountContext);
  const navigate = useNavigate();
  const { isOpen: isOpenInfoEnel, onOpen: onOpenInfoEnel, onClose: onCloseInfoEnel } = useDisclosure();
  const [successEnel, setSuccessEnel] = useState(null);
  const [loadingContinuar, setLoadingContinuar] = useState(false);

  return (
    <>
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={validationSchemaFormEnel}
        initialValues={{ titular: user.name ?? '', cpf: user.cpf ?? '', unidade: '', isTitular: false }}
        onSubmit={async (values, actions) => {
          const { success } = await fetch(`${ENDPOINT}/enel/consultar/${values.unidade}`, {
            method: 'POST',
            body: JSON.stringify(values),
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
          }).then((response) => response.json());

          setSuccessEnel(success);

          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, dirty, values }) => {
          return (
            <>
              <Form>
                <Field name="titular">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      mb={4}
                      isInvalid={form.errors.titular && form.touched.titular}
                      isReadOnly={user.name}
                      isDisabled={user.name}
                    >
                      <FormLabel>Titular da conta</FormLabel>
                      <Input {...field} placeholder="Digite o nome do titular da conta" />
                      <FormErrorMessage>{form.errors.titular}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="cpf">
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.cpf && form.touched.cpf} mb={4} isRequired isReadOnly={user.cpf} isDisabled={user.cpf}>
                      <FormLabel>CPF do titular</FormLabel>
                      <Input as={InputMask} {...field} placeholder="xxx.xxx.xxx-xx" mask="999.999.999-99" type="tel" />
                      <FormErrorMessage>{form.errors.cpf}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="unidade">
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.unidade && form.touched.unidade} mb={4} isRequired>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <FormLabel>Unidade de consumo </FormLabel>

                        <InfoCircle width={16} onClick={onOpenInfoEnel}></InfoCircle>
                      </Box>
                      <Input {...field} placeholder="xxxxxxxxxx" type="tel" />
                      <FormErrorMessage>{form.errors.unidade}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field type="checkbox" name="isTitular">
                  {({ field, form }) => {
                    return (
                      <FormControl isInvalid={form.errors.isTitular && form.touched.isTitular} mb={4} isRequired>
                        <Checkbox colorScheme="teal" {...field} isChecked={field.checked}>
                          Sou titular da conta
                        </Checkbox>
                        <FormErrorMessage>{form.errors.isTitular}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>

                <Box w={'100%'} border={'2px solid #E4E4E4'} borderRadius={20} p={4} color={'#585858'} mt={8}>
                  <Text fontSize={'lg'} fontWeight={'bold'} mb={2}>
                    Resumo da compra:
                  </Text>
                  <HStack justifyContent={'space-between'}>
                    <Box>
                      <Text color={'#FF8000'} fontWeight={'bold'}>
                        Plano {state.nome_plano}
                      </Text>
                      <Text>Período: {state.frequenciaText} </Text>
                    </Box>
                    <Text fontWeight={'bold'}>{Number(valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                  </HStack>
                </Box>
                <Button isFullWidth mt={2} colorScheme="teal" isLoading={isSubmitting} disabled={!(isValid && dirty)} type="submit">
                  Continuar
                </Button>
              </Form>
              <Modal isOpen={isSubmitting} isCentered>
                <ModalOverlay />
                <ModalContent m={8} bg="#F3F3F3" rounded={20}>
                  <ModalBody p={8}>
                    <Center gap={10} flexDirection={'column'}>
                      <Spinner thickness="4px" emptyColor="gray.200" color="#5AB2AD" size="xl" speed="1s" />
                      <Text>
                        Estamos carregando as <br />
                        informações, aguarde...
                      </Text>
                    </Center>
                  </ModalBody>
                </ModalContent>
              </Modal>
              <Modal isOpen={successEnel !== null} isCentered>
                <ModalOverlay />
                <ModalContent m={8} bg="#F3F3F3" rounded={10}>
                  <ModalBody p={8} color={'#585858'} textAlign={'center'}>
                    <Center gap={4} flexDirection={'column'}>
                      {successEnel === true ? (
                        <>
                          <CheckCircle></CheckCircle>
                          <Text fontWeight={'bold'}>Apto para ser faturado!</Text>
                          <Text>Unidade de consumo está disponível para ser usada como forma de pagamento.</Text>
                        </>
                      ) : (
                        <>
                          <SadCircle />
                          <Text fontWeight={'bold'}>
                            Vish...
                            <br /> Algo de errado aconteceu
                          </Text>
                          <Text>Verifique os dados enviados e tente novamente mais tarde.</Text>
                        </>
                      )}
                    </Center>
                  </ModalBody>
                  <ModalFooter>
                    {successEnel === true ? (
                      <Button
                        isFullWidth
                        colorScheme="teal"
                        disabled={loadingContinuar}
                        isLoading={loadingContinuar}
                        onClick={async () => {
                          setLoadingContinuar(true);

                          let { latitude, longitude } = user.coordenadas_cidade;

                          if (!(latitude && longitude)) {
                            const response = await fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GEOLOCATION_KEY}`, {
                              method: 'POST',
                            });

                            const { location } = await response.json();
                            latitude = `${location.lat}`;
                            longitude = `${location.lng}`;
                          }

                          const [{ success, data }] = await fetch(`${ENDPOINT}/contracts`, {
                            method: 'POST',
                            body: JSON.stringify([
                              {
                                paciente_id: user.id_cliente_deovita,
                                plano_id: state.id_plano,
                                forma_pgto: 'enel',
                                frequencia: state.frequencia,
                                valor: valor,
                                vendedor_id: user.voucher ? user.voucher.vendedor_id : null,
                                codigo_enel: values.unidade,
                                latitude,
                                longitude,
                              },
                            ]),
                            credentials: 'include',
                            headers: { 'Content-Type': 'application/json' },
                          })
                            .then((response) => response.json())
                            .catch((err) => {
                              console.error(err);
                              setSuccessEnel(false);
                            })
                            .finally(() => {
                              setLoadingContinuar(false);
                            });

                          if (success) {
                            setUser((prevState) => ({
                              ...prevState,
                              //id_contrato: data.fct_criar_contratos_parcelas,
                              situacao_contrato: 'P',
                            }));
                            navigate('/verificacao-enel', { state: { idContrato: data.fct_criar_contratos_parcelas } });
                          } else {
                            setSuccessEnel(false);
                          }
                        }}
                      >
                        Continuar
                      </Button>
                    ) : (
                      <Button isFullWidth colorScheme="teal" onClick={() => setSuccessEnel(null)}>
                        Tentar novamente
                      </Button>
                    )}
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          );
        }}
      </Formik>
      <Modal isOpen={isOpenInfoEnel} onClose={onCloseInfoEnel} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent m={8} bg="#F3F3F3" rounded={10} maxW={'90vw'}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody color={'#585858'}>
            <Text fontWeight={'bold'} mb={2}>
              O número da Unidade de Consumo está localizado na fatura da sua conta de luz.
            </Text>
            <Text mb={2}>
              Logo no início da sua fatura, você encontra no lado direito o "Nº DO CLIENTE". Este número é a unidade de consumo do seu contrato.
            </Text>
            <InfoEnel style={{ width: '100%', height: 'min-content' }} />
          </ModalBody>

          <ModalFooter>
            <Button isFullWidth colorScheme="teal" onClick={onCloseInfoEnel}>
              Entendi
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
