import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, VStack } from '@chakra-ui/react';
import { React, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import MainChildHeader from '../Dashboard/Main/MainChildHeader';

export default function Error() {
  const { rota } = useParams();
  const navigate = useNavigate();
  const { user, page, setPage } = useContext(AccountContext);
  if (page === 'Titulo da Pagina' || page === '' || !page) {
    setPage('');
  }

  var msg = 'Não foi possível concluir sua solicitação nesse momento';
  var navigateTo = '/dashboard';
  switch (rota) {
    case 'update_adress':
      msg = 'Não foi possível atualizar seu endereço';
      break;
    case 'atualiza_dados':
      msg = 'Não foi possível atualizar seus dados';
      break;
    case 'agendamento':
      msg = 'Não foi possível realizar seu agendamento';
      break;
    case 'recuperacao':
      msg = 'Não foi possível recuperar sua senha';
      break;
    case 'excluir_dependente':
      msg = 'Não foi possível excluir o dependente no momento';
      navigateTo = '/perfil/dependentes';
      break;
    case 'cadastro_plano':
      msg = 'Algo deu errado ao buscar os dados';
      navigateTo = '/';
      break;
    case 'updatebank':
      msg =
        'Favor, é preciso atualizar seus dados bancários para realizar transferências. Clique no botão abaixo para atualizar seus dados bancários.';
      navigateTo = '/empreendedor/dados-bancarios';
      break;
    case 'agendaindisponivel':
      msg = 'Ops! não foi possivel concluir seu agendamento. A agenda escolhida não está mais disponivel.';
      navigateTo = '-1';
      break;
    default:
  }

  return (
    <>
      {user.loggedIn ? <MainChildHeader /> : <NoUserHeader />}
      <VStack w={{ base: '90%', md: '500px' }} m="auto" justify="top" h="100vh" spacing="1rem" marginTop="2">
        <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Algo deu errado!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <p>{msg}</p>
            <Button onClick={() => navigate(navigateTo)}>OK</Button>
          </AlertDescription>
        </Alert>
      </VStack>
    </>
  );
}
