import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  Image,
  Modal,
  ModalBody,
  chakra,
  ModalCloseButton,
  Stack,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  HStack,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';

import Familia from '../../../../../../images/FamiliaWarning.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ModalAvisoPsicologo(props) {
  const navigate = useNavigate();
  const disclosure = props.disclosure;
  const { pacienteEscolhido, nascimento, typeList, especialidade, idClientClinica, pacienteId } = props;

  async function validaMenorPsicologo() {
    try {
      const postsData = await fetch(`${ENDPOINT}/clients/salvarAceiteContrato`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paciente_id: pacienteId, tipo: 'menor_idade_acompanhado_psicologo' }),
      });
      if (postsData.status === 200) {
        navigate('/medico-agora/especialista/lista', {
          state: {
            pacienteEscolhido: pacienteEscolhido,
            nascimento: nascimento,
            typeList: typeList,
            especialidade: especialidade,
            idClientClinica,
          },
        });
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [telefonePequeno] = useMediaQuery('(max-height: 592px)');
  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={'5%'} borderRadius="0.8em">
          <ModalBody>
            <Center>
              <Image src={Familia} boxSize={'60px'} mt={'1em'} />
            </Center>
            <Center>
              <Heading textAlign="center" color="#333333" mt="5" size="md" mb={'1em'}>
                Atenção!
              </Heading>
            </Center>
            <VStack bg={'#E5E5E5'} p={'1'} borderRadius={'0.8em'}>
              <chakra.h4 mt="0.8em" color="#333333" textAlign="left" fontSize="0.8em">
                Ao realizar uma consulta com o psicólogo para um dependente menor de idade, é indispensável que um responsável maior acompanhe o
                paciente durante a primeira consulta.
              </chakra.h4>
              <chakra.h4 mt="0.8em" color="#333333" textAlign="left" fontSize="0.8em">
                A presença do responsável é necessária para que o psicólogo possa fornecer todas as orientações adequadas e garantir o melhor cuidado
                ao paciente.
              </chakra.h4>
            </VStack>
            <Center mt={'1em'} mb={'1em'}>
              <Button
                bg={'orange'}
                color={'white'}
                h={'2.5em'}
                borderRadius={'0.8em'}
                onClick={() => {
                  validaMenorPsicologo();
                }}
              >
                Paciente está acompanhado!
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalAvisoPsicologo;
