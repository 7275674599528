import { useContext, useEffect } from "react";
import requestTimeout from "../../../Utils/Functions/requestTimeout";
import { AccountContext } from "../../../AccountContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading from "../../../LoadingWithHeader";

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const ProcessarCompra = () => {
    const { user, setUser, setPage } = useContext(AccountContext);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(false);
    const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;

    setPage("Processando compra");

    function handleError() {
        setErro(true);
        setLoading(false);
    }

    function gravar() {
        let datas = [
            {
                paciente_contrato_id: user.id_contrato,
                adicionaisIds: state.adicionaisIds,
                valor: state.valor,
                forma_pgto: state.forma_pgto,
                taxa_adesao: false,
                plano_id: user.id_plano,
                id_trasacao_gatway: state.transaction_id,
                classe: "adesao adicional",
            },
        ];
        console.log(datas);
        const requestOptions = {
            signal: requestTimeout(timeOutInSeconds).signal,
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(datas),
        };

        fetch(`${ENDPOINT}/extras/byContractId`, requestOptions)
            .catch((err) => {
                handleError();
                return;
            })
            .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                    handleError();
                    return;
                }
                return res.json();
            })
            .then((data) => {
                console.log(data);
                if (!data) return;
                if (data.success) {
                    setLoading(false);
                    setUser((prevUser) => {
                        return {
                            ...prevUser,
                            adicionais: data.data,
                            popup_adicionais: false,
                        };
                    });
                } else {
                    handleError();
                }
            });
    }

    useEffect(() => {
        gravar();
    }, []);

    return loading ? <Loading /> : erro ? navigate("/alerts/erro/default") : navigate("/alerts/sucesso/default");
};

export default ProcessarCompra;
