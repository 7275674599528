import {
  Button,
  ButtonGroup,
  Heading,
  chakra,
  VStack,
  HStack,
  PinInput,
  PinInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Form, Formik } from 'formik';
import { React, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import NoUserHeader from '../../AreaLivre/NoUserHeader';
import { useContext } from 'react';
import { AccountContext } from '../../AccountContext';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function GeraCodigo() {
  const [error, setError] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const { state } = useLocation();

  console.log(state)

  const mustUpdatePhone = state.mustUpdatePhone ? true : false;
  const { setPage } = useContext(AccountContext);
  const formatCpf = (str) => str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sair, setSair] = useState(false);
  const [counter, setCounter] = useState(30);
  const [showText, setShowText] = useState(false);

  setPage('Recuperação');
  const navigate = useNavigate();
  let firstName = state.name.split(' ');
  let cpf = formatCpf(state.cpf);
  const url = `${ENDPOINT}/whatsapp/recupera_senha`;
  const payload = {
    cpf: cpf,
    numero: state.phone,
    nome: state.name ? state.name : 'CLIENTE',
  };
  const fetchPosts = async () => {
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });
    const postsData = await response.json();
  };

  useEffect(() => {
    fetchPosts();
    contar();
    setDisableButton((disableButton) => true);
  }, []);
  const [pin, setPin] = useState('');
  const handleChange = (pin) => {
    setPin(pin);
  };

  async function updatePhone(vals) {
    return await fetch(`${ENDPOINT}/client/update_user_phone`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(vals),
    })
      .catch()
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError('Erro na Rede');
          return res.json();
        }
        if (res.status === 200) {
          setError('');
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.success === false) {
          setError('Falha na atualização');
          return false;
        }
        if (data.success === true) {
          setError('');
          return true;
        }
      });
  }

  const contar = () => {
    let aux = 30;
    var countdown = setInterval(function () {
      aux--;
      setCounter(aux);
      if (aux === 0) {
        clearInterval(countdown);
        setShowText(false);
        setDisableButton((disableButton) => false);
        setCounter(30);
      }
    }, 1000);
  };

  return (
    <>
      <Modal isOpen={sair} isCentered>
        <ModalOverlay />
        <ModalContent height="300px" width="320px" border-radius="16px">
          <ModalHeader></ModalHeader>
          <ModalBody>
            <CheckCircleIcon ml="100" color="green" boxSize={20} />
            <Text size="lg" ml="3" mt="15" textAlign="justify">
              Um novo código foi enviado para o número cadastrado
            </Text>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter>
              <Stack direction="row" ml="-4" mt="-5" spacing={5} align="center">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  size="lg"
                  onClick={() => {
                    setSair(false);
                  }}
                >
                  Ok
                </Button>
              </Stack>
            </ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
      <NoUserHeader />
      <Formik
        initialValues={{ codigo: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          const vals = {
            codigo: pin,
            cpf: cpf,
          };
          actions.resetForm();
          fetch(`${ENDPOINT}/auth/recovery`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(vals),
          })
            .catch((err) => {
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                setError('Erro na Rede');
                return res.json();
              }
              if (res.status === 200) {
                setError('');
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              if (data.success === false) {
                setError('Codigo Incorreto');
                return;
              }
              if (data.success === true) {
                setError('');
                if (mustUpdatePhone) {
                  let vals = {
                    whatsapp: state.phone,
                    id_cliente_deovita: state.paciente_id,
                  };
                  updatePhone(vals).then((res) => (res === true ? navigate('/recuperacao/senha', { state: { cpf: state.cpf } }) : ''));
                } else {
                  navigate('/recuperacao/senha', { state: { cpf: state.cpf, nome: firstName[0] } });
                }
              }
            });
        }}
      >
        <VStack
          as={Form}
          w={{
            base: '90%',
            md: '500px',
          }}
          m="auto"
          justify="top"
          spacing="1rem"
        >
          <Heading marginTop="5%" fontSize="1em">
            Olá, {firstName[0]} enviamos um código via whatsapp para o número de final {state.phone && state.phone.slice(-2)}.
          </Heading>
          <chakra.p color="red">{error}</chakra.p>
          <HStack>
            <PinInput  size="md" name="codigo" value={pin} onChange={handleChange} otp>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>

          <div id="Timer"> </div>

          <ButtonGroup pt="4rem" sx={{ width: '100%' }}>
            <Button
              colorScheme="teal"
              type="submit"
              w={{
                base: '100%',
              }}
            >
              Enviar
            </Button>
          </ButtonGroup>
          <ButtonGroup pt="0.5rem" sx={{ width: '100%' }}>
            <Button
              w={{
                base: '100%',
              }}
              disabled={disableButton}
              isLoading={disableButton}
              loadingText={'Aguarde' + ' ' + counter + ' ' + 'segundos'}
              onClick={() => {
                setDisableButton((disableButton) => true);
                fetchPosts();
                setShowText(true);
                contar();
                setSair(true);
              }}
            >
              Gerar um novo código
            </Button>
          </ButtonGroup>
          {mustUpdatePhone ? (
            ''
          ) : (
            <Button
              variant="link"
              colorScheme="teal"
              size="xs"
              onClick={() => navigate('/recuperacao/security-questions', { state: { name: state.name, paciente_id: state.paciente_id, cpf: state.cpf } })}
            >
              Outra opção para redefinir a senha
            </Button>
          )}

          <Modal />
        </VStack>
      </Formik>
    </>
  );
}

export default GeraCodigo;
