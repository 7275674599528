import { chakra, Box, Img, LinkBox, Text, VStack, Image, Progress, useMediaQuery, Center, Button, Flex } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import PetsImg from '../../../../../images/assets/telemedicina/pets-white.png';
import especialistas from '../../../../../images/doctor-badge.svg';
import { ReactComponent as InfoCircle } from '../../../../../images/info-circle.svg';
import DesistenciaModal from 'components/Telemedicina/DesistenciaModal';
import ChildHeader from '../../ChildHeader';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Fila() {
  const [md] = useMediaQuery('(min-width: 375px)');

  const { user, setPage } = useContext(AccountContext);
  setPage('Telemedicina');
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [queued, setQueued] = useState(false);
  const [medico, setMedico] = useState('');
  const [paciente, setPaciente] = useState('');
  const [agenda, setAgenda] = useState('');
  const [position, setPosition] = useState(false);
  const today = new Date().toISOString().substring(0, 10);
  const [pausedFetchRdy, setPausedFetchRdy] = useState(false);
  const intervalRef = useRef();
  const [reconnect, setReconnect] = useState(false);
  const { state } = useLocation();
  const [faturado, setFaturado] = useState(!user?.allowsTelemedicina?.paga_telemedicina || state.faturado || state.pago);
  const [error, setError] = useState(false);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const url = ENDPOINT + '/schedule/appointment_pet';
  const payload = {
    cpf: onlyNumbers(user.cpf),
    clientId: user.id_cliente_deovita,
    cart: {
      convenio_id: 252,
      dia_previsao: 0,
      grupo: 'ENDOSCOPIA',
      materiais: '',
      nome: 'ENDOSCOPIA',
      procedimento_convenio_id: 6178,
      procedimento_tuss_id: 173059,
      valor: '0,00',
      faturado: state?.faturado ?? true,
    },
    turno: 'manhã',
    valor: 0,
    metadataCoparticipacao: state?.metadataCoparticipacao,
    empresa: {
      ativo: true,
      bairro: 'Centro',
      cep: '63500-065',
      descricao:
        'Iniciamos nossas atividades em 2006, tendo como base fundamental a garantia de um bom atendimento e qualidade. Com união entre profissionais qualificados e tecnologia avançada, conquistamos a confiança de nossos clientes, trazendo resultados com agilidade e precisão.     Além da sede em Iguatu/CE, hoje estamos presentes em Fortaleza, região metropolitana, e em diversas cidades do estado do Ceará.',
      empresa: 'ADOLFO LUTZ',
      empresa_id: 1,
      endereco: 'Rua Deputado Adail Barreto',
      estado: 'CEARA',
      grupo: 'ENDOSCOPIA',
      horario_sab_fim: '13:00',
      horario_sab_inicio: '06:00',
      horario_seg_sex_fim: '18:00',
      horario_seg_sex_inicio: '06:00',
      municipio: 'IGUATU',
      numero: '54',
      ordem: '1',
      telefone_01: '0800 280 2133',
      telefone_02: '88 981189898',
    },
    data: today,
    credito: state?.credito,
  };
  console.log('state', state);
  const fetchPosts = async () => {
    try {
      if (queued === false) {
        setQueued(true);
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        setSuccess(postsData.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (success === false && state?.status !== 'telemed_paga') {
      fetchPosts();
    } else if (state?.status === 'telemed_paga') {
      setSuccess(true);
    }
  }, [success, state?.status]);

  const fetchRdy = useCallback(async () => {
    setError(null);

    try {
      const response = await fetch(ENDPOINT + `/pet/schedule/${user.username}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      console.log(`/pet/schedule/${user.username}`, postsData);
      setFaturado(postsData.faturado);
      setReconnect(false);
      setPosition(postsData.position);
      setPaciente(postsData.paciente_id);
      setAgenda(postsData.agenda_exames_id);
      if (postsData.medico_ready === true) {
        setMedico(postsData.medico_id);
        //setLoading(false);
        console.log(
          JSON.stringify({
            service: true,
            openInBrowser: false,
            url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`,
          })
        );
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            service: true,
            openInBrowser: false,
            url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`,
          })
        );

        /* const isAndroid = user.device === 'android' ? true : false;
        if (isAndroid) {
          window.location = `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`;
        } else {
          setLoading(false);
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              service: true,
              url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`
            })
          );
        } */

        const highestId = window.setTimeout(() => {
          for (let i = highestId; i >= 0; i--) {
            window.clearInterval(i);
          }
        }, 0);
      }
    } catch (error) {
      setError('Problema na rede, verifique se seu dispositivo está conectado a internet');
      console.log(error);
    }
  }, [user.username]);

  // useEffect(() => {
  //   if (success === true) {
  //     setInterval(function () {
  //       fetchRdy();
  //     }, 10000);
  //   }
  // }, [success]);

  useEffect(() => {
    if (success === true && !pausedFetchRdy) {
      intervalRef.current = setInterval(fetchRdy, 10000);
    }

    return () => clearInterval(intervalRef.current);
  }, [success, pausedFetchRdy, fetchRdy]);

  return isLoading ? (
    <>
      <Center flexDirection={'column'} minH="100vh" background="#EB8B2A" p={md ? 8 : 4} pb={20}>
        <Box width={'100%'} mb={4}>
          <Image src={BackButton} onClick={() => navigate(-1)} />
        </Box>
        <Box borderRadius={20} backgroundColor="white" textAlign="center" p={md ? 8 : 4} fontSize="xs">
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Image src={especialistas} h={md ? '5em' : '3em'} w={md ? '5em' : '3em'}></Image>
            {faturado || !user.allowsTelemedicina.paga_telemedicina ? (
              <>
                <Text color="#585858" fontWeight="bold" fontSize={md ? 'lg' : 'sm'} mt={4}>
                  Agora é só aguardar!
                </Text>
                <Text>
                  {error != false && error != undefined ? (
                    'Falha na conexão'
                  ) : position !== null ? (
                    position == false || position == undefined ? (
                      'conectando...'
                    ) : (
                      <Box p={4}>
                        <Text>Você é o </Text>
                        <Text fontSize={'md'} fontWeight="bold" color={'#EB8B2A'}>{`${position}º da fila`}</Text>
                      </Box>
                    )
                  ) : (
                    'iniciando atendimento...'
                  )}
                </Text>
              </>
            ) : (
              <>
                <Text color={'#EB8B2A'} fontWeight="bold" fontSize={md ? 'lg' : 'sm'} mt={4}>
                  Aguardando confirmação de pagamento
                </Text>
                <Progress colorScheme="orange" isIndeterminate size="xs" width={'75%'} rounded={'lg'} my={4} />
              </>
            )}
          </Box>

          <Box bgColor={'#DFDFDF'} color={'#585858'} p={4} textAlign={'left'} borderRadius={10} mt={md ? 10 : 4}>
            <InfoCircle style={{ height: '1rem' }} />
            <Text mt={4}>
              É importante lembrar que o profissional de saúde pode demorar um pouco mais durante o atendimento. Ou seja, seu lugar na fila e o tempo
              estimado pode aumentar ou diminuir.
            </Text>
          </Box>

          {faturado && position !== false && (
            <DesistenciaModal state={{ ...state, patient: user.cpf }} agenda={agenda} setPausedFetchRdy={setPausedFetchRdy}></DesistenciaModal>
          )}
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} fontSize={md ? 'lg' : 'xs'} mt={4}>
          {reconnect === true ? (
            <Text color="white" fontWeight="bold">
              Reconectando...
            </Text>
          ) : (
            <>
              <Text color="white" fontWeight="bold">
                Problemas na conexão?
              </Text>
              <Button
                isFullWidth
                variant="ghost"
                color="white"
                fontSize={md ? 'lg' : 'xs'}
                onClick={() => {
                  setReconnect(true);
                  fetchRdy();
                }}
              >
                Clique aqui para reconectar
              </Button>
            </>
          )}
        </Box>
      </Center>

      <BottomNavBar />
    </>
  ) : (
    <>
      <ChildHeader />
      <VStack>
        <chakra.iframe
          width="95%"
          paddingTop="0.5em"
          height="600px"
          src={`https://atendimento.deovita.com.br:5000/atendimento?p=${paciente ? paciente : ''}&m=${medico ? medico : ''}&a=${
            agenda ? agenda : ''
          }&u=paciente`}
          allow="camera *;microphone *"
        ></chakra.iframe>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Fila;
