import { chakra, Box, LinkBox, Text, VStack, Image, Progress } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../images/assets/telemedicina/backbutton.svg';
import ChildHeader from '../Dashboard/Main/ChildHeader';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main() {
  const { user, setPage, Page } = useContext(AccountContext);
  setPage('Telemedicina');
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [success, setSuccess] = useState(true);
  const [reconnect, setReconnect] = useState(false);
  const { atendimento } = state;
  const [medico, setMedico] = useState('');
  const [paciente, setPaciente] = useState('');
  const [agenda, setAgenda] = useState('');
  const [queued, setQueued] = useState(false);
  const [position, setPosition] = useState(false);
  const today = new Date().toISOString().substring(0, 10);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const [zapMedico, setZapMedico] = useState(false);
  const [error, setError] = useState(false);

  const fetchZap = async (medico) => {
    try {
      if (zapMedico === false) {
        setZapMedico(true);
        const response = await fetch(ENDPOINT + `/whatsapp/send_operador_lembrete/${medico}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        });
        const postsData = await response.json();
        console.log('mensagem enviada');
        console.log(postsData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchZap(atendimento?.RESOURCE_LID);
  }, [atendimento]);
  const fetchRdy = async () => {
    try {
      const response = await fetch(ENDPOINT + `/schedule/scheduletelemedEspecialist/${atendimento?.APP_LID}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      console.log(postsData);
      setError(false);
      setReconnect(false);
      setPosition(postsData.position);

      if (postsData.medico_ready === true) {
        setMedico(postsData.medico_id);
        setAgenda(postsData.agenda_exames_id);
        setPaciente(postsData.paciente_id);
        setLoading(false);
        const highestId = window.setTimeout(() => {
          for (let i = highestId; i >= 0; i--) {
            window.clearInterval(i);
          }
        }, 0);
      }
    } catch (error) {
      setError('problema na rede, verifique se seu dispositivo está conectado a internet');
      console.log(error);
    }
  };

  useEffect(() => {
    if (success === true) {
      setInterval(function () {
        fetchRdy();
      }, 10000);
    }
  }, [success]);
  return isLoading ? (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="linear-gradient(180deg, #EB8B2A 0%, #EFA73F 28.65%, #5AB2AD 64.06%, #4E9E8F 100%);"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <LinkBox
          mt="1"
          id=""
          as="card"
          width="328px"
          height="300px"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          maxInlineSize="80"
          textAlign="center"
        >
          <VStack m="10%">
            <Text mt="1em" color="white" fontWeight="bold" fontSize="md" mb="0.5em">
              Aguarde um instante, você já será atendido
            </Text>
            <Text mt="1em" color={error !== false && error !== undefined ? 'red.500' : 'white'} fontWeight="bold" fontSize="md" mb="0.5em">
              {error !== false && error !== undefined
                ? error
                : position !== null
                ? position === false || position === undefined
                  ? 'conectando...'
                  : ``
                : 'iniciando atendimento...'}
            </Text>
            <Box w="100%" h="1em" bgColor="white" borderRadius="15px 15px 15px 15px">
              <Progress h="1em" borderRadius="15px 15px 15px 15px" colorScheme="blackAlpha" size="xs" isIndeterminate />
            </Box>
          </VStack>
        </LinkBox>
        <VStack
          w="90%"
          onClick={() => {
            setReconnect(true);
            fetchRdy();
          }}
        >
          {reconnect === true ? (
            <>
              <Text mt="1em" color="white" fontWeight="bold" fontSize="md">
                Reconectando...
              </Text>
            </>
          ) : (
            <>
              <Text mt="1em" color="white" fontWeight="bold" fontSize="md">
                Problemas na conexão?
              </Text>
              <Text color="white" fontWeight="bold" fontSize="md" mb="0.5em">
                clique aqui para reconectar
              </Text>
            </>
          )}
        </VStack>
      </VStack>

      <BottomNavBar />
    </>
  ) : (
    <>
      <ChildHeader />
      <VStack>
        <chakra.iframe
          width="95%"
          paddingTop="0.5em"
          height="600px"
          src={`https://atendimento.deovita.com.br:5000/atendimento/p${atendimento?.USER_LID}m${atendimento?.RESOURCE_LID}a${atendimento?.APP_LID}`}
          allow="camera *;microphone *"
        ></chakra.iframe>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
