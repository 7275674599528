import { Button, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlueFitImage from '../../../../images/carrosel/descontos/Banners/BlueFit.svg';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';

function Bluefit() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [valor, setValor] = useState([]);
  const { setPage, setUser, user } = useContext(AccountContext);
  const { id_contrato, descricao_plano, titular } = user;
  setPage('Clube de Vantagens');
  return (
    <>
      <ChildHeader />
      <VStack alignItems="left" spacing={2.5} w={'100%'} p={'2em'}>
        <Image src={BlueFitImage} borderRadius={20} w="100%" objectFit="cover" />
        <Text textAlign={'justify'} fontSize={'16px'} color={'#474747'} fontWeight={'bold'}>
          Para ter acesso ao seu desconto, será necessário coletar o voutcher pelo WhatsApp do MultiDescontos e apresentar no balcão da academia.
        </Text>
        <VStack alignItems="left">
          <Button
            bg="#EB8B2A"
            color="#FFFFFF"
            w="100%"
            mt={'2em'}
            onClick={() => {
              window.open('https://www.casasbahia.com.br/', '_blank');
            }}
          >
            Obter meus Descontos
          </Button>
        </VStack>
      </VStack>
    </>
  );
}

export default Bluefit;
