import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  chakra,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { AccountContext } from '../../../../AccountContext';
import BottomNavBar from '../../../../BottomNavBar';
import ChildHeader from '../../ChildHeader';
import Grafico from './Grafico';
import ListaContratos from './ListaContratos';
import ListaOperations from './ListaOperations';
import SearchBar from './SearchBar';
import Loading from '../../../../Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineOpenInBrowser } from 'react-icons/md';
import { useEffect } from 'react';
import DrawerSaque from './DrawerSaque';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main() {
  const { setPage, user } = useContext(AccountContext);
  setPage('Extrato');
  const { state } = useLocation();
  const { vendedores, contratos, empresas } = state;
  const [tabIndex, setTabIndex] = useState(0);
  const [saque, setSaque] = useState(0);
  const [bankData, setBankData] = useState(false);
  const [error, setError] = useState(null);
  const disclosure = useDisclosure();
  const navigate = useNavigate();
  const [operations, setOperations] = useState([]);
  const [recipientBalance, setRecipientBalance] = useState({
    object: 'balance',
    waiting_funds: { amount: '-' },
    transferred: { amount: '-' },
    available: { amount: '-' },
  });

  let graphicData;
  switch (tabIndex) {
    case 0:
      graphicData = contratos;
      break;
    case 1:
      graphicData = contratos;
      break;

    default:
      break;
  }

  const fetchBankData = async () => {
    const response = await fetch(ENDPOINT + '/entrepreneur/bank-data/' + user.id_empreendedor, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json().then((data) => {
      if (data) setBankData(data.data);
    });
  };

  const fetchRecipientBalance = async () => {
    const response = await fetch(`${ENDPOINT}/entrepreneur/balance/${user.recipient_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json().then((data) => {
      if (data) setRecipientBalance(data.recipientBalance.data);
    });
  };
  const fetchOperations = async () => {
    const response = await fetch(`${ENDPOINT}/entrepreneur/balanceOperations/${user.recipient_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json().then( async (data) => {  
     await fetch(ENDPOINT + '/entrepreneur/bank-data/' + user.id_empreendedor, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      }).then( (data_bank) =>{ 
            data_bank.json().then((data_bank) => { 
                if(data.balanceOperationsResponse.data[data.balanceOperationsResponse.data.length - 1].movement_object.status == "canceled"
                && data_bank.data.data_atualizacao < data.balanceOperationsResponse.data[data.balanceOperationsResponse.data.length - 1].movement_object.date_updated
                ){    
                  navigate('/alerts/erro/updatebank');
                } 
            });
        } 
      );
      
      if (data) setOperations(data.balanceOperationsResponse.data);
    });
  };

  useEffect(() => {
    fetchRecipientBalance();
    fetchBankData();
    fetchOperations();
  }, [user]);
  console.log(recipientBalance);
  
  return contratos !== [] && vendedores !== [] && operations !== [] && operations !== undefined ? (
    <>
      <ChildHeader />
      <DrawerSaque disclosure={disclosure} recipientBalance={recipientBalance} bankData={bankData} />
      {user.recipient_id ? (
        <>
          <HStack align="center" w="100%" p="5%">
            <VStack w="70%" align="left" spacing="-0.1" mt="0.5em">
              <Text ml="1.2em" fontSize="0.5em" mb="0.5em" color="#A1A1A1">
                Saldo:
              </Text>
              <Badge borderRadius="25px" backgroundColor="#1FAB8A" h="2.6em">
                <Center h="2.5em" color="white">
                  {recipientBalance.available_amount !== '-'
                    ? ((recipientBalance.available_amount + recipientBalance.waiting_funds_amount) / 100).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : 'carregando...'}
                </Center>
              </Badge>
            </VStack>
            <ButtonGroup w="100%" pt="1em">
              <Button w="100%" color="white" borderRadius="15px" backgroundColor="#5AB2AD" onClick={() => disclosure.onOpen()}>
                Solicitar
              </Button>
            </ButtonGroup>
          </HStack>
          <VStack m="5% 5%" align="start" mb="25%">
            <Grafico contractData={graphicData} widht="90%" />
            <chakra.h1 fontWeight="bold">Atividades Recentes</chakra.h1>
            <SearchBar />
            <Tabs width="100%" pb="25%" onChange={(e) => setTabIndex(e)}>
              <TabList>
                <Tab w="50%">Contratos</Tab>

                <Tab w="50%">Extrato</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <ListaContratos lista={contratos} />
                </TabPanel>

                <TabPanel>
                  <HStack align="center" pb="3%">
                    <Box>
                      <MdOutlineOpenInBrowser color="white" />
                    </Box>
                    <Box align="center" w="25%" fontSize="0.7em">
                      Cód
                    </Box>
                    <Box pl="1.5em" align="center" w="25%" fontSize="0.7em">
                      Forma
                    </Box>
                    <Box minW="25%" textAlign="center" fontSize="0.7em">
                      Valor
                    </Box>
                    <Box textColor="black" align="center" w="25%" fontSize="0.7em">
                      Taxa
                    </Box>
                  </HStack>
                  <ListaOperations lista={operations} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </>
      ) : (
        <>
          <VStack
            align="row"
            mt="3"
            justify={{
              base: 'center',
              md: 'space-around',
              xl: 'space-between',
            }}
            direction={{ base: 'row' }}
            wrap="no-wrap"
            minH="10vh"
            px={5}
            spacing={2}
          >
            <Tabs isFitted>
              <TabList>
                <Tab>Revendedor</Tab>
                <Tab isDisabled></Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Heading size="md" paddingInlineStart={6} mt="4">
                    Você ainda não cadastrou seus dados bancários. Para gerar o código de indicação, cadastre seus dados bancários.
                  </Heading>
                  <Center>
                    <Button
                      mt="4em"
                      w="90%"
                      colorScheme="teal"
                      onClick={() => {
                        navigate('/empreendedor/dados-bancarios');
                      }}
                    >
                      Cadastrar Dados Bancários
                    </Button>
                  </Center>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <BottomNavBar />
          </VStack>
        </>
      )}

      <BottomNavBar />
    </>
  ) : (
    <Loading />
  );
}

export default Main;
