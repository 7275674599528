import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Center,
  ModalBody,
  Heading,
  Text,
  Image,
  HStack,
  VStack,
  CircularProgress,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';

function LoadingGenerico(props) {
  const { user, toastInadimplente, setUser, updateData, loadedMedicina } = useContext(AccountContext);
  const disclosure = props.disclosure;
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent height="180px" width="280px" borderRadius="1em">
          <ModalBody>
            <VStack w="100%" alignItems="center">
              <CircularProgress isIndeterminate color={usuarioAssinante ? '#EB8B2A' : '#529C94'} mt={'0.5em'} />
            </VStack>
            <Text ml="3" mt="15" color={'#585858'} fontSize={'18px'}>
              Estamos carregando as informações, aguarde ...
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default LoadingGenerico;
