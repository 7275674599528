import { Box, Button, Center, Flex, HStack, Image, Stack, Text, VStack, useMediaQuery } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React from 'react';
import ReactPlayer from 'react-player/youtube';
import Warning from '../../../images/AvisoPendencias.svg';
import { useNavigate } from 'react-router-dom';
function CancelamentoMusicaSaudade() {
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const navigate = useNavigate();
  return (
    <>
      <ChildHeader />
      <VStack mt="1.5em" alignItems="center">
        <VStack w="100%" alignItems="flex-start" spacing="1em">
          <Box w="100%" pl="3em">
            <Text color="#EB8B2A" fontSize="18px" fontWeight="bold">
              Poxa vida... 😭
            </Text>
          </Box>
          <Box w="100%" pl="3em">
            <Text color="#EB8B2A" fontSize="18px" fontWeight="bold" mt={'-0.8em'}>
              Eu fiz o meu melhor por você
            </Text>
          </Box>
          <Box w="100%" pl="3em">
            <Text color="#EB8B2A" fontSize="18px" fontWeight="bold" mt={'-0.8em'}>
              Mas tudo bem, o que resta é <br /> aceitar.
            </Text>
          </Box>
        </VStack>

        <VStack minW="210px" maxW="390px" mx="auto" overflow="hidden" padding="2.5em" alignItems="center" pl={isMinHeight ? '3em' : '2.2em'}>
          <Text color="#585858" fontSize="16px" textAlign="left" mt="-1.7em" mb={'-4em'}>
            Agora, vou ouvir uma música para aceitar o nosso término:
          </Text>
        </VStack>

        <Flex minW="10em" maxW="60em" bg="#EB8B2A" p="10px" borderRadius="10px">
          <VStack w="100%" spacing="10px">
            <HStack borderRadius="0.8em" w="100%" alignItems="left">
              <ReactPlayer url="https://www.youtube.com/watch?v=ptTslJIvT6g" controls={true} width="100%" minH={'110px'} maxH={'110px'} />
            </HStack>
            <VStack w="100%" alignItems="flex-start" spacing="5px">
              <Text fontSize="18px" color="white">
                Bruno & Marrone
              </Text>
              <Text fontSize="14px" color="white">
                Você Não Me Ensinou a Te Esquecer
              </Text>
            </VStack>
          </VStack>
        </Flex>

        <Flex minW="10em" maxW="20em" bg="#F3F3F3" p="10px" borderRadius="10px">
          <VStack w="100%" spacing="10px" mb="2em">
            <HStack borderRadius="0.8em" w="100%" alignItems="left">
              <Image src={Warning} />
            </HStack>
            <VStack w="100%" alignItems="flex-start" spacing="5px">
              <Text fontSize="19px" color="#585858" fontWeight="bold">
                Sua saúde não pode esperar...
              </Text>
              <Text fontSize="16px" color="#585858">
                Ao realizar o cancelamento você fica impedido de fazer uma nova assinatura nos próximos 90 dias.
              </Text>
              <Text fontSize="16px" color="#585858" mt="1em">
                Deseja realizar o cancelamento mesmo assim?
              </Text>
              <Stack direction="row" spacing={5} justifyContent="center" w="100%">
                <Button bg="#F3F3F3" color="#585858" h="2em" onClick={() => {navigate('/perfil/assinaturas/movitos-cancelamento-contrato');}}>
                  Sim
                </Button>
                <Button
                  bg="#EB8B2A"
                  color="white"
                  h="2em"
                  onClick={() => {
                    navigate('/perfil/assinaturas/continua-contrato');
                  }}
                >
                  Não
                </Button>
              </Stack>
            </VStack>
          </VStack>
        </Flex>
      </VStack>
    </>
  );
}

export default CancelamentoMusicaSaudade;
