import { Box, Button, ButtonGroup, Flex, HStack, Heading, Image, Spacer, Text, VStack, chakra, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useEffect } from 'react';
import SucessoLaranja from '../../../images/SucessoLaranja.svg';
import Excluir from '../../../images/ExcluirGenerico.svg';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import MainChildHeader from 'components/Dashboard/Main/MainChildHeader';
import ExcluirContaDrawer from './Modal/ExcluirContaDrawer';
function ContratoCanceladoSucesso() {
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const navigate = useNavigate();
  const { user, toastSolicitacao, setUser } = useContext(AccountContext);
  const excluirDrawer = useDisclosure()
  useEffect(() => {setUser((prevState) => ({ ...prevState, id_contrato: 0, qntdeParcelas: [] }));}, [user]);
  return (
    <>
    <ExcluirContaDrawer disclosure={excluirDrawer} />
      <Box position="relative">
        <MainChildHeader />
        <VStack w={'100%'} mt={'1.5em'} align="center">
          <VStack w={'100%'} align="center">
            <Flex minW="270px" maxW="310px" bg={'#F3F3F3'} p={'20px'} borderRadius={'10px'}>
              <VStack w={'100%'} align="center">
                <Image w="30px" h={'30px'} objectFit="cover" ml={'-14.5em'} src={SucessoLaranja} />
                <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                  <Text fontSize={'19px'} fontWeight={'bold'} color={'#585858'}>
                    Seu <span style={{ color: '#FFA500' }}>contrato</span> foi cancelado
                  </Text>
                </HStack>

                <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                  <Text fontSize={'16px'} color={'#585858'}>
                    Agradecemos por utilizar o Plano DeoVita.
                  </Text>
                </HStack>

                <Spacer border={'0.1px solid #E6E6E6'} />

                <HStack borderRadius={'0.8em'} w={'100%'} alignItems="center">
                  <Text fontSize={'16px'} color={'#585858'}>
                    Você só poderá reativar seu contrato após 90 dias do cancelamento.
                  </Text>
                </HStack>

                <HStack
                  borderRadius={'0.8em'}
                  w={'100%'}
                  alignItems="center"
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                >
                  <ButtonGroup sx={{ width: '100%' }} mb="1em" mt={'1em'}>
                    <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
                      Voltar para a Home
                    </Button>
                  </ButtonGroup>
                </HStack>
              </VStack>
            </Flex>
          </VStack>
        </VStack>

        <VStack w="100%" align="center" position="fixed" bottom="0" zIndex="10" p="1em" bg="white" mb={'2.5em'}>
          <Heading fontSize="16" fontWeight="bold" mb="1em" color={'#585858'} ml={'-14.5em'}>
            Opções
          </Heading>
          <VStack w="100%" align="center" onClick={() => navigate('/perfil/dependentes/excluir-conta-pagina')}>
            <HStack align="center" spacing={2} ml={'-10em'}>
              <Image boxSize="14px" objectFit="cover" src={Excluir} alt="" />
              <chakra.span fontSize="16px" color={'#585858'}>
                {' '}
                Excluir Conta
              </chakra.span>
            </HStack>
          </VStack>
        </VStack>
      </Box>
    </>
  );
}

export default ContratoCanceladoSucesso;
